import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {searchGetGeneric} from '../../actions/search_actions';
import RequireAuth from '../../hocs/RequireAuth';
import { trackMyBallotGetData, trackMyBallotClearData } from '../../actions/trackMyBallot_actions';
import { imgCheck } from '../../misc/icons/icons';
import Address from '../Address';
import PageTitle from '../PageTitle';
import {PATHS} from '../../Routes';
import {NavLink} from 'react-router-dom';


class TrackMailBallot extends React.Component {
	no_ballot_status = "No Ballot Found";
	no_ballot_status_primary_replacement = (<span className='dtsvote-u-pl-0d5'>We could not find a ballot for you.
		If you are not affiliated with a political party, you will need to request a ballot;
		however, some conditions may apply.</span>);
	no_ballot_status_primary_url = (<NavLink to={PATHS.ElectionFAQ}>Read more about primary election rules and conditions.</NavLink>);

	constructor(props) {
		super(props);
		this.PageMessage = this.PageMessage.bind(this);
		this.VoterName = this.VoterName.bind(this);
		this.BallotType = this.BallotType.bind(this);
		this.BallotStatus = this.BallotStatus.bind(this);
		this.ContactInfo = this.ContactInfo.bind(this);
	}

	componentDidMount() {
		if (!this.props.generic) {
			// Restore `generic` in case app is refreshed.
			this.props.searchGetGeneric();
		}
	}

	componentWillUnmount() {
		this.props.trackMyBallotClearData();
	}

	// Get page message
	PageMessage() {
		let message = "After you have submitted your ballot it may take the county clerk a few days to process your ballot and update your status. If your status is not yet updated, please check back.  If there is any issue with your ballot, your county clerk will reach out to you.";
		let message2 = "This tool displays tracking information for by mail ballots or provisional ballots. It does not display information if you voted in-person on a voting machine at a polling location.";
		return (
			<div>
				<div>
					<span className='dtsvote-u-pl-0d4'>{message}</span>
					<br />
					<br />
					<span className='dtsvote-u-pl-0d4'>{message2}</span>
					<br />
					<br />
				</div>
			</div>
		);
	}

	// Get the voter's full name
	VoterName() {
		let voter = {
		voter : this.props.data.voter,
		fullName : function() {
			let voterName = this.voter.first;
			if (this.voter.middle) {
				voterName += ' ' + this.voter.middle;
			}
			voterName += ' ' + this.voter.last;

			return voterName;
		}
	}
		return (
			<div>
				<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock dtsvote-u-mt-0'>Name: </h2>
				<span className='dtsvote-u-pl-0d5'>{voter.fullName()}</span>
				<br />
			</div>

		);
	}

	// Get the voter's ballot type
	BallotType() {
		const b = this.props.ballot;
		let ballotType = null;

		if (b && b.errorMessage) {
			ballotType = (
				<p>{b.errorMessage}</p>
				);
		} else if (b) {
			ballotType = (
				<div>
					<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Type of Ballot: </h2> <span className='dtsvote-u-pl-0d5'>{b.description}</span>
					<br />
				</div>
			);
		} else {
			ballotType = (
				<div></div>
			);
		}

		return ballotType;
	}

	// Get the voter's ballot status
	BallotStatus() {
		const isPrimary = this.props.generic && this.props.generic.electionPhase === 'Primary';
		const b = this.props.ballot;
		let ballotStatus = null;

		if (!b || b.editable) {
			ballotStatus = (
				<div></div>
			);
		} else if (b.status === this.no_ballot_status && isPrimary) {
			ballotStatus = (
				<div>
				<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Ballot Status: </h2> {this.no_ballot_status_primary_replacement} {this.no_ballot_status_primary_url}
				<br />
			</div>
			);
		} else {
			ballotStatus = (
				<div>
				<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Ballot Status: </h2> <span className='dtsvote-u-pl-0d5'>{b.status}</span>
				<br />
			</div>
			);
		}

		return ballotStatus;
	}

	// Get contact info for a voter
	ContactInfo() {
		const {clerk, clerkEmail, phone} = this.props.data.countyClerk;
		const isMunicipal = this.props.generic && this.props.generic.electionPhase === 'Municipal';
		let contactInfo = null;

		if (isMunicipal) {
			contactInfo = (
				<p>Questions about your ballot? Contact your municipal clerk or recorder.</p>
			);
		} else {
			contactInfo = (
				<p>
					Questions about your ballot? Contact your county clerk:<br/>
					{phone ? <span className='dtsvote-u-d-block'><strong>Phone</strong>: <a href={`tel:${phone}`}>{phone}</a></span>
						: <span className='dtsvote-u-d-block'><strong>Phone</strong>:</span>}
					{clerkEmail ? <span className='dtsvote-u-d-block'><strong>Email</strong>: <a href={`mailto:${clerkEmail}`}>{clerkEmail}</a></span>
						: <span className='dtsvote-u-d-block'><strong>Email</strong>:</span>}
				</p>
			);
		}

		return contactInfo;
	}

	render() {
		let pageStatus;
		let addressProps;
		if (this.props.data) {
			if (!this.props.ballot) {
				if (this.props.data.voter.voterId) {
					this.props.trackMyBallotGetData(this.props.data.voter.voterId);
				} else if (this.props.generic && this.props.generic.electionPhase === 'Municipal') {
					pageStatus = (
						<div className='dtsvote-u-ml-0d5 dtsvote-u-text-left'>
							<p className='dtsvote-u-mt-0'>There is no current ballot information to display. Contact
								your
								municipal clerk or recorder for more information.</p>
						</div>
					);
				} else {
					pageStatus = (
						<div className='dtsvote-u-ml-0d5 dtsvote-u-text-center'>
							<h3>There is no current ballot information to display.</h3>
						</div>
					);
				}
			} else {
				let myState = this.props.ballot.state;
				if (myState && ! myState.isEmpty && myState.length == 2) {
					myState = myState.toUpperCase();
				} else {
					myState = '';
				}

				let address = {
					address: this.props.ballot.address,
					addressLine2: this.props.ballot.addressLine2,
					city: this.props.ballot.city,
					state: myState,
					zip: this.props.ballot.zip
				}
				addressProps = {
					address: address,
					header: "Your Ballot Mailing Address",
					headerStyle: "dtsvote-fs-h5 dtsvote-u-d-iblock",
					addressStyle: "dtsvote-u-mt-0d5"
				};
			}

			let pageTitle = 'TRACK MY MAIL OR PROVISIONAL BALLOT';
			const isCaucus = this.props.generic && this.props.generic.electionPhase === 'Caucus';
			const electionName = this.props.data && this.props.data.election && this.props.data.election.name;
			if (isCaucus && electionName) {
				pageTitle = 'Track My ' + electionName + ' Ballot';
			}

			return (
				<div id='TrackMailBallot'>
					<PageTitle image={imgCheck(50, 50)} title={pageTitle}/>
					{pageStatus && pageStatus}
					<div className="dtsvote-c-home__inner">
						<this.PageMessage />
						<this.VoterName/>
						<this.BallotType/>
						<this.BallotStatus/>
						<Address addressProps={addressProps}/>
						<this.ContactInfo/>
					</div>
				</div>
			);
		} else {
			return (
				<div>Loading...</div>
			);
		}
	}
}

TrackMailBallot.propTypes = {
	// User info from redux store.
	data: PropTypes.object,

	// Redux sub-slice for ballot tracking status.
	ballot: PropTypes.object,

	// Get/clear the ballot data.
	trackMyBallotGetData: PropTypes.func.isRequired,
	trackMyBallotClearData: PropTypes.func.isRequired,

	// Redux action creator to fetch misc. app data.
	searchGetGeneric: PropTypes.func.isRequired
};

function mapStateToProps({search, ballot}) {
	return { data: search.data,  ballot: ballot.data, generic: search.generic }
}

export default connect(mapStateToProps, {
	trackMyBallotGetData,
	trackMyBallotClearData,
	searchGetGeneric
})(RequireAuth(TrackMailBallot));
