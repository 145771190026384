import React from 'react';

import Accordion from '../Accordion';
import AccordionHeaderLabel from "../AccordionHeaderLabel";
import Address from "../Address";
import AddressMap from "../AddressMap";

/**
 * This is a component to render drop box locations.
 */
class DropBoxLocation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabSelected: 0,
			tabs: [
				'Vote By Mail',          // Tab 0
				'Vote Early In Person',  // Tab 1
				'Vote On Election Day'   // Tab 2
			],
			accordionToggleAll: true,
		};
	}

	render() {
		return (this.renderDropBoxLocation(this.props));
	}

	renderDropBoxLocation = () => {
		const locations = this.props.dropBoxLocations;
		if (!locations || locations.length === 0) {
			return (<div id="noDropBoxLocations"></div>);
		}

		const list = locations.map(location => {
			const {address1, address2, city, dayTime, description, zip} = location;

			const accordionHeader = {
				header : city,
				items : [description, address1, address2]
			};
			const headerLabel = <AccordionHeaderLabel accordionHeader={accordionHeader} />;

			const address = {
				description : description,
				address : address1,
				addressLine2 : address2,
				city : city,
				state : 'UT',
				zip : zip
			};
			const addressProps = {
				address : address
			};

			return {
				headerLabel: headerLabel,
				panelContents: <div className='dtsvote-u-pb-1'>
					<div className='dtsvote-u-ml-0d5'>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Location:</strong>
						<Address addressProps={addressProps} />
						<AddressMap address={address1} city={city} />
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Date &amp; Time:</strong>
						<div>
							{dayTime}
						</div>
					</div>
				</div>
			}
		});

		return <div>
			<h3 className='dtsvote-fs-h5 dtsvote-u-pl-1'>Mail Ballot Drop Box Locations</h3>
			<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
					onClick={() => {
						this.state.accordionToggleAll
							? Accordion.expandAll('dtsvote')
							: Accordion.collapseAll('dtsvote');
						this.setState({
							accordionToggleAll: !this.state.accordionToggleAll});
					}}>
				{this.state.accordionToggleAll ? 'Expand All' : 'Collapse All'}
			</button>
			<Accordion
				list={list}
				id='acc3'
				headingLevel={2}
				namespace='dtsvote'
			/>
		</div>
	};
}

export default DropBoxLocation;