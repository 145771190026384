import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SEARCH_TYPES } from './App';

import Landing from './components/Landing';
import Search from './components/Search';
import LoginCallback from './components/LoginCallback';

import Home from './components/Home/Home';
import HowWhereToVote from './components/Home/Home__HowWhereToVote';
import VoteByMailCounty from "./components/Home/Home_VoteByMailCounty";
import CandidatesIssues from './components/Home/Home__CandidatesIssues';
import TrackMailBallot from './components/Home/Home__TrackMailBallot';
import VoterInfo from './components/Home/Home__VoterInfo';
import ContactOfficial from './components/Home/Home__ContactOfficial';
import VoterIdRequirements from './components/Home/Home__VoterIdRequirements';
import ElectionFAQ from "./components/Home/Home_ElectionFAQ";
// import CandidateLanding from './components/Candidate/CandidateLanding';
// import CandidateEdit from './components/Candidate/CandidateEdit';

export const PATHS = {
	// Search Paths:
	SearchByAddress: '/search/search-by-address/:next?',
	SearchByAddress_plain: '/search/search-by-address',
	SearchByVoter: '/search/search-by-voter/:next?',
	SearchByVoter_plain: '/search/search-by-voter',

	// Home Path:
	Home: '/:auth(resident|voter)/home',
	HomeAsResident: '/resident/home',
	HomeAsVoter: '/voter/home',

	// Home > Address Auth Paths:
	HowWhereToVote: '/resident/home/how-and-where-can-i-vote',
	CandidatesIssues: '/resident/home/candidates-and-issues',
	ContactOfficial: '/resident/home/contact-election-official',
	VoterIdRequirements: '/resident/home/voter-id-requirements',
	ElectionFAQ: '/resident/home/election-faqs',

	// Home > Voter Auth Paths:
	VoterInfo: '/voter/home/voter-info',
	TrackMailBallot: '/voter/home/track-mail-ballot',

	// Candidate Paths:
	// CandidateLanding: '/candidate/landing',
	// CandidateEdit: '/candidate/edit/',

	// Login Paths:
	OpenIdAuth: '/search/login/callback',

	// Misc. Paths:
	UI: 'https://vote.utah.gov',
	RegisterToVote: 'https://vote.utah.gov/additionalInfo.html',
	RequestAMailBallot: 'https://vote.utah.gov/additionalInfoForMailBallot.html',
	Facebook: 'https://facebook.com/utahelections',
	Twitter: 'https://twitter.com/ElectionsUtah',

	// Root Path:
	Root: '/' // Note: <BrowserRouter> currently using a base url.
};

export const AppRoutes = () =>
<Switch>
	<Route path={PATHS.OpenIdAuth} render={props =>
		<LoginCallback {...props} />} />
	<Route path={PATHS.SearchByAddress} render={props =>
		<Search {...props}
				next={props.match.params.next}
				searchBy={SEARCH_TYPES.ADDRESS} />} />
	<Route path={PATHS.SearchByVoter} render={props =>
		<Search {...props}
				next={props.match.params.next}
				searchBy={SEARCH_TYPES.VOTER} />} />
	<Route path={PATHS.Home} render={props =>
		<Home {...props} authRequired={SEARCH_TYPES.ADDRESS} />} />
	{/*<Route path={PATHS.CandidateLanding} render={props =>*/}
	{/*	<CandidateLanding {...props} />} />*/}
	{/*<Route path={PATHS.CandidateEdit} render={props =>*/}
	{/*	<CandidateEdit {...props} />} />*/}

	<Route path={PATHS.Root} render={props => <Landing {...props} />} />
</Switch>;

export const HomeRoutes = () =>
<Switch>
	<Route path={PATHS.HowWhereToVote} render={props =>
		<HowWhereToVote {...props} authRequired={SEARCH_TYPES.ADDRESS} />} />
	<Route path={PATHS.CandidatesIssues} render={props =>
		<CandidatesIssues {...props} authRequired={SEARCH_TYPES.ADDRESS} />} />
	<Route path={PATHS.ContactOfficial} render={props =>
		<ContactOfficial {...props} authRequired={SEARCH_TYPES.ADDRESS} />} />
	<Route path={PATHS.VoterIdRequirements} render={props =>
		<VoterIdRequirements {...props} authRequired={SEARCH_TYPES.ADDRESS} />} />
	<Route path={PATHS.ElectionFAQ} render={props =>
		<ElectionFAQ {...props} authRequired={SEARCH_TYPES.ADDRESS} />} />
	<Route path={PATHS.VoterInfo} render={props =>
		<VoterInfo {...props} authRequired={SEARCH_TYPES.VOTER} />} />
	<Route path={PATHS.TrackMailBallot} render={props =>
		<TrackMailBallot {...props} authRequired={SEARCH_TYPES.VOTER} />} />
	<Route path={PATHS.Home} render={props =>
		<HowWhereToVote {...props} authRequired={SEARCH_TYPES.ADDRESS}/>} />
</Switch>;
