import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RequireAuth from '../../hocs/RequireAuth';
import { imgSearch } from '../../misc/icons/icons';

import PageTitle from '../PageTitle';

class VoterInfo extends React.Component {
	constructor(props) {
		super(props);
	}

	renderVoterInfo = () => {
		if (!this.props.data
			|| !this.props.data.residence
			|| !this.props.data.voter) {
			return <p>Loading...</p>
		}

		const r = this.props.data.residence;
		const v = this.props.data.voter;
		return <div>
			<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock dtsvote-u-mt-0'>Name: </h2>
			<span className='dtsvote-u-pl-0d5'>{v.first}{v.middle ? ` ${v.middle} ` : ''} {v.last}</span>

			<h2 className='dtsvote-fs-h5'>Physical Address:</h2>
			<address className='dtsvote-u-mt-0d5'>
				{r.physicalAddress} {r.physicalUnitType} {r.physicalUnitNumber}<br />
				{r.physicalCity}, {r.physicalState} {r.physicalZip}
			</address>

			{
				r.mailingAddress ?
					<div>
						<h2 className='dtsvote-fs-h5'>Ballot Mailing Address:</h2>
						<address className='dtsvote-u-mt-0d5'>
							{r.mailingAddress}<br />
							{r.mailingCity}, {r.mailingState} {r.mailingZip}
						</address>
					</div>
					:
					<div>
						<h2 className='dtsvote-fs-h5'>Ballot Mailing Address:</h2>
						<address className='dtsvote-u-mt-0d5'>
							{r.physicalAddress} {r.physicalUnitType} {r.physicalUnitNumber}<br />
							{r.physicalCity}, {r.physicalState} {r.physicalZip}
						</address>
					</div>
			}

			<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Voting Precinct:</h2>
			<span className='dtsvote-u-pl-0d5'>{this.props.data.precinct.precinctName}</span><br />

			<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Voting District:</h2>
			<span className='dtsvote-u-pl-0d5'>State House {this.props.data.districtsByPrecinct.stateHouse}</span><br />

			<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Voter ID Number:</h2>
			<span className='dtsvote-u-pl-0d5'>{v.voterId}</span><br />

			<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Party Affiliation:</h2>
			<span className='dtsvote-u-pl-0d5'>{v.party}</span><br />

			{
				v.status && v.status === 'Inactive' ?
					<div>
						<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Voter Status:</h2>
						<span className='dtsvote-u-pl-0d5'>{v.status}.  An Inactive voter is a voter who has not voted in two regular general elections and has failed to respond to a notice sent to them by the county clerk.</span><br />

						<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Notice:</h2>
						<span className='dtsvote-u-pl-0d5'>Inactive voters do not receive mail ballots.  If you would like to receive a mail ballot, please contact your <a href='https://voteinfo.utah.gov/county-clerk-contact-information/' target='_blank'> county clerk's office or update your voter registration.</a></span><br />
					</div>
					:
					<div>
						<h2 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Voter Status:</h2>
						<span className='dtsvote-u-pl-0d5'>{v.status}</span><br />
					</div>
			}

			<a className='dtsvote-btn dtsvote-btn--primary dtsvote-u-mt-2 dtsvote-u-ml-0 dtsvote-u-hide-print'
			   href='https://vote.utah.gov/register-to-vote-or-update-your-voter-registration/'>
				Update my voter registration
			</a>

			<a className='dtsvote-btn dtsvote-btn--primary dtsvote-u-mt-2 dtsvote-u-ml-1 dtsvote-u-hide-print'
			   href='https://vote.utah.gov/update-your-ballot-mailing-address/'>
				Update my ballot mailing address
			</a>

			<br />
			<button className='dtsvote-btn dtsvote-btn--primary dtsvote-u-mt-2 dtsvote-u-hide-print'
				onClick={() => window.print()}
			>Print this page</button>

		</div>
	};

	render() {
		return(
			<div id='VoterInfo'>
				<PageTitle image={imgSearch(50, 50)} title="MY VOTER REGISTRATION INFO"/>
				<div className="dtsvote-c-home__inner">
					{ this.renderVoterInfo() }
				</div>
			</div>
		);
	}
}

VoterInfo.propTypes = {
	// User info from redux store.
	data: PropTypes.object,
};

function mapStateToProps({search}) {
	return { data: search.data }
}

export default connect(mapStateToProps, null)(RequireAuth(VoterInfo));
