import React from 'react';

import {PATHS} from './Routes';
import {NavLink} from 'react-router-dom';

global.canceledElectionMessage = 'Your election has been canceled.  Please contact your local clerk/recorders office for more information.';
global.noElectionMessage = 'There is no current election information to display.';
global.noMunicipalElectionMessage = 'There is no current election information to display.  Contact your municipal clerk or recorder for more information.';

global.noInfoMessage = 'Sorry, there is no information to show here. You may not have an election in your area. Contact your local election official for more information.';
global.noPollInfoMessage = 'There is no Election Day polling location available in your area. Please contact your local election official for more information.';

// This is used to replace the body of "Vote By Mail", "Vote Early in Person", "Vote On Election Day", and "Candidates and Issues" with regular text.
export function replaceBody(msg) {
	return (
		<div className='dtsvote-u-ml-0d5 dtsvote-u-text-left'>
			<p className='dtsvote-u-mt-0'>{msg}</p>
		</div>
		)
}

// This is used to replace the body of "Vote By Mail", "Vote Early in Person", "Vote On Election Day", and "Candidates and Issues" with bold text.
export function replaceBodyBold(msg) {
	return (
		<div className='dtsvote-u-ml-0d5 dtsvote-u-text-center'>
			<h3>{msg}</h3>
		</div>
	)
}

// This is used to display a notice for primary elections.
export function primaryNoticeInPerson() {
	return (
		<div>
			<h3 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Notice: </h3>
			<span  className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>
				Each political party sets rules on who may vote in its primary election. 
				Click <NavLink to={PATHS.ElectionFAQ}>here</NavLink> for more information about these rules.
			</span>
		</div>
	)
}

export function primaryNoticeDriveUpPolling() {
	return (
		<div>
			<h3 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Notice: </h3>
			<span  className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>
				<ul>
					<li>
						Your county election official may cancel or close a drive-up voting station without prior notice if it is
						necessary to protect the public health and welfare.  If you wait until election day to vote, you assume the
						risk that a drive-up voting location may close at any time.
					</li>
					<li>
						Each political party sets rules on who may vote in its primary election. 
						<NavLink to={PATHS.ElectionFAQ}>Read more about these rules.</NavLink>
					</li>
					<li>
						For more information about drive-up voting locations, please visit your county clerk's website.
					</li>
				</ul>
			</span>
		</div>
	)
}

// Check to see if county allows in person voting
export function isVoteInPersonCounty(countyId) {
	const inPersonCounties = [ 19 ];
	let isInPersonCounty = false;
	for (let i = 0; i < inPersonCounties.length; i++) {
		if (countyId && countyId == inPersonCounties[i]) {
			isInPersonCounty = true;
		}
	}

	return isInPersonCounty;
}