import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RequireAuth from '../../hocs/RequireAuth';
import Accordion from '../Accordion';
import {searchGetElectedOfficials, searchClearElectedOfficials} from '../../actions/search_actions';
import { imgPhone } from '../../misc/icons/icons';

import PageTitle from '../PageTitle';

class ContactOfficial extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accordionToggleAll: true
		};
	}

	componentWillUnmount() {
		this.props.searchClearElectedOfficials();
	}

	renderElectedOfficials = () => {
		if (!this.props.data || !this.props.electedOfficials) {
			return <p>Loading...</p>
		}

		if (this.props.electedOfficials.errorMessage) {
			return <p>{this.props.electedOfficials.errorMessage}</p>
		}

		const list = this.props.electedOfficials.electedOfficialList.map(official => {

			const { officeHolder, office, party, district } = official;
			const headerLabel = <div className='dtsvote-u-text-left'>
				<span className='dtsvote-fs-h5'>{ office.toUpperCase() }</span>
				{ district ? <small>&nbsp;&nbsp;District: {district}</small> : undefined }
				<br />
				<span className='dtsvote-fs-h6'>{ officeHolder }</span>
				{ party ? <small>&nbsp;&nbsp;({party})</small> : undefined }

			</div>;

			let { officeAddress, officeEmail, officePhone } = official;
			officeAddress = officeAddress ?
				officeAddress.trim().split('\r\n').map((line, i) => {
					return (
						<span key={i}>{line}<br /></span>
					);
				})
				: 'N.A';
			officePhone = officePhone ?
				officePhone.trim().split('\r\n').map((line, i) => {
					return (
						<span key={i}>{line}<br /></span>
					);
				})
				: 'N.A';

			return {
				headerLabel: headerLabel,
				panelContents: <div className='dtsvote-u-pb-1'>
					<div className='dtsvote-u-ml-0d5'>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-1'>Office Address:</strong>
						<address>
							{ officeAddress }
						</address>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-1'>Office Email:</strong> <span>{officeEmail ? <a href={`mailto:${officeEmail}`}>{officeEmail}</a> : 'N.A.'}</span>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-1'>Office Phone:</strong> <span>{officePhone}</span>
					</div>
				</div>
			};
		});

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p>
					Below are your elected officials. Expand a section for more details.
				</p>
				<p>
					The officeholders listed below reflect the new districts passed by the Utah State Legislature.
					If you would like to see your current officeholders under the previous maps. Please visit <a href='https://le.utah.gov' target='_blank'>le.utah.gov </a>
					and click on 'My Legislators'.
				</p>
			</div>
			<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
					onClick={() => {
						this.state.accordionToggleAll
							? Accordion.expandAll('dtsvote')
							: Accordion.collapseAll('dtsvote');
						this.setState({
							accordionToggleAll: !this.state.accordionToggleAll});
					}}>
				{this.state.accordionToggleAll
					? 'Expand All' : 'Collapse All'}
			</button>
			<Accordion
				list={list}
				id='acc1'
				headingLevel={2}
				namespace='dtsvote'
			/>
		</div>;
	};

	render() {
		if (!this.props.electedOfficials && this.props.data) {
			this.props.searchGetElectedOfficials(this.props.data.precinctId);
		}

		return(
			<div id='ContactOfficial'>
				<PageTitle image={imgPhone(50, 50)} title="CONTACT MY ELECTED OFFICIALS"/>
				<div>
					{ this.renderElectedOfficials() }
				</div>
			</div>
		);
	}
}

ContactOfficial.propTypes = {
	// User info from redux store.
	data: PropTypes.object,

	// The elected officials.
	electedOfficials: PropTypes.object,

	// Get/clear the election officials.
	searchGetElectedOfficials: PropTypes.func.isRequired,
	searchClearElectedOfficials: PropTypes.func.isRequired
};

function mapStateToProps({search}) {
	return { data: search.data, electedOfficials: search.electedOfficials }
}

export default connect(mapStateToProps, {
	searchGetElectedOfficials, searchClearElectedOfficials
})(RequireAuth(ContactOfficial));
