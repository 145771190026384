import {
	ADDRESS_SEARCH_REQUEST,
	ADDRESS_SEARCH_RESPONSE,
	ADDRESS_SEARCH_ERROR,
	VOTER_SEARCH_REQUEST,
	VOTER_SEARCH_RESPONSE,
	VOTER_SEARCH_ERROR,
	SEARCH_REHYDRATE,
	SEARCH_LOGOUT,
	SEARCH_GET_ELECTED_OFFICIALS,
	SEARCH_CLEAR_ELECTED_OFFICIALS,
	SEARCH_GET_GENERIC
} from '../actions/_index_action-types';

export const searchReducer = (state = {
	isFetching: false,
	data: null,
	errors: null,
	authCreatedAt: null,
	authType: null,
	inputs: null,
	generic: null,
	electedOfficials: null
}, action) => {
	switch (action.type) {
		case ADDRESS_SEARCH_REQUEST:
		case VOTER_SEARCH_REQUEST:
			return {
				...state,
				isFetching: true,
				errors: null
			};
		case ADDRESS_SEARCH_RESPONSE:
		case VOTER_SEARCH_RESPONSE:
			return {
				...state,
				isFetching: false,
				data: !action.payload.errors ? action.payload.data : null,
				authCreatedAt: !action.payload.errors
					? action.payload.authCreatedAt : null,
				errors: action.payload.errors,
				authType: !action.payload.errors
					? action.payload.authType : null,
				inputs: !action.payload.errors
					? action.payload.inputs : null
			};
		case ADDRESS_SEARCH_ERROR:
		case VOTER_SEARCH_ERROR:
			return {
				...state,
				isFetching: false,
				errors: { requestError: 'Error submitting your search. Check' +
				' your information or try refreshing the page.' }
			};
		case SEARCH_REHYDRATE:
			return {
				...state,
				data: action.payload.data,
				authCreatedAt: action.payload.authCreatedAt,
				authType: action.payload.authType,
				inputs: action.payload.inputs
			};
		case SEARCH_LOGOUT:
			return {
				...state,
				isFetching: false,
				data: null,
				errors: null,
				authCreatedAt: null,
				authType: null,
				inputs: null
			};
		case SEARCH_GET_GENERIC:
			return {
				...state,
				generic: action.payload
			};
		case SEARCH_GET_ELECTED_OFFICIALS:
			return {
				...state,
				electedOfficials: action.payload
			};
		case SEARCH_CLEAR_ELECTED_OFFICIALS:
			return {
				...state,
				electedOfficials: null
			};
		default:
			return state;
	}
};
