import React from 'react';

import {imgLocation} from '../../misc/icons/icons';
import Accordion from '../Accordion';
import AccordionHeaderLabel from "../AccordionHeaderLabel";
import Address from "../Address";
import AddressMap from "../AddressMap";
import moment from "moment";

class CaucusInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabSelected: 0,
			tabs: [
				'Vote By Mail',          // Tab 0
				'Vote Early In Person',  // Tab 1
				'Vote On Election Day'   // Tab 2
			],
			accordionToggleAll: true,
		};
	}

	render() {
		return(this.CaucusInfo(this.props));
	}

	buildAddressProps(caucus) {
		const {address1, address2, city, zip } = caucus;

		const address = {
			address : address1,
			addressLine2 : address2,
			city : city,
			state : 'UT',
			zip : zip
		};

		const addressProps = {
			address : address
		};

		return addressProps;
	}

	buildCaucusList(caucusList) {
		const list = caucusList.map(caucus => {
			const { placeName, address1, address2, caucusTime, party, city, zip, whoCanAttend, phone, email, website } = caucus;
			const dateMoment = moment(caucusTime);

			let caucusDateTime = (<div></div>);
			let caucusLocation = (<div></div>);
			let caucusAddress = (<div></div>);
			let caucusWhoCanAttend = (<div></div>);
			let caucusPartyInfo = (<div></div>);
			let websiteHref = (<div></div>);
			if (website) {
				websiteHref = (<a href={website}>{website}</a>);
			}
			// Sections :
			// Caucus Date and Time
			if (caucusTime && dateMoment && dateMoment.isValid() && party && (party === 'Green' || party === 'Libertarian')) {
				caucusDateTime = (
					<div>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Convention Date &amp; Time:</strong>
						<span>{dateMoment.format('MMM D YYYY')} @ {dateMoment.format('hh:mm A')}</span>
					</div>
				);
			} else if (caucusTime && dateMoment && dateMoment.isValid()) {
				caucusDateTime = (
					<div>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Caucus Date &amp; Time:</strong>
						<span>{dateMoment.format('MMM D YYYY')} @ {dateMoment.format('hh:mm A')}</span>
					</div>
				);
			}
			// Caucus Location
			if (party && party === 'Constitution') {
				caucusLocation = (<div>
					<br />
						<span>For more information on the Constitution Party's conventions, please check the Constitution Party of Utah website: <a href="http://www.utahconstitutionparty.com" target="_blank">http://www.utahconstitutionparty.com/</a></span>
					</div>);
			} else if (placeName && party && (party === 'Green' || party === 'Libertarian')) {
				caucusLocation = (
					<div>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Convention Location:</strong>
						<span>{placeName}</span>
					</div>
				)
			} else if(placeName) {
				caucusLocation = (
					<div>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Caucus Location:</strong>
						<span>{placeName}</span>
					</div>
				)
			}

			// Address
			if (address1) {
				caucusAddress = (
					<div>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Address:</strong>
							<Address addressProps={this.buildAddressProps(caucus)} />
						{ city ? <AddressMap address={address1} city={city} /> : undefined }
						<br />
					</div>
				);
			}

			// What can attend?
			caucusWhoCanAttend = whoCanAttend ? (<div>
				<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Who can attend:</strong>
				<span>{whoCanAttend}</span>
			</div>) : caucusWhoCanAttend;

			// For info about party
			if (party && (party === 'Green' || party === 'Libertarian')) {
				caucusPartyInfo = (<div>
					<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>For more information about the {party} Party convention, please contact:</strong>
					<ul>
						<li>Phone: {phone}</li>
						<li>Email: {email}</li>
						<li>Website: {websiteHref}</li>
					</ul>
				</div>);
			} else if (party && party === 'Republican') {
				caucusPartyInfo = (<div>
					<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>For more information about the {party} Party
						caucuses, please contact:</strong>
					<ul>
						<li>Phone: {phone}</li>
						<li>Email: {email}</li>
						<li>Website: {websiteHref}</li>
					</ul>
				</div>);
			} else if (party && party === 'Democratic') {
				caucusPartyInfo = (<div>
					<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>For more information about the {party} Party
						caucuses, please contact:</strong>
					<ul>
						<li>Phone: {phone}</li>
						<li>Email: {email}</li>
						<li>Website: {websiteHref}</li>
					</ul>
				</div>);
			} else if (party && party === 'Constitution') {
				caucusPartyInfo = (<div></div>);
			} else if (party) {
				caucusPartyInfo = (<div>
					<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>For more information about the {party} Party caucuses, please contact:</strong>
					<ul>
						<li>Phone: {phone}</li>
						<li>Email: {email}</li>
						<li>Website: {websiteHref}</li>
					</ul>
				</div>);
			}

			const accordionHeader = {
				header : party
			};
			const headerLabel = <AccordionHeaderLabel accordionHeader={accordionHeader} />;

			return {
				headerLabel: headerLabel,
				panelContents: <div className='dtsvote-u-pb-0d5 dtsvote-u-ml-0d5'>
					{caucusDateTime}
					{caucusLocation}
					{caucusAddress}
					{caucusWhoCanAttend}
					{caucusPartyInfo}
				</div>
			};
		});

		return list;
	}

	CaucusInfo(props) {
		if (!props.residence) {
			return <p>Loading...</p>
		}

		let caucusList = props.residence.caucusList;

		const list = this.buildCaucusList(caucusList);

		return (
			<div id='HowWhereToVote'>
				<div className='dtsvote-c-home__title'>
					{ imgLocation(50, 50) }
					<h1 className='dtsvote-c-home__title__text'>MY CAUCUS LOCATION</h1>
					{/*<a href="#" onClick={this.props.handleCaucusInfoClick} style={{ marginLeft: 'auto' }}>How can I vote?</a>*/}
				</div>
				<div className='dtsvote-c-home__inner'>
					<div className='dtsvote-c-home__tabs-content'>
						<h2 className='dtsvote-u-mt-0 dtsvote-fs-h3'>Precinct {this.props.residence.precinct.precinctName}</h2>
						{ list && list.length === 0
								? undefined
								: <p>
									Click on a political party below to view your caucus location, date, and time.
								  </p>
						}
					</div>
				</div>
				<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
					onClick={() => {
						this.state.accordionToggleAll
							? Accordion.expandAll('dtsvote')
							: Accordion.collapseAll('dtsvote');
						this.setState({
							accordionToggleAll: !this.state.accordionToggleAll});
					}}>
					{this.state.accordionToggleAll
					? 'Expand All' : 'Collapse All'}
				</button>
				<Accordion list={list} id='acc1' headingLevel={2} namespace='dtsvote' />
			</div>
		);
	}
}

export default CaucusInfo;