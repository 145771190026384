import React from 'react';

import RequireAuth from '../../hocs/RequireAuth';
import { imgId } from '../../misc/icons/icons';

import PageTitle from '../PageTitle';

class VoterIdRequirements extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return(
			<div id='VoterIdRequirements'>
				<PageTitle image={imgId(50, 50)} title="VOTER ID REQUIREMENTS"/>
				<div className="dtsvote-c-home__inner">
					<h2 className='dtsvote-fs-h4 dtsvote-u-mt-0'>In order to vote in Utah, you need valid voter I.D.</h2>
					<p>This is either a form of identification that bears the name and photograph of the voter or two forms of identification that bear the name of the voter and provide evidence of the voter’s residence,</p>
					<h2 className='dtsvote-fs-h4'>In Utah, these forms of identification include:</h2>
					<ol>
						<li>A currently valid Utah driver license;</li>
						<li>A currently valid ID card issued by the state or a branch, department, or agency of the United States;</li>
						<li>A currently valid Utah permit to carry a concealed weapon;</li>
						<li>A currently valid United States passport; or</li>
						<li>A valid tribal ID card, whether or not the card includes a photograph of the voter.</li>
					</ol>
					<p className='dtsvote-u-text-center'><strong>- OR -</strong></p>
					<h2 className='dtsvote-fs-h4'>TWO forms of identification that bear the name of the voter and provide evidence that the voter resides in the voting precinct, which may include:</h2>
					<ol>
						<li>A current utility bill or copy thereof dated within 90 days before the election;</li>
						<li>A bank or other financial account statement, or legible copy thereof;</li>
						<li>A certified birth certificate;</li>
						<li>A valid Social Security card;</li>
						<li>A check issued by the state or federal government or legible copy thereof;</li>
						<li>A paycheck from the voter’s employer, or legible copy thereof</li>
						<li>A currently valid Utah hunting or fishing license;</li>
						<li>A currently valid U.S. military ID card;</li>
						<li>Certified naturalization documents (NOT a green card);</li>
						<li>A certified copy of court records showing the voter’s adoption or name change;</li>
						<li>A Bureau of Indian Affairs card;</li>
						<li>A tribal treaty card;</li>
						<li>A valid Medicaid or Medicare or Electronic Benefits Transfer card;</li>
						<li>A currently valid ID card issued by a local government within the state;</li>
						<li>A currently valid ID card issued by an employer;</li>
						<li>A currently valid ID card issued by a college, university, technical school or professional school within the state; or</li>
						<li>A current Utah vehicle registration.</li>
					</ol>
				</div>
			</div>
		);
	}
}

export default RequireAuth(VoterIdRequirements);
