import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SEARCH_TYPES } from "../App";
import { PATHS } from "../Routes";
import { searchLogout, searchRehydrate } from "../actions/search_actions";
import {
	getDisplayName,
	authIsValid,
	getLocalAuthDetails
} from "../misc/util";

export default function(WrappedComponent) {
	class RequireAuth extends React.Component {
		constructor(props) {
			super(props);

			const CHECK_AUTH_EVERY_MS = 1000 * 60 * 3; // 3 minutes.

			this.state = {
				validAuth: this.validateAuth(),
				intervalId: setInterval(() => {
					this.setState({ validAuth: this.validateAuth() });
				}, CHECK_AUTH_EVERY_MS)
			};
		}

		componentWillUnmount() {
			clearInterval(this.state.intervalId);
		}

		validateAuth = (
			authType = this.props.authType,
			authCreatedAt = this.props.authCreatedAt
		) => {
			const authDetails = getLocalAuthDetails(authType, authCreatedAt);

			let validAuth = false;
			if (authDetails !== false) {
				if (authDetails.rehydrateRedux) {
					// If auth was from sessionStorage, rehydrate redux store.
					this.props.searchRehydrate(authDetails);
				}

				validAuth = authIsValid(authDetails.authCreatedAt);

				if (!validAuth) {
					this.props.searchLogout();
					return false;
				}
			}

			if (validAuth) {
				// Have auth data & not expired, now check for access rules.
				if (this.props.authRequired === SEARCH_TYPES.VOTER
					&& this.props.authType === SEARCH_TYPES.ADDRESS) {
					validAuth = false;
				}
			}

			return validAuth;
		};

		render() {
			if (!this.state.validAuth) {
				let pathname = PATHS.Root;
				if (this.props.authRequired === SEARCH_TYPES.VOTER) {
					pathname = PATHS.SearchByVoter_plain;
				} else if (this.props.authRequired === SEARCH_TYPES.ADDRESS) {
					pathname = PATHS.SearchByAddress_plain;
				}

				return <Redirect to={{
					pathname,
					state: {
						next: this.props.location.pathname
					}
				}} />;
			}

			return(
				<WrappedComponent {...this.props} />
			);
		}
	}

	RequireAuth.propTypes = {
		// Whether the page needs address (resident) or voter auth.
		authRequired: PropTypes.string.isRequired,

		// From react-router.
		location: PropTypes.object.isRequired,

		// Redux state values set from search_reducer.js
		authCreatedAt: PropTypes.any, // {(number|null)}
		authType: PropTypes.any, // {(string|null)}

		// Redux action creator to clear auth data from redux & sessionStorage.
		searchLogout: PropTypes.func.isRequired,

		// Redux action creator to rehydrate store from sessionStorage data.
		searchRehydrate: PropTypes.func.isRequired,
	};

	RequireAuth.displayName =
		`withRequireAuth(${getDisplayName(WrappedComponent)})`;

	function mapStateToProps({ search }) {
		return {
			authCreatedAt: search.authCreatedAt,
			authType: search.authType
		};
	}

	return connect(mapStateToProps,
		{ searchLogout, searchRehydrate })(RequireAuth);
}
