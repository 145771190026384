import React from 'react';

import Accordion from '../Accordion';
import AccordionHeaderLabel from "../AccordionHeaderLabel";
import {NavLink} from 'react-router-dom';
import {PATHS} from '../../Routes';
import AddressMap from "../AddressMap";
import {replaceBody, replaceBodyBold, primaryNoticeInPerson} from "../../AppVars";

class VoteEarlyInPerson extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabSelected: 1,
			tabs: [
				'Vote By Mail',          // Tab 0
				'Vote Early In Person',  // Tab 1
				'Vote On Election Day'   // Tab 2
			],
			accordionToggleAll: true,
		};
	}

	render() {
		return (this.VoteEarlyInPerson(this.props.data));
	}

	pollAddressList(data) {
		let pollAddressList;

		if (data.voteEarly) {
			pollAddressList = data.voteEarly.pollAddressList.map((poll, i) => {
				const panelContentsListItems = poll.earlyPollingDateTimeRangeList.map((time, i) => {
					return (
						<li className='dtsvote-c-home__accordion-item' key={i}>
							<span>{time.earlyPollingDate}</span>
							<span>{time.earlyPollingTimeRange}</span>
						</li>
					);
				});

				const {city, pollName, address1, address2} = poll;

				const accordionHeader = {
					header : city,
					items : [pollName, address1, address2]
				};
				const headerLabel = <AccordionHeaderLabel accordionHeader={accordionHeader} />;

				return {
					headerLabel: headerLabel,
					panelContents: <div>
						{
							(address1 === null && city === null) ?
								<p>No early voting locations.</p>
								:
								<AddressMap address={address1} city={city} />
						}
						<ul className='dtsvote-c-home__accordion-list' key={i}>
							{panelContentsListItems}
						</ul>
					</div>
				};
			});
		} else {
			pollAddressList = null;
		}

		return pollAddressList;
	}

	// Text for primary elections
	primary(data) {
		const list = this.pollAddressList(data);

		return <div>
			{list ? (<div>
						<div className='dtsvote-c-home__inner'>
							<p className='dtsvote-u-mt-0'>
								Below are the early voting locations for <strong>{data.residence.countyName} County</strong>.
								Expand a section to view the dates and times for that polling location.
								Remember to bring valid ID. (<NavLink to={PATHS.VoterIdRequirements}>what is valid ID?</NavLink>)
							</p>
							{primaryNoticeInPerson()}
						</div>
						<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
								onClick={() => {
									this.state.accordionToggleAll
										? Accordion.expandAll('dtsvote')
										: Accordion.collapseAll('dtsvote');
									this.setState({
										accordionToggleAll: !this.state.accordionToggleAll});
								}}>
						{this.state.accordionToggleAll
							? 'Expand All' : 'Collapse All'}
						</button>
						<Accordion
						list={list}
						id='acc1'
						headingLevel={2}
						namespace='dtsvote'
						/>
					</div>)
				: (<div>
					<div className='dtsvote-c-home__inner'>
						There are no early voting locations for <strong>{data.residence.countyName} County</strong>.
						Please contact your county clerk.
						{primaryNoticeInPerson()}
					</div>
				</div>
				)
			}
		</div>;
	}

	// Text for general elections
	general(data) {
		const list = this.pollAddressList(data);

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p className='dtsvote-u-mt-0'>
					Below are the early voting locations for <strong>{data.residence.countyName} County</strong>.
					Expand a section to view the dates and times for that polling location.
					Remember to bring valid ID. (<NavLink to={PATHS.VoterIdRequirements}>what is valid ID?</NavLink>)
				</p>

				<h3 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Notice:</h3> <br />
				<span className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>Early voting locations are designed to assist voters who need to replace their ballots, voters who did not receive their mail ballots, or voters who need to use a voting machine that offers accessible features such as an audio ballot. If you received a mail ballot, you are encouraged to skip the lines at voting locations by postmarking your ballot by November 7th or depositing it in a county ballot drop box before 8:00 pm on Election Day.</span>
			</div>
			<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
					onClick={() => {
						this.state.accordionToggleAll
							? Accordion.expandAll('dtsvote')
							: Accordion.collapseAll('dtsvote');
						this.setState({
							accordionToggleAll: !this.state.accordionToggleAll});
					}}>
				{this.state.accordionToggleAll
					? 'Expand All' : 'Collapse All'}
			</button>
			<Accordion
				list={list}
				id='acc1'
				headingLevel={2}
				namespace='dtsvote'
			/>
		</div>;
	}

	VoteEarlyInPerson(data) {
		const { countyId, electionId, electionMessage } = data.residence;
		const precinctId = data.residence.precinct.precinctId ;
		const electionPhase = data.generic.electionPhase;
		let body = '<p>Loading...</p>';

		if (electionMessage) {
			return replaceBody(canceledElectionMessage);
		} else if (! data.voteEarly) {
			data.voteWhereGetEarlyLocs(countyId);
			body = (<p>Loading...</p>);
		} else if (! countyId || ! precinctId || ! electionId) {
			if (data.generic && electionPhase === 'Municipal') {
				body =  replaceBody(noMunicipalElectionMessage);
			} else {
				body =  replaceBodyBold(noElectionMessage);
			}
		} else if (data.voteEarly.errorMessage) {
			body = (<p className='dtsvote-u-alert'>{data.voteEarly.errorMessage}</p>);
		} else if (electionPhase === 'Primary') {
			body =  this.primary(data);
		} else {
			body =  this.general(data);
		}

		return body;
	}
}

export default VoteEarlyInPerson;