import {
	TRACK_BALLOT_GET_DATA,
	TRACK_BALLOT_CLEAR_DATA
} from '../actions/_index_action-types';

export const trackMyBallotReducer = (state = {
	data: null
}, action) => {
	switch (action.type) {
		case TRACK_BALLOT_GET_DATA:
			return {
				...state,
				data: action.payload
			};
		case TRACK_BALLOT_CLEAR_DATA:
			return {
				...state,
				data: null
			};
		default:
			return state;
	}
};
