import {
	VOTEWHERE_GET_EARLYLOCS,
	VOTEWHERE_GET_ELECTIONLOCS,
	VOTEWHERE_GET_DROPBOXLOCS,
	VOTEWHERE_RESET
} from "../actions/_index_action-types";

export const voteWhereReducer = (state = {
	voteEarly: null,
	voteElectionDay: null,
	dropBoxLocations: null
}, action) => {
	switch (action.type) {
		case VOTEWHERE_GET_EARLYLOCS:
			return {
				...state,
				voteEarly: action.payload
			};
		case VOTEWHERE_GET_ELECTIONLOCS:
			return {
				...state,
				voteElectionDay: action.payload
			};
		case VOTEWHERE_RESET:
			return {
				...state,
				voteEarly: null,
				voteElectionDay: null,
				dropBoxLocations: null
			};
		case VOTEWHERE_GET_DROPBOXLOCS:
			return {
				...state,
				dropBoxLocations: action.payload
			};
		default:
			return state;
	}
};
