import axios from 'axios';

import { URL_BASENAME } from '../App';
import {
	TRACK_BALLOT_GET_DATA,
	TRACK_BALLOT_CLEAR_DATA,
	GLOBAL_ERRORS_SET
} from './_index_action-types';

export const trackMyBallotGetData = (voterId) => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/ballotStatus.json/${voterId}`
	})
	.then(res => {
		dispatch({ type: TRACK_BALLOT_GET_DATA, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching ballot data. Refresh the page and try again.'
		});
	});
};

export const trackMyBallotClearData = () => dispatch => {
	dispatch({ type: TRACK_BALLOT_CLEAR_DATA, payload: null });
};
