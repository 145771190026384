import React from 'react';

/**
 * This is a generic component for accordion header formatting.
 */
class AccordionHeaderLabel extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (this.AccordionHeaderLabel(this.props));
	}

	formatHeader(header) {
		let formattedHeader;

		if (header) {
			formattedHeader = (<span className='dtsvote-fs-h5'>{header.toUpperCase()}<br/></span>);
		} else {
			formattedHeader = (<div id='noAccordionHeader'></div> );
		}

		return formattedHeader;
	}

	formatItems(items) {
		let formattedItems;

		if (items) {
			formattedItems = items.map((item) => {
				if(item) {
					return (<div> <span className='dtsvote-fs-h6 dtsvote-u-pl-1'>{item}</span> <br /></div>);
				}
			})
		}

		return (<div>{formattedItems}</div>);
	}

	/**
	 *
	 * @param props = {
	 *     header : headerString,
	 *     items : [ item1, item2, ..., itemN ]
	 * }
	 * @returns {*}
	 * @constructor
	 */
	AccordionHeaderLabel(props) {
		let header;
		let items;

		if (props && props.accordionHeader) {
			header = props.accordionHeader.header;
			items = props.accordionHeader.items;
		}

		return (
			<div className='dtsvote-u-text-left'>
				{this.formatHeader(header)}
				{this.formatItems(items)}
			</div>
		);
	}
}

export default AccordionHeaderLabel;