import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import { PATHS } from '../Routes';
import {
	CANDIDATE_VERIFY_PASSCODE_REQ,
	CANDIDATE_VERIFY_PASSCODE_RES,
	CANDIDATE_GET_LIST_REQ,
	CANDIDATE_GET_LIST_RES,
	CANDIDATE_UPDATE_REQ,
	CANDIDATE_UPDATE_RES,
	CANDIDATE_REQUEST_PASSCODE,
	CANDIDATE_RESET_FETCHING,
	GLOBAL_ERRORS_SET,
	CANDIDATE_GET_LIST_ERROR
} from './_index_action-types';
import { store, URL_BASENAME, SESSION_STORAGE_KEY } from '../App';

export const candidateVerifyPasscode = (passcode, history) => dispatch => {
	dispatch({ type: CANDIDATE_VERIFY_PASSCODE_REQ, payload: null });

	let tokenStr = store.getState().oidc.user && store.getState().oidc.user.access_token;
	axios({
		method: 'GET',
		headers: {"Authorization" : `Bearer ${tokenStr}`},
		url: `${URL_BASENAME}/secure-api/candidateProfileByKeyCode.json/${passcode}`
	})
	.then(res => {
		dispatch({ type: CANDIDATE_VERIFY_PASSCODE_RES, payload: res.data });

		if (!res.data.errorMessage) {
			history.push(PATHS.CandidateEdit);
			const value = { passCodeData: res.data };
			sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(value));
		}
	})
	.catch(() => {
		dispatch({ type: CANDIDATE_RESET_FETCHING, payload: null })
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error validating passcode.'
		});
	});
};

export const candidateGetList = electionId => dispatch => {
	dispatch({ type: CANDIDATE_GET_LIST_REQ, payload: null });

	let tokenStr = store.getState().oidc.user && store.getState().oidc.user.access_token;
	axios({
		method: 'GET',
		headers: {"Authorization" : `Bearer ${tokenStr}`},
		url: `${URL_BASENAME}/secure-api/candidateProfileLanding.json`
	})
	.then(res => {
		if (res.data.errorMessage) {
			dispatch({ type: CANDIDATE_GET_LIST_ERROR, payload: res.data.errorMessage});
		} else {
			dispatch({ type: CANDIDATE_GET_LIST_RES, payload: res.data.candidates });
		}
	})
	.catch(() => {
		dispatch({ type: CANDIDATE_RESET_FETCHING, payload: null });
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching candidate list.'
		});
	});
};

export const candidateUpdate = (profile, electionId, newStatus, history) => dispatch => {
	dispatch({ type: CANDIDATE_UPDATE_REQ, payload: null });

	let profileCopy = cloneDeep(profile);
	profileCopy.status = newStatus;

	let tokenStr = store.getState().oidc.user && store.getState().oidc.user.access_token;
	axios({
		method: 'POST',
		headers: {"Authorization" : `Bearer ${tokenStr}`},
		url: `${URL_BASENAME}/secure-api/editCandidateProfile/${electionId}`,
		data: profileCopy
	})
	.then(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Successfully saved candidate profile.'
		});
		dispatch({ type: CANDIDATE_UPDATE_RES, payload: {newStatus: newStatus, saveSuccessful: true} });
		sessionStorage.clear(); // todo test this
		history.push(PATHS.CandidateLanding);
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error saving candidate profile, please try refreshing the page.'
		});
		dispatch({ type: CANDIDATE_UPDATE_RES, payload: {newStatus: undefined, saveSuccessful: false} });
		history.push(PATHS.CandidateLanding);
	});
};

export const candidateRequestPasscode = (candidateId) => dispatch => {
	dispatch({ type: CANDIDATE_REQUEST_PASSCODE, payload: null });

	let tokenStr = store.getState().oidc.user && store.getState().oidc.user.access_token;
	axios({
		method: 'GET',
		headers: {"Authorization" : `Bearer ${tokenStr}`},
		url: `${URL_BASENAME}/secure-api/candidateProfileGenerateKeyCode.json/${candidateId}`
	})
	.then(res => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: res.data.message
		});
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error requesting candidate passcode.'
		});
	});


};
