import React from 'react';
import { connect } from 'react-redux';
import { Button, PopupWindow } from 'dts-react-common';

import { SESSION_STORAGE_KEY, store } from '../App';
import {
	GLOBAL_ERRORS_CLEAR,
	GLOBAL_ERRORS_SET
} from "../actions/_index_action-types";

/**
 * Use WrappedComponent's name instead of the wrapping HOC container.
 * @param WrappedComponent
 * @returns {string}
 */
export const getDisplayName = WrappedComponent => {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

/**
 * Check if a timestamp has expired compared to Date.now()
 * @param authTimestamp {number}
 * @returns {boolean}
 */
export const authIsValid = authTimestamp => {
	const AUTH_VALID_FOR_MS = 1000 * 60 * 85; // 85 minute session length.

	return ( AUTH_VALID_FOR_MS + authTimestamp ) > Date.now();
};

/**
 * Returns `authType` & `authCreatedAt` if it exists in redux or sessionStorage.
 * @param authType_redux {string}
 * @param authCreatedAt_redux {number}
 * @returns {(boolean|object)} false or the existing authDetails.
 */
export const getLocalAuthDetails = (
	authType_redux = null,
	authCreatedAt_redux = null
) => {
	if (authType_redux !== null && authCreatedAt_redux !== null) {
		return {
			authCreatedAt: authCreatedAt_redux,
			authType: authType_redux
		};
	}

	// If page was refreshed, might have the data in sessionStorage.
	try {
		const data = JSON.parse( sessionStorage.getItem(SESSION_STORAGE_KEY) );

		if (data !== null
			&& data.authCreatedAt !== null && data.authType !== null) {
			return {
				authCreatedAt: data.authCreatedAt,
				authType: data.authType,
				data: data.data,
				inputs: data.inputs,
				rehydrateRedux: true
			};
		}
	} catch(e) {
		store.dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error parsing session data, refresh the page.'
		});
	}

	return false;
};

class GlobalPopupClass extends React.Component {
	handleClose = () => {
		store.dispatch({ type: GLOBAL_ERRORS_CLEAR, payload: null });
	};

	buttons = () => {
		return <Button label='Close' className='dtsvote-btn dtsvote-btn--primary
		   		dtsvote-u-ml-1d5 dtsvote-u-mb-1'
		   onClick={this.handleClose}/>;
	};

	render() {
		if (this.props.errors.length <= 0) {
			return (null);
		}

		return (
			<PopupWindow
				onCloseButtonCallback = { this.handleClose }
				onBackdropCallback    = { this.handleClose }
				onEnterKeyPressed     = { this.handleClose }
				footerChildren        = { this.buttons() }
			>
				<div className='message-wrapper'>
					<div className='message'>
						<div className='popup-title'>
							<h1 className='dtsvote-u-mt-0'>Alert:</h1>
						</div>
						<div className='popup-long-description'>
							<ul>
								{
									this.props.errors.map((item, i) => {
										return <li key={i}
												   className='dtsvote-u-mt-1'>
											{ item }
										</li>;
									})
								}
							</ul>
						</div>
					</div>
				</div>
			</PopupWindow>
		);
	}
}
function mapStateToProps({ global }) {
	return {
		errors: global.errors
	};
}
export const GlobalPopup = connect(mapStateToProps)(GlobalPopupClass);

export const MEDIA_SIZES = {
	SCREEN_SMALL_MIN_WIDTH: "(min-width: 768px)",
	SCREEN_SMALL_MAX_WIDTH: "(max-width: 767px)"
};