import axios from 'axios';

import { PATHS } from '../Routes';
import {
	ADDRESS_SEARCH_REQUEST,
	ADDRESS_SEARCH_RESPONSE,
	ADDRESS_SEARCH_ERROR,
	VOTER_SEARCH_REQUEST,
	VOTER_SEARCH_RESPONSE,
	VOTER_SEARCH_ERROR,
	SEARCH_REHYDRATE,
	SEARCH_LOGOUT,
	SEARCH_GET_GENERIC,
	SEARCH_GET_ELECTED_OFFICIALS,
	SEARCH_CLEAR_ELECTED_OFFICIALS,
	GLOBAL_ERRORS_SET,
} from './_index_action-types';
import {
	URL_BASENAME,
	SESSION_STORAGE_KEY,
	SEARCH_TYPES
} from '../App';

import {isVoteInPersonCounty} from "../AppVars";

function gatherErrors(json) {
	if (json.errorMessage) {
		return {
			errorMessage: json.errorMessage,
			firstName: json.errorFirstName,
			lastName: json.errorLastName,
			dob: json.errorDob,
			street: json.errorAddress,
			city: json.errorCity,
			zip: json.errorZip
		};
	}

	return null;
}

export const searchByAddress = ({
	street,
	city,
	zip
}, history, next, nextFromRedirect) => dispatch => {
	dispatch({ type: ADDRESS_SEARCH_REQUEST, payload: null });
	const postData = {
		street: street,
		city: city,
		zip: zip
	};
	axios({
		method: 'POST',
		url: `${URL_BASENAME}/public-api/my-address`,
		data: postData
	})
	.then(res => {
		// Note: there could be errors returned in the JSON.
		const errors = gatherErrors(res.data);
		const authCreatedAt = Date.now();
		const inputs = { street, city, zip };


		dispatch({ type: ADDRESS_SEARCH_RESPONSE, payload: {
			data: res.data,
			authCreatedAt,
			authType: SEARCH_TYPES.ADDRESS,
			inputs,
			errors,
		}});

		if (!errors) {
			const value = JSON.stringify({
				data: res.data,
				authCreatedAt,
				authType: SEARCH_TYPES.ADDRESS,
				inputs
			});
			sessionStorage.setItem(SESSION_STORAGE_KEY, value);

			if (isVoteInPersonCounty(res.data.countyId) && next !== 'candidates-and-issues') {
				next = 'vote-in-person-county';
			}

			if (nextFromRedirect) {
				history.push(nextFromRedirect);
			} else if (next === 'how-and-where-can-i-vote') {
				history.push(PATHS.HomeAsResident);
			} else if(next === 'candidates-and-issues') {
				history.push(PATHS.CandidatesIssues);
			} else if (next === 'vote-in-person-county') {
				history.push(PATHS.HomeAsResident);
			} else {
				history.push(PATHS.HomeAsResident);
			}
		}
	})
	.catch(err => {
		dispatch({ type: ADDRESS_SEARCH_ERROR, payload: err });
	});
};

export const searchByVoter = ({
	firstName,
	lastName,
	dob,
	street,
	city,
	zip
}, history, next, nextFromRedirect) => dispatch => {
	dispatch({ type: VOTER_SEARCH_REQUEST, payload: null });
	const _dob = dob.replace(/\//gi, '-');
	const postData = {
		firstName: firstName,
		lastName: lastName,
		dob: _dob,
		street: street,
		city: city,
		zip: zip
	};
	axios({
		method: 'POST',
		url: `${URL_BASENAME}/public-api/my-voter-profile`,
		data: postData
	})
	.then(res => {
		// Note: there could be errors returned in the JSON.
		const errors = gatherErrors(res.data);
		const authCreatedAt = Date.now();
		const inputs = {
			firstName,
			lastName,
			dob,
			street,
			city,
			zip
		};

		dispatch({ type: VOTER_SEARCH_RESPONSE, payload: {
			data: res.data,
			authCreatedAt,
			authType: SEARCH_TYPES.VOTER,
			inputs,
			errors
		}});

		if (!errors) {
			const value = JSON.stringify({
				data: res.data,
				authCreatedAt,
				authType: SEARCH_TYPES.VOTER,
				inputs
			});
			sessionStorage.setItem(SESSION_STORAGE_KEY, value);

		if (isVoteInPersonCounty(res.data.countyId) && next !== 'voter-info' && next !== 'track-mail-ballot') {
			next = 'vote-in-person-county';
		}

			if (nextFromRedirect) {
				history.push(nextFromRedirect);
			} else if (next === 'voter-info') {
				history.push(PATHS.VoterInfo);
			} else if(next === 'track-mail-ballot') {
				history.push(PATHS.TrackMailBallot);
			} else if (next === 'vote-in-person-county') {
				history.push(PATHS.HomeAsVoter);
			} else {
				history.push(PATHS.HomeAsVoter);
			}
		}
	})
	.catch(err => {
		dispatch({ type: VOTER_SEARCH_ERROR, payload: err });
	});
};

export const searchRehydrate = authDetails => dispatch => {
	dispatch({ type: SEARCH_REHYDRATE, payload: {
		data: authDetails.data,
		authCreatedAt: authDetails.authCreatedAt,
		authType: authDetails.authType,
		inputs: authDetails.inputs
	}});
};

export const searchLogout = (history, pushToRoot = false) => dispatch => {
	dispatch({ type: SEARCH_LOGOUT, payload: null });
	sessionStorage.clear();

	pushToRoot && history.push('/');
};

export const searchGetGeneric = () => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/public-api/getAppProperties.json/`
	})
	.then(res => {
		dispatch({ type: SEARCH_GET_GENERIC, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching election data.'
		});
	});
};

export const searchGetElectedOfficials = precinctId => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/electedOfficials.json/${precinctId}`
	})
	.then(res => {
		dispatch({ type: SEARCH_GET_ELECTED_OFFICIALS, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching elected officials. Refresh the page and try again.'
		});
	});
};

export const searchClearElectedOfficials = () => dispatch => {
	dispatch({ type: SEARCH_CLEAR_ELECTED_OFFICIALS, payload: null });
};
