// SEARCH/AUTH
export const ADDRESS_SEARCH_REQUEST = 'ADDRESS_SEARCH_REQUEST';
export const ADDRESS_SEARCH_RESPONSE = 'ADDRESS_SEARCH_RESPONSE';
export const ADDRESS_SEARCH_ERROR = 'ADDRESS_SEARCH_ERROR';

export const VOTER_SEARCH_REQUEST = 'VOTER_SEARCH_REQUEST';
export const VOTER_SEARCH_RESPONSE = 'VOTER_SEARCH_RESPONSE';
export const VOTER_SEARCH_ERROR = 'VOTER_SEARCH_ERROR';

export const SEARCH_REHYDRATE = 'SEARCH_REHYDRATE';
export const SEARCH_LOGOUT = 'SEARCH_LOGOUT';
export const SEARCH_GET_GENERIC = 'SEARCH_GET_GENERIC';

export const SEARCH_GET_ELECTED_OFFICIALS = 'SEARCH_GET_ELECTED_OFFICIALS';
export const SEARCH_CLEAR_ELECTED_OFFICIALS = 'SEARCH_CLEAR_ELECTED_OFFICIALS';

// HOW/WHERE CAN I VOTE?
export const VOTEWHERE_GET_EARLYLOCS = 'VOTE_WHERE_GET_EARLY_LOCS';
export const VOTEWHERE_RESET = 'VOTEWHERE_CLEAR';
export const VOTEWHERE_GET_ELECTIONLOCS = 'VOTEWHERE_GET_ELECTIONLOCS';
export const VOTEWHERE_GET_DROPBOXLOCS = 'VOTEWHERE_GET_DROPBOXLOCS';

// LEARN ABOUT CANDIDATES & ISSUES
export const SAMPLEBALLOT_GET_DATA = 'SAMPLEBALLOT_GET_DATA';
export const SAMPLEBALLOT_CLEAR_DATA = 'SAMPLEBALLOT_CLEAR_DATA';
export const SAMPLEBALLOT_GET_PROFILE_REQ = 'SAMPLEBALLOT_GET_PROFILE_REQ';
export const SAMPLEBALLOT_GET_PROFILE_RES = 'SAMPLEBALLOT_GET_PROFILE_RES';
export const SAMPLEBALLOT_CLEAR_PROFILE = 'SAMPLEBALLOT_CLEAR_PROFILE';

// TRACK MY BALLOT
export const TRACK_BALLOT_GET_DATA = 'TRACK_BALLOT_GET_DATA';
export const TRACK_BALLOT_CLEAR_DATA = 'TRACK_BALLOT_CLEAR_DATA';

// CANDIDATE ACTIONS
export const CANDIDATE_VERIFY_PASSCODE_REQ = 'CANDIDATE_VERIFY_PASSCODE_REQ';
export const CANDIDATE_VERIFY_PASSCODE_RES = 'CANDIDATE_VERIFY_PASSCODE_RES';
export const CANDIDATE_GET_LIST_REQ = 'CANDIDATE_GET_LIST_REQ';
export const CANDIDATE_GET_LIST_RES = 'CANDIDATE_GET_LIST_RES';
export const CANDIDATE_GET_LIST_ERROR = 'CANDIDATE_GET_LIST_ERROR';
export const CANDIDATE_UPDATE_REQ = 'CANDIDATE_UPDATE_REQ';
export const CANDIDATE_UPDATE_RES = 'CANDIDATE_UPDATE_RES';
export const CANDIDATE_REQUEST_PASSCODE = 'CANDIDATE_REQUEST_PASSCODE';
export const CANDIDATE_RESET_FETCHING = 'CANDIDATE_RESET_FETCHING';

// GLOBAL ACTIONS
export const GLOBAL_ERRORS_SET = 'GLOBAL_ERRORS_SET';
export const GLOBAL_ERRORS_CLEAR = 'GLOBAL_ERRORS_CLEAR';
export const GLOBAL_GET_APP_VERSION = 'GLOBAL_GET_APP_VERSION';

// LOGIN ACTIONS
export const LOGIN_OIDC_SET = 'LOGIN_OIDC_SET';
