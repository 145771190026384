import axios from 'axios';
import { URL_BASENAME } from '../App';

import { GLOBAL_ERRORS_SET, GLOBAL_GET_APP_VERSION } from './_index_action-types';

export const globalErrorsSet = error => dispatch => {
	dispatch({ type: GLOBAL_ERRORS_SET,
		payload: error
	});
};

export const globalGetAppVersion = () => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/version.txt`
	})
	.then(res => {
		dispatch({ type: GLOBAL_GET_APP_VERSION, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching app version.'
		});
	});
};
