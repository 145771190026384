import React from 'react';
import { getLocalAuthDetails, getDisplayName } from '../misc/util';

export default function(WrappedComponent) {
	class ClearInactive extends React.Component {
		constructor(props) {
			super(props);

			this.timeoutId = 0;
			const auth = getLocalAuthDetails();
			if (auth !== false) {
				// Existing auth data, wait awhile then clear.
				const WAIT_MS_BEFORE_CLEAR = 1000 * 60 * 5; // 5 minutes.
				this.timeoutId = setTimeout(() => {
					sessionStorage.clear();
					window.location.reload(); // clear redux
				}, WAIT_MS_BEFORE_CLEAR);
			}
		}

		componentWillUnmount() {
			clearTimeout(this.timeoutId);
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	}

	ClearInactive.displayName =
		`withClearInactive(${getDisplayName(WrappedComponent)})`;

	return ClearInactive;
}
