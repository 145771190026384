import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'dts-react-common';
import PropTypes from 'prop-types';
import Media from "react-media";
import {MEDIA_SIZES} from "../misc/util";

import { PATHS } from '../Routes';
import { searchLogout } from '../actions/search_actions';
import imgBg from '../misc/images/header-bg-blue.png';
const styleBg = {
	background: `url(${imgBg}) repeat-x center top transparent`
};
import logo from '../misc/images/header-seal.png';
import logoSmall from '../misc/images/header-bg-seal-small.png';
import { imgFacebook, imgTwitter } from '../misc/icons/icons';

class Header extends React.Component {
	render() {
		return (
			<header id='dtsvote-header' className='dtsvote-c-header'>
				<div className='dtsvote-c-header__band--blue' style={styleBg}>
					<div className='dtsvote-c-header__band--blue-wrapper'>
						<a href='https://vote.utah.gov' className="dtsvote-c-header__logo">
							<img src={logo} alt='Utah state seal' width='156px' height='174px'/>
						</a>
						<a href='https://vote.utah.gov' className="dtsvote-c-header__logo--small">
							<img src={logoSmall} alt='Utah state seal' width='75px' height='75px'/>
						</a>
						<div className='dtsvote-c-header__text'>
							<Media query={MEDIA_SIZES.SCREEN_SMALL_MIN_WIDTH}>
								{matches => matches ? (
									<h2>LIEUTENANT GOVERNOR DEIDRE M. HENDERSON WELCOMES YOU TO</h2>
								) : (
									<h2>WELCOME TO</h2>
								)}
							</Media>
							<h1><a href='https://vote.utah.gov'>Vote.Utah.gov</a></h1>
						</div>
						<div className='dtsvote-c-header__right'>
							<div className='dtsvote-c-header__right__social'>
								<a href={PATHS.Facebook}>
									{ imgFacebook(35, 35) }
								</a>

								<a href={PATHS.Twitter} className='dtsvote-u-pl-1'>
									{ imgTwitter(35, 35) }
								</a>
							</div>
							<div className='dtsvote-c-header__right__logout'>
								{
									this.props.auth ?
										<Button label='LOGOUT'
												className='dtsvote-btn dtsvote-btn--primary'
												onClick={() =>
													this.props.searchLogout(this.props.history, true)} />
										: undefined
								}
							</div>
						</div>
					</div>
				</div>
				<div className='dtsvote-c-header__band--gray'></div>
				<div className='dtsvote-c-header__band--trans'></div>
			</header>
		);
	}
}

Header.propTypes = {
	// Used to check for auth (either voter or residence/address).
	auth: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

	// Clears redux/session and pushes to root.
	searchLogout: PropTypes.func.isRequired,

	// From react-router-dom via withRouter
	history: PropTypes.object.isRequired
};

function mapStateToProps({ search }) {
	return { auth: search.authType }
}

export default withRouter(connect(mapStateToProps, { searchLogout } )(Header));
