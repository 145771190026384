import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {searchGetGeneric} from '../../actions/search_actions';
import RequireAuth from '../../hocs/RequireAuth';
import Accordion from '../Accordion';
import {
	sampleBallotGetData,
	sampleBallotResetData,
	sampleBallotGetProfile,
	sampleBallotClearProfile
} from '../../actions/sampleBallot_actions';
import { AjaxSpinner, PopupWindow, Button } from 'dts-react-common';
import { imgCandidates } from '../../misc/icons/icons';
import {replaceBody, replaceBodyBold} from "../../AppVars";

import PageTitle from '../PageTitle';
import {NavLink} from "react-router-dom";
import {PATHS} from "../../Routes";

class CandidatesIssues extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accordionToggleAll: true
		};
	}

	componentDidMount() {
		if (!this.props.generic) {
			// Restore `generic` in case app is refreshed.
			this.props.searchGetGeneric();
		}
	}

	componentWillUnmount() {
		this.props.sampleBallotResetData();
	}

	renderProfilePopup = () => {
		const handleClose = () => {
			this.props.sampleBallotClearProfile();
		};

		const buttons = () => {
			return <Button label='Close' className='dtsvote-btn dtsvote-btn--primary
		   		dtsvote-u-ml-1d5 dtsvote-u-mb-1'
						   onClick={handleClose}/>;
		};

		const {
			errorMessage,
			age,
			candidateImage,
			cityOfResidence,        // *Required
			cityResidenceYear,      // *Required
			contactEmail,           // *Required
			message,                // *Required
			occupation,
			name,                   // *Required
			websiteUrl
		} = this.props.profile;

		return <PopupWindow
			onCloseButtonCallback = { handleClose }
			onBackdropCallback    = { handleClose }
			onEnterKeyPressed     = { handleClose }
			footerChildren        = { buttons() }
			className="popup-window--large"
		>
		<div className='message-wrapper'>
		{ errorMessage ?
			<div className='message'>
				<div className='popup-title'>
					<h1 className='dtsvote-u-mt-0'>Error:</h1>
				</div>
				<div className='popup-long-description'>
					{errorMessage}
				</div>
			</div>
		:	<div className='message'>
				<div className='popup-title'>
				<h1 className='dtsvote-u-mt-0 dtsvote-u-text-center dtsvote-u-mb-1'>{name.toUpperCase()}</h1>
				</div>
				<div className='popup-long-description'>
					{ !candidateImage ? undefined :
						<img src={`${candidateImage}`}
							 alt='profile picture'
							 className='dtsvote-u-mx-auto dtsvote-c-candidate__profile-img'/>
					}
					{ !cityOfResidence ? undefined :
						<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>
							City of Residence: <span className='dtsvote-u-text-body dtsvote-fs-h6'>{cityOfResidence}</span></h2>
					}
					{ !cityResidenceYear ? undefined :
						<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>
							Resident For: <span className='dtsvote-u-text-body dtsvote-fs-h6'>{cityResidenceYear} years</span></h2>
					}
					{ !occupation ? undefined :
						<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>
							Occupation: <span className='dtsvote-u-text-body dtsvote-fs-h6'>{occupation}</span></h2>
					}
					{ !age ? undefined :
						<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>
							Age: <span className='dtsvote-u-text-body dtsvote-fs-h6'>{age}</span></h2>
					}
					{ !contactEmail ? undefined :
						<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>
							Contact Email: <span className='dtsvote-u-text-body dtsvote-fs-h6'>{contactEmail}</span></h2>
					}
					{ !websiteUrl ? undefined :
						<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>
							Website: <span className='dtsvote-u-text-body dtsvote-fs-h6'>{websiteUrl}</span></h2>
					}
					{ !message ? undefined :
						<div className='dtsvote-u-mt-1'>
							<h2 className='dtsvote-u-mt-1 dtsvote-u-d-block dtsvote-fs-h5'>Message:</h2>
							<div dangerouslySetInnerHTML={{__html: message}} />
						</div>
					}
				</div>
			</div>
		}
		</div>
		</PopupWindow>
	};

	handleProfileClick = id => {
		this.props.sampleBallotGetProfile(id);
	};

	renderAccordion() {
		const isCaucus = this.props.generic && this.props.generic.electionPhase === 'Caucus';
		const isPrimary = this.props.generic && this.props.generic.electionPhase === 'Primary';
		const { electionDate : election_date, name : electionName } = this.props.residence.election || {};
		const electionDate = new Date(election_date);
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		let electionDateFormatted = months[electionDate.getMonth()] + ', ' + electionDate.getDate() + ' ' + electionDate.getFullYear();
		let electionDayAndName = electionDateFormatted + ' ' + electionName + ' election';

		const list = this.props.data.raceList.map((race, i) => {
			const panelContentsListItems = race.candidateList.map((candidate, i) => {
				const {
					candidateName: name,
					candidateParty: party,
					judicialUrl: url,
					candidateId: id } = candidate;

				if (race.isAmendmentProposition) {
					return (
						<li className='dtsvote-c-home__accordion-item' key={i}>
							<span>
								{name ? name : 'N/A'}
							</span>
						</li>
					);
				}

				if (race.isJudicial) {
					return (
						<li className='dtsvote-c-home__accordion-item' key={i}>
							<span className='dtsvote-u-w-80 dtsvote-u-text-left dtsvote-u-pr-0d5'>
								{name ? name : 'N/A'}
							</span>
							<span className='dtsvote-u-w-20 dtsvote-u-text-right'>
								{url ? <span className='dtsvote-u-d-block dtsvote-u-p-0d5'><a href={url} target="_blank">Read profile (opens new window)</a></span> : 'Yes/No'}
							</span>
						</li>
					);
				}

				return (
					<li className='dtsvote-c-home__accordion-item' key={i}>
						<span className='dtsvote-u-w-40 dtsvote-u-text-left'>
							{name ? name : 'N/A'}
						</span>
						<span className='dtsvote-u-w-40 dtsvote-u-text-left dtsvote-u-px-0d5'>
							{party ? party : ''}
						</span>
						<span className='dtsvote-u-w-20 dtsvote-u-text-right'>
							<button className='dtsvote-btn dtsvote-btn--link' onClick={(e) => this.props.sampleBallotGetProfile(id)}>Read profile</button>
						</span>
					</li>
				);
			});

			const raceName = race.cancelled ?
				`${race.raceName} (CANCELLED)` : race.raceName;

			return {
				headerLabel: raceName,
				panelContents: <div>
					<p className='dtsvote-u-mt-0 dtsvote-u-text-center'>
						{race.cancelled ? <span className='dtsvote-u-d-block dtsvote-u-p-0d5'>This election has been cancelled.</span> : undefined}
						{race.raceSubDescription ? <pre>{race.raceSubDescription}</pre> : undefined}
						{race.isAmendmentProposition ? <span className='dtsvote-u-d-block dtsvote-u-p-0d5'><a href={race.externalUrl} target="_blank">Read more information on this ballot question (opens new window)</a></span> : undefined}
					</p>
					<ul className='dtsvote-c-home__accordion-list' key={i}>
						{panelContentsListItems}</ul>
				</div>
			};
		});

		let bodyText = (<p className='dtsvote-u-px-0d5'>
				To view candidate biographies or read ballot proposition information, click on a contest below.
			</p>);

		if (isPrimary) {
			bodyText = (<p className='dtsvote-u-px-0d5'>
				Below is a list of primary election contests in your area.  Click on a contest to view candidates
				and read their profiles.  Due to primary election rules, your ballot may not include all contests
				listed below.  Please click this <NavLink to={PATHS.ElectionFAQ}>link</NavLink> for more information on these rules.
			</p>);
		}

		if (isCaucus) {
			bodyText = (<div>
					<p className='dtsvote-u-px-0d5'>
						To view candidate biographies or read ballot proposition information, click on a contest below.
					</p>
					<p className='dtsvote-u-px-0d5'>
						Learn About the Candidates & Issues should display candidate and ballot data for the {electionDayAndName}.
					</p>
				</div>
			);
		}

		return <div>
			{bodyText}
			<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
				onClick={() => {
				this.state.accordionToggleAll
					? Accordion.expandAll('dtsvote')
					: Accordion.collapseAll('dtsvote');
				this.setState({
					accordionToggleAll: !this.state.accordionToggleAll});
			}}>
				{this.state.accordionToggleAll
					? 'Expand All' : 'Collapse All'}
			</button>
			<Accordion
				list={list}
				id='acc1'
				headingLevel={2}
				namespace='dtsvote'
			/>
		</div>;
	}

	render() {
		let pageStatus = (
			replaceBodyBold(noInfoMessage)
		);
		if (this.props.data && this.props.data.errorMessage) {
			pageStatus = (
				replaceBody(canceledElectionMessage)
				);
		} else if (!this.props.data && this.props.search) {
			if (this.props.search.electionId && this.props.search.precinctId) {
				this.props.sampleBallotGetData(this.props.search.precinctId, this.props.search.electionId);
				pageStatus = (
					<div className='dtsvote-u-ml-0d5 dtsvote-u-text-center'>
						<h3>Loading...</h3>
					</div>
				);
			} else if (this.props.generic && this.props.generic.electionPhase === 'Municipal') {
				pageStatus = (
					replaceBody(noMunicipalElectionMessage)
				);
			} else {
				pageStatus = (
					replaceBodyBold(noElectionMessage)
				);
			}
		}

		return(
			<div id='CandidatesIssues'>
				<PageTitle image={imgCandidates(50, 50)} title="LEARN ABOUT THE CANDIDATES & ISSUES"/>
				{
					this.props.fetchingProfile ? <AjaxSpinner backdropType={AjaxSpinner.BACKDROPS.COVER_PARENT} /> : undefined
				}
				{
					this.props.profile ? this.renderProfilePopup() : undefined
				}
				{
					this.props.data && this.props.data.raceList
						? this.renderAccordion()
						: pageStatus
				}
			</div>
		);
	}
}

CandidatesIssues.propTypes = {
	// The sample ballot info from API response.
	data: PropTypes.object,
	// Need precinctId and electionId for API request.
	search: PropTypes.object,

	// Get/reset the sample ballot data.
	sampleBallotGetData: PropTypes.func.isRequired,
	sampleBallotResetData: PropTypes.func.isRequired,

	// Used to show ajax spinner when fetching a profile.
	fetchingProfile: PropTypes.bool.isRequired,

	// Actions to fetch/clear candidate public candidate profile data.
	sampleBallotGetProfile: PropTypes.func.isRequired,
	sampleBallotClearProfile: PropTypes.func.isRequired,

	// The fetched 'Read more' profile data.
	profile: PropTypes.object,

	// Redux action creator to fetch misc. app data.
	searchGetGeneric: PropTypes.func.isRequired
};

function mapStateToProps({ sampleBallot, search }) {
	return {
		data: sampleBallot.data,
		search: search.data,
		residence: search.data,
		fetchingProfile: sampleBallot.fetchingProfile,
		profile: sampleBallot.profile,
		generic: search.generic
	};
}

export default connect(mapStateToProps, {
	sampleBallotGetData,
	sampleBallotResetData,
	sampleBallotGetProfile,
	sampleBallotClearProfile,
	searchGetGeneric
})(RequireAuth(CandidatesIssues));
