import React from 'react';

export const imgLocation = (width = 28, height = 28) => (
	<svg id='fa-icon-map-marker' viewBox='0 0 16 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-map-marker-title'>
		<title id='fa-icon-map-marker-title'>map-marker</title>
		<path d='M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM16 10c0 0.953-0.109 1.937-0.516 2.797l-5.688 12.094c-0.328 0.688-1.047 1.109-1.797 1.109s-1.469-0.422-1.781-1.109l-5.703-12.094c-0.406-0.859-0.516-1.844-0.516-2.797 0-4.422 3.578-8 8-8s8 3.578 8 8z'></path>
	</svg>
);

export const imgCandidates = (width = 28, height = 28) => (
	<svg id='fa-icon-users' viewBox='0 0 30 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-users-title'>
		<title id='fa-icon-users-title'>users</title>
		<path d="M9.266 14c-1.625 0.047-3.094 0.75-4.141 2h-2.094c-1.563 0-3.031-0.75-3.031-2.484 0-1.266-0.047-5.516 1.937-5.516 0.328 0 1.953 1.328 4.062 1.328 0.719 0 1.406-0.125 2.078-0.359-0.047 0.344-0.078 0.688-0.078 1.031 0 1.422 0.453 2.828 1.266 4zM26 23.953c0 2.531-1.672 4.047-4.172 4.047h-13.656c-2.5 0-4.172-1.516-4.172-4.047 0-3.531 0.828-8.953 5.406-8.953 0.531 0 2.469 2.172 5.594 2.172s5.063-2.172 5.594-2.172c4.578 0 5.406 5.422 5.406 8.953zM10 4c0 2.203-1.797 4-4 4s-4-1.797-4-4 1.797-4 4-4 4 1.797 4 4zM21 10c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6zM30 13.516c0 1.734-1.469 2.484-3.031 2.484h-2.094c-1.047-1.25-2.516-1.953-4.141-2 0.812-1.172 1.266-2.578 1.266-4 0-0.344-0.031-0.688-0.078-1.031 0.672 0.234 1.359 0.359 2.078 0.359 2.109 0 3.734-1.328 4.062-1.328 1.984 0 1.937 4.25 1.937 5.516zM28 4c0 2.203-1.797 4-4 4s-4-1.797-4-4 1.797-4 4-4 4 1.797 4 4z"></path>
	</svg>
);

export const imgMailOpen = (width = 28, height = 28) => (
	<svg id='fa-icon-envelope-open' viewBox='0 0 28 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-envelope-open-title'>
		<title id='fa-icon-envelope-open-title'>envelope-open</title>
		<path d="M28 10.219v15.281c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-15.281c0-0.141 0.063-0.281 0.172-0.375 1.641-1.437 1.656-1.609 9.812-7.562 0.984-0.719 2.719-2.281 4.016-2.281s3.047 1.578 4.016 2.281c8.156 5.953 8.172 6.125 9.812 7.562 0.109 0.094 0.172 0.234 0.172 0.375zM19.187 19.359c2.438-1.766 4.141-3 5.391-3.938 0.219-0.156 0.266-0.469 0.094-0.688l-0.594-0.812c-0.172-0.219-0.484-0.266-0.703-0.094-1.234 0.906-2.922 2.156-5.359 3.906-0.969 0.703-2.719 2.266-4.016 2.266s-3.047-1.563-4.016-2.266c-2.438-1.766-4.125-3-5.359-3.906-0.219-0.172-0.531-0.125-0.703 0.094l-0.594 0.812c-0.172 0.219-0.125 0.531 0.094 0.688 1.25 0.938 2.953 2.172 5.391 3.938 1.219 0.875 3.141 2.641 5.187 2.641 2.063 0 4.031-1.797 5.187-2.641z"></path>
	</svg>
);

export const imgSearch = (width = 28, height = 28) => (
	<svg id='fa-icon-search' viewBox='0 0 26 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-search-title'>
		<title id='fa-icon-search-title'>search</title>
		<path d="M18 13c0-3.859-3.141-7-7-7s-7 3.141-7 7 3.141 7 7 7 7-3.141 7-7zM26 26c0 1.094-0.906 2-2 2-0.531 0-1.047-0.219-1.406-0.594l-5.359-5.344c-1.828 1.266-4.016 1.937-6.234 1.937-6.078 0-11-4.922-11-11s4.922-11 11-11 11 4.922 11 11c0 2.219-0.672 4.406-1.937 6.234l5.359 5.359c0.359 0.359 0.578 0.875 0.578 1.406z"></path>
	</svg>
);

export const imgPencil = (width = 28, height = 28) => (
	<svg id='fa-icon-pencil' viewBox='0 0 24 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-pencil-title'>
		<title id='fa-icon-pencil-title'>pencil</title>
		<path d="M5.672 24l1.422-1.422-3.672-3.672-1.422 1.422v1.672h2v2h1.672zM13.844 9.5c0-0.203-0.141-0.344-0.344-0.344-0.094 0-0.187 0.031-0.266 0.109l-8.469 8.469c-0.078 0.078-0.109 0.172-0.109 0.266 0 0.203 0.141 0.344 0.344 0.344 0.094 0 0.187-0.031 0.266-0.109l8.469-8.469c0.078-0.078 0.109-0.172 0.109-0.266zM13 6.5l6.5 6.5-13 13h-6.5v-6.5zM23.672 8c0 0.531-0.219 1.047-0.578 1.406l-2.594 2.594-6.5-6.5 2.594-2.578c0.359-0.375 0.875-0.594 1.406-0.594s1.047 0.219 1.422 0.594l3.672 3.656c0.359 0.375 0.578 0.891 0.578 1.422z"></path>
	</svg>
);

export const imgMailClosed = (width = 28, height = 28) => (
	<svg id='fa-icon-envelope' viewBox='0 0 28 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-envelope-title'>
		<title id='fa-icon-envelope-title'>envelope</title>
		<path d="M28 11.094v12.406c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-12.406c0.469 0.516 1 0.969 1.578 1.359 2.594 1.766 5.219 3.531 7.766 5.391 1.313 0.969 2.938 2.156 4.641 2.156h0.031c1.703 0 3.328-1.188 4.641-2.156 2.547-1.844 5.172-3.625 7.781-5.391 0.562-0.391 1.094-0.844 1.563-1.359zM28 6.5c0 1.75-1.297 3.328-2.672 4.281-2.438 1.687-4.891 3.375-7.313 5.078-1.016 0.703-2.734 2.141-4 2.141h-0.031c-1.266 0-2.984-1.437-4-2.141-2.422-1.703-4.875-3.391-7.297-5.078-1.109-0.75-2.688-2.516-2.688-3.938 0-1.531 0.828-2.844 2.5-2.844h23c1.359 0 2.5 1.125 2.5 2.5z"></path>
	</svg>
);

export const imgPhone = (width = 28, height = 28) => (
	<svg id='fa-icon-phone' viewBox='0 0 22 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-phone-title'>
		<title id='fa-icon-phone-title'>phone</title>
		<path d="M22 19.375c0 0.562-0.25 1.656-0.484 2.172-0.328 0.766-1.203 1.266-1.906 1.656-0.922 0.5-1.859 0.797-2.906 0.797-1.453 0-2.766-0.594-4.094-1.078-0.953-0.344-1.875-0.766-2.734-1.297-2.656-1.641-5.859-4.844-7.5-7.5-0.531-0.859-0.953-1.781-1.297-2.734-0.484-1.328-1.078-2.641-1.078-4.094 0-1.047 0.297-1.984 0.797-2.906 0.391-0.703 0.891-1.578 1.656-1.906 0.516-0.234 1.609-0.484 2.172-0.484 0.109 0 0.219 0 0.328 0.047 0.328 0.109 0.672 0.875 0.828 1.188 0.5 0.891 0.984 1.797 1.5 2.672 0.25 0.406 0.719 0.906 0.719 1.391 0 0.953-2.828 2.344-2.828 3.187 0 0.422 0.391 0.969 0.609 1.344 1.578 2.844 3.547 4.813 6.391 6.391 0.375 0.219 0.922 0.609 1.344 0.609 0.844 0 2.234-2.828 3.187-2.828 0.484 0 0.984 0.469 1.391 0.719 0.875 0.516 1.781 1 2.672 1.5 0.313 0.156 1.078 0.5 1.188 0.828 0.047 0.109 0.047 0.219 0.047 0.328z"></path>
	</svg>
);

export const imgId = (width = 28, height = 28) => (
	<svg id='fa-icon-drivers-license' viewBox='0 0 32 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-drivers-license-title'>
		<title id='fa-icon-drivers-license-title'>drivers-license</title>
		<path d="M14 18.937c0-2.063-0.5-4.438-2.562-4.438-0.625 0.625-1.484 1-2.438 1s-1.813-0.375-2.438-1c-2.063 0-2.562 2.375-2.562 4.438 0 1.141 0.75 2.063 1.672 2.063h6.656c0.922 0 1.672-0.922 1.672-2.063zM12 12c0-1.656-1.344-3-3-3s-3 1.344-3 3 1.344 3 3 3 3-1.344 3-3zM28 19.5v-1c0-0.281-0.219-0.5-0.5-0.5h-11c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h11c0.281 0 0.5-0.219 0.5-0.5zM22 15.5v-1c0-0.281-0.219-0.5-0.5-0.5h-5c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h5c0.281 0 0.5-0.219 0.5-0.5zM28 15.5v-1c0-0.281-0.219-0.5-0.5-0.5h-3c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h3c0.281 0 0.5-0.219 0.5-0.5zM28 11.5v-1c0-0.281-0.219-0.5-0.5-0.5h-11c-0.281 0-0.5 0.219-0.5 0.5v1c0 0.281 0.219 0.5 0.5 0.5h11c0.281 0 0.5-0.219 0.5-0.5zM2 6h28v-1.5c0-0.281-0.219-0.5-0.5-0.5h-27c-0.281 0-0.5 0.219-0.5 0.5v1.5zM32 4.5v19c0 1.375-1.125 2.5-2.5 2.5h-27c-1.375 0-2.5-1.125-2.5-2.5v-19c0-1.375 1.125-2.5 2.5-2.5h27c1.375 0 2.5 1.125 2.5 2.5z"></path>
	</svg>
);

export const imgTwitter = (width = 28, height = 28) => (
	<svg id='fa-icon-twitter-square' viewBox='0 0 24 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-twitter-square-title'>
		<title id='fa-icon-twitter-square-title'>twitter-square</title>
		<path d="M20 9.531c-0.594 0.266-1.219 0.438-1.891 0.531 0.688-0.406 1.203-1.062 1.453-1.828-0.641 0.375-1.344 0.656-2.094 0.797-0.594-0.641-1.453-1.031-2.391-1.031-1.813 0-3.281 1.469-3.281 3.281 0 0.25 0.016 0.516 0.078 0.75-2.734-0.141-5.156-1.437-6.781-3.437-0.281 0.484-0.453 1.062-0.453 1.656 0 1.141 0.531 2.141 1.422 2.734-0.547-0.016-1.062-0.172-1.563-0.406v0.031c0 1.594 1.203 2.922 2.703 3.219-0.281 0.078-0.5 0.125-0.797 0.125-0.203 0-0.406-0.031-0.609-0.063 0.422 1.297 1.625 2.25 3.063 2.281-1.125 0.875-2.531 1.406-4.078 1.406-0.266 0-0.531-0.016-0.781-0.047 1.453 0.922 3.172 1.469 5.031 1.469 6.031 0 9.344-5 9.344-9.344 0-0.141 0-0.281-0.016-0.422 0.641-0.453 1.203-1.031 1.641-1.703zM24 6.5v15c0 2.484-2.016 4.5-4.5 4.5h-15c-2.484 0-4.5-2.016-4.5-4.5v-15c0-2.484 2.016-4.5 4.5-4.5h15c2.484 0 4.5 2.016 4.5 4.5z"></path>
	</svg>
);

export const imgFacebook = (width = 28, height = 28) => (
	<svg id='fa-icon-facebook-square' viewBox='0 0 24 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-facebook-square-title'>
		<title id='fa-icon-facebook-square-title'>facebook-square</title>
		<path d="M19.5 2c2.484 0 4.5 2.016 4.5 4.5v15c0 2.484-2.016 4.5-4.5 4.5h-2.938v-9.297h3.109l0.469-3.625h-3.578v-2.312c0-1.047 0.281-1.75 1.797-1.75l1.906-0.016v-3.234c-0.328-0.047-1.469-0.141-2.781-0.141-2.766 0-4.672 1.687-4.672 4.781v2.672h-3.125v3.625h3.125v9.297h-8.313c-2.484 0-4.5-2.016-4.5-4.5v-15c0-2.484 2.016-4.5 4.5-4.5h15z"></path>
	</svg>
);

export const imgCheck = (width = 28, height = 28) => (
	<svg id='fa-icon-check' viewBox='0 0 28 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-check-title'>
		<title id='fa-icon-check-title'>check</title>
		<path d="M26.109 8.844c0 0.391-0.156 0.781-0.438 1.062l-13.438 13.438c-0.281 0.281-0.672 0.438-1.062 0.438s-0.781-0.156-1.062-0.438l-7.781-7.781c-0.281-0.281-0.438-0.672-0.438-1.062s0.156-0.781 0.438-1.062l2.125-2.125c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l4.594 4.609 10.25-10.266c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l2.125 2.125c0.281 0.281 0.438 0.672 0.438 1.062z"></path>
	</svg>
);

export const imgProfile = (width = 150, height = 150) => (
	<svg id='fa-icon-user-circle-o' viewBox='0 0 28 28' width={`${width}px`} height={`${height}px`} aria-labelledby='fa-icon-user-circle-o-title'>
		<title id='fa-icon-user-circle-o-title'>user-circle-o</title>
		<path d="M14 0c7.734 0 14 6.266 14 14 0 7.688-6.234 14-14 14-7.75 0-14-6.297-14-14 0-7.734 6.266-14 14-14zM23.672 21.109c1.453-2 2.328-4.453 2.328-7.109 0-6.609-5.391-12-12-12s-12 5.391-12 12c0 2.656 0.875 5.109 2.328 7.109 0.562-2.797 1.922-5.109 4.781-5.109 1.266 1.234 2.984 2 4.891 2s3.625-0.766 4.891-2c2.859 0 4.219 2.312 4.781 5.109zM20 11c0-3.313-2.688-6-6-6s-6 2.688-6 6 2.688 6 6 6 6-2.688 6-6z"></path>
	</svg>
);

export const circleDown = () => {
	return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
		<title></title>
		<path d="M32 16c0-8.837-7.163-16-16-16s-16 7.163-16 16 7.163 16 16 16 16-7.163 16-16zM3 16c0-7.18 5.82-13 13-13s13 5.82 13 13-5.82 13-13 13-13-5.82-13-13z"></path>
		<path d="M9.914 11.086l-2.829 2.829 8.914 8.914 8.914-8.914-2.828-2.828-6.086 6.086z"></path>
	</svg>
};

export const imgQuestion = (width = 28, height = 28) => (
	<svg aria-hidden="true" focusable="false"  role="img" xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0 0 512 512">
		<title>Question Mark Icon</title>
		<path fill="currentColor"
			  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path>
	</svg>
);

export const stateSeal = () => {
	return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				width="172px" height="172px" viewBox="0 0 172 172">
		<title></title>
		<g id="State_Seal">
			<path d="M79.156,5.584c0.649,0.525,1.465,0.793,2.393,0.799h0.037c3.251,0,3.708-2.618,5.333-4.109
		c0.458-0.422,1.096-0.303,1.141-1.029c-0.758-0.523-1.642-0.743-2.524-0.743c-1.385,0-2.764,0.543-3.637,1.313
		C80.648,2.921,80.352,4.813,79.156,5.584z M74.608,6.913c3.187,0,4.515-3.938,6.377-5.439c-0.495-0.423-0.962-0.607-1.601-0.683
		c-0.28-0.034-0.547-0.052-0.8-0.052c-3.665,0.001-4.654,3.477-6.381,5.53C73.112,6.72,73.905,6.913,74.608,6.913z M88.172,6.269
		c0.252,0.035,0.492,0.053,0.723,0.053c3.17,0,4.428-3.17,6.578-4.507c-0.699-0.591-1.395-0.949-2.393-1.024
		c-0.164-0.013-0.323-0.019-0.479-0.019c-3.303,0-4.686,2.866-6.367,4.585C86.747,5.947,87.386,6.159,88.172,6.269z M94.105,5.014
		C93.701,5.029,93.6,5.34,93.189,5.357c0.617,1.034,1.976,1.524,3.321,1.524c0.229,0,0.456-0.014,0.679-0.042
		c2.445-0.305,3.697-3.208,5.699-3.768c-0.773-0.942-2.078-1.37-3.41-1.37c-1.156,0-2.334,0.324-3.201,0.914
		C95.252,3.312,94.841,4.41,94.105,5.014z M67.066,8.327h0.09c3.812,0,4.367-3.972,6.415-5.941c-0.416-0.371-0.849-0.511-1.368-0.57
		c-0.269-0.03-0.523-0.044-0.764-0.044c-3.986,0-4.471,3.914-6.2,5.984C65.75,8.125,66.417,8.311,67.066,8.327z M103.654,8.211
		h0.031c2.793-0.023,3.934-2.823,6.281-3.197c-0.683-1.246-1.943-1.74-3.195-1.827c-0.154-0.011-0.303-0.017-0.447-0.017
		c-3.15,0-4.177,2.533-6.171,3.441C100.934,7.63,102.33,8.211,103.654,8.211z M60.255,10.342c3.498,0,4.356-4.318,6.009-6.586
		c-0.473-0.228-0.991-0.324-1.518-0.324c-1.468,0-2.993,0.749-3.727,1.468c-1.459,1.427-1.391,3.842-2.507,5.137
		C59.16,10.248,59.736,10.342,60.255,10.342z M110.646,10.037c0.058,0.002,0.116,0.004,0.174,0.004c2.576,0,4.069-2.46,6.214-2.741
		c-0.779-0.907-1.622-1.706-2.848-1.942c-0.342-0.067-0.67-0.098-0.984-0.098c-2.502,0-4.191,1.929-6.09,2.722
		C107.699,9.316,109.278,9.98,110.646,10.037z M53.765,12.75c3.75,0,4.319-4.837,5.66-7.166c-0.419-0.092-0.827-0.136-1.218-0.136
		C55.583,5.45,53.648,7.4,53.143,9.694c-0.263,1.21-0.171,2.178-0.797,2.855C52.865,12.687,53.335,12.75,53.765,12.75z
		 M117.631,12.391c0.286,0,0.582-0.023,0.887-0.073c1.66-0.258,2.988-1.958,4.811-1.958c0.107,0,0.218,0.007,0.329,0.02
		c-0.587-1.446-1.868-2.311-3.425-2.51c-0.247-0.033-0.483-0.048-0.709-0.048c-2.428,0-3.783,1.707-5.796,2.102
		C114.359,11.418,115.794,12.391,117.631,12.391z M50.18,14.606c1.539-1.577,0.98-4.569,2.394-6.279
		c-0.322-0.052-0.634-0.076-0.938-0.076c-2.802,0-4.724,2.113-4.991,4.98c-0.125,1.326-0.176,2.021-0.456,2.743
		c0.093,0.003,0.184,0.004,0.273,0.004C48.096,15.978,49.298,15.508,50.18,14.606z M86.007,9.809
		c-2.982,0.18-6.053,0.259-9.017,0.456c-0.217,0.437-0.051,0.989,0.115,1.371c0.056,0.006,0.115,0.007,0.176,0.007
		c0.091,0,0.187-0.004,0.281-0.008c0.096-0.004,0.19-0.009,0.282-0.009c0.212,0,0.402,0.023,0.515,0.122
		c0.137,2.035,0.239,4.104,0.342,6.165c-0.28,0.328-0.958,0.257-1.368,0.459V19.4c0.524,0.073,1.096,0.104,1.683,0.104
		c1.35,0,2.779-0.161,3.908-0.332c0.085-0.498-0.188-0.644-0.114-1.141c-0.055-0.001-0.111-0.001-0.168-0.001
		c-0.092,0-0.186,0-0.28,0.002c-0.093,0.002-0.186,0.003-0.277,0.003c-0.331,0-0.643-0.019-0.872-0.121
		c-0.152-0.755-0.112-1.71-0.119-2.623c0.276-0.043,0.537-0.087,0.78-0.087c0.258,0,0.496,0.049,0.712,0.2
		c0.07,0.456,0.07,0.993,0.223,1.37c0.055-0.006,0.115-0.007,0.176-0.007c0.09,0,0.186,0.004,0.282,0.008s0.191,0.009,0.282,0.009
		c0.212,0,0.403-0.023,0.521-0.121c0.089-1.229-0.047-3.135-0.234-4.456c-0.08-0.014-0.153-0.02-0.22-0.02
		c-0.168,0-0.302,0.036-0.435,0.071c-0.134,0.035-0.267,0.07-0.434,0.07c-0.05,0-0.103-0.003-0.161-0.011
		c0.029,0.646-0.073,1.153-0.347,1.486h-1.254c0.017-0.71-0.325-1.654,0-2.281c0.512-0.11,1.047-0.18,1.554-0.18
		c1.422,0,2.606,0.545,2.328,2.233h1.368C86.172,12.726,86.429,10.664,86.007,9.809z M93.301,13.918
		c0.422,0.077,0.873,0.123,1.26,0.229c0.221-0.888,0.682-2.137,1.607-2.137c0.204,0,0.43,0.061,0.68,0.196
		c-0.468,1.975-0.536,4.341-1.141,6.166c-0.123,0.092-0.252,0.122-0.385,0.122c-0.14,0-0.285-0.034-0.438-0.068
		c-0.154-0.034-0.312-0.068-0.482-0.068c-0.062,0-0.125,0.004-0.188,0.015c-0.051,0.4-0.166,0.742-0.108,1.255
		c1.773,0.323,3.471,0.716,5.477,0.803c0.114-0.303,0.139-0.702,0.114-1.145c-0.353-0.254-1.117-0.1-1.367-0.459
		c0.272-2.195,0.399-4.541,1.14-6.277c1.22,0.029,1.355,1.149,1.255,2.51c0.323,0.138,0.681,0.239,1.14,0.239
		c0.072,0,0.148-0.002,0.227-0.008c0.148-1.221,0.422-2.316,0.457-3.654c-2.826-0.481-5.678-0.938-8.557-1.371
		C93.639,11.361,93.484,12.649,93.301,13.918z M73.57,11.406c-0.614-0.276-1.5-0.381-2.269-0.381c-0.294,0-0.571,0.015-0.809,0.042
		c-0.958,0.103-1.835,0.296-2.513,0.682c-3.586,2.035-2.685,8.795,1.943,9.251c0.266,0.026,0.529,0.039,0.789,0.039
		c1.473,0,2.824-0.423,3.883-1.297C77.241,17.566,76.128,12.561,73.57,11.406z M71.191,19.62c-0.316,0-0.665-0.069-1.046-0.22
		c-2-0.79-2.695-6.292,0.114-6.851c0.189-0.037,0.369-0.055,0.542-0.055c1.72,0,2.623,1.781,2.693,3.562
		C73.563,17.838,72.801,19.62,71.191,19.62z M120.34,12.663c0.633,1.578,2.013,2.568,3.658,2.741
		c0.137,0.015,0.27,0.021,0.402,0.021c0.985,0,1.861-0.38,2.734-0.759c0.873-0.381,1.742-0.761,2.713-0.761
		c0.102,0,0.203,0.004,0.307,0.013c-0.4-1.961-2.444-2.857-4.531-2.857c-1.527,0-3.076,0.48-4.023,1.374
		C121.298,12.629,120.625,12.447,120.34,12.663z M40.137,19.627c0.164,0.012,0.321,0.017,0.473,0.017
		c4.672,0,4.077-5.062,5.353-8.121c-0.066-0.001-0.131-0.002-0.195-0.002c-2.942,0-4.932,1.728-5.281,4.567
		c-0.086,0.693,0.108,1.432,0,2.168C40.411,18.77,40,19.124,40.137,19.627z M103.154,19.984c-0.398,0-0.592-0.196-0.949-0.242
		c-0.166,0.365-0.387,0.677-0.342,1.258c1.602,0.49,3.174,1.01,4.791,1.483c0.189-0.349,0.338-0.73,0.344-1.258
		c-0.423-0.108-0.872-0.194-1.031-0.57c0.251-0.779,0.364-1.691,0.805-2.282c1.143,0.224,2.271,0.463,3.082,1.027
		c-0.205,0.904-0.331,1.882-0.918,2.398c-0.025,0.002-0.049,0.003-0.072,0.003c-0.184,0-0.311-0.059-0.437-0.117
		c-0.127-0.059-0.253-0.117-0.437-0.117c-0.024,0-0.05,0.001-0.076,0.003c-0.169,0.366-0.432,0.634-0.346,1.256
		c1.68,0.41,3.118,1.065,4.793,1.482c0.165-0.364,0.387-0.673,0.34-1.255c-0.396-0.174-0.934-0.209-1.137-0.57
		c0.648-2.011,1.082-4.238,2.051-5.938c0.033-0.004,0.066-0.006,0.098-0.006c0.176,0,0.35,0.045,0.51,0.091
		c0.162,0.045,0.312,0.09,0.44,0.09c0.076,0,0.146-0.016,0.206-0.058c0.148-0.312,0.279-0.637,0.227-1.145
		c-1.594-0.527-3.088-1.167-4.906-1.485c0.006,0.499-0.346,0.636-0.223,1.258c0.435,0.06,0.855,0.131,1.026,0.456
		c-0.125,0.793-0.279,1.552-0.685,2.056c-1.104-0.265-2.256-0.481-3.082-1.028c0.165-0.784,0.296-1.604,0.797-2.055
		c0.051-0.006,0.098-0.01,0.143-0.01c0.373,0,0.537,0.213,0.883,0.235c0.24-0.29,0.309-0.756,0.457-1.141
		c-1.522-0.682-3.215-1.194-5.021-1.598c0,0.454-0.229,0.686-0.229,1.145c0.359,0.288,1.095,0.194,1.141,0.796
		c-0.707,1.922-1.004,4.246-2.053,5.823C103.276,19.98,103.213,19.984,103.154,19.984z M126.734,15.86
		c0.439,1.776,1.857,2.879,3.535,3.084c0.168,0.021,0.331,0.029,0.492,0.029c0.879,0,1.693-0.275,2.516-0.55
		c0.822-0.275,1.65-0.551,2.557-0.551c0.193,0,0.393,0.014,0.596,0.041c-0.629-2.07-2.462-3.314-4.736-3.314
		c-0.094,0-0.189,0.002-0.285,0.007c-1.627,0.072-2.848,1.294-4.244,1.294C127.022,15.9,126.879,15.887,126.734,15.86z
		 M38.654,21.456c0.571-1.24,0.138-2.76,0.343-4.337c0.091-0.647,0.559-1.21,0.455-1.828c-4.685,0.313-4.913,4.597-4.9,8.675
		C36.688,23.755,37.971,22.971,38.654,21.456z M55.999,17.458c-0.152-0.305-0.348-0.567-0.456-0.912
		c-1.02,0.523-4.805,1.648-4.907,2.398c-0.045,0.322,0.33,0.636,0.456,0.912h0.012c0.257,0,0.429-0.086,0.599-0.171
		c0.17-0.086,0.34-0.172,0.596-0.172c0.016,0,0.032,0,0.049,0.001c1.093,1.683,1.794,3.762,2.741,5.596
		c-0.177,0.581-1.021,0.507-1.14,1.141c0.187,0.23,0.24,0.596,0.456,0.799c2.9-1.13,5.704-2.361,8.214-3.882
		c-0.617-1.095-1.017-2.407-1.598-3.54h-0.026c-0.558,0-0.868,0.258-1.228,0.457c1.209,2.199-0.307,3.805-2.284,4.113
		c-1.021-1.793-1.972-3.66-2.621-5.825C55.126,17.954,55.789,17.934,55.999,17.458z M113.844,23.624
		c-0.195,0.377-0.457,0.685-0.572,1.144c2.762,1.421,5.336,3.034,8.214,4.338c0.862-0.967,1.38-2.272,2.059-3.425
		c-0.446-0.166-0.72-0.508-1.26-0.57c-0.325,1.109-1.125,1.535-2.019,1.535c-1.033,0-2.192-0.568-2.889-1.308
		c0.382-0.802,0.76-1.603,1.254-2.284c0.495,0.23,1.094,0.357,1.26,0.912c-0.005,0.57-0.342,0.805-0.462,1.259
		c0.457,0.159,0.654,0.563,1.261,0.57c0.592-1.35,1.527-2.354,1.943-3.882c-0.217-0.322-0.628-0.443-1.033-0.57
		c-0.393,0.291-0.449,0.918-1.025,1.025c-0.377-0.233-1.004-0.213-1.146-0.687c0.251-0.778,0.622-1.435,1.146-1.939
		c1.493,0.725,3.961,1.684,2.513,3.996c0.389,0.22,0.649,0.567,1.255,0.57c0.518-1.075,1.18-2.014,1.596-3.197
		c-2.787-1.472-5.438-3.086-8.334-4.448c-0.09,0.398-0.455,0.527-0.455,1.026c0.148,0.465,0.85,0.367,0.912,0.912
		c-0.9,1.948-1.766,3.937-3.078,5.479C114.549,23.986,114.26,23.744,113.844,23.624z M132.834,19.763
		c-0.129,0-0.262-0.007-0.394-0.021c0.552,2.031,2.104,3.378,4.224,3.426c0.025,0,0.051,0.001,0.076,0.001
		c0.69,0,1.391-0.212,2.1-0.423c0.709-0.212,1.428-0.424,2.154-0.424c0.34,0,0.683,0.046,1.027,0.161
		c-0.587-2.313-2.178-3.477-4.332-3.656c-0.117-0.01-0.231-0.014-0.344-0.014c-0.832,0-1.573,0.237-2.301,0.476
		C134.319,19.526,133.606,19.763,132.834,19.763z M29.416,26.821c0.051,0.499-0.142,1.469,0,1.828
		c2.332-0.331,3.939-1.891,3.996-4.451c0.033-1.632-0.429-3.475,0.113-4.57c-2.93,0.38-4.77,3.084-4.445,5.938
		C29.121,25.977,29.377,26.399,29.416,26.821z M43.563,32.417c0.216,0.276,0.245,0.75,0.69,0.798
		c1.167-0.727,2.512-1.286,3.417-2.281c-0.306-0.265-0.397-0.745-0.796-0.915c-0.216,0.241-0.525,0.391-0.912,0.456
		c-0.143-0.431-0.387-0.759-0.343-1.369c0.867-0.613,1.688-1.277,2.737-1.714c0.331,0.319,0.945,0.342,1.026,0.912
		c-0.147,0.426-0.775,0.368-0.797,0.916c0.216,0.277,0.238,0.747,0.683,0.799c1.54-0.745,2.794-1.774,4.225-2.627
		c-0.144-0.428-0.27-0.878-0.691-1.03c-0.321,0.036-0.459,0.255-0.821,0.255c-0.061,0-0.128-0.006-0.204-0.021
		c-2.599-1.32-4.525-3.309-7.183-4.572c-0.483,0.477-1.161,0.748-1.715,1.145c0.417,2.854,1.17,5.379,1.375,8.447
		C44.059,31.92,43.59,31.951,43.563,32.417z M46.874,26.361c-0.286,0.634-1.002,0.825-1.596,1.146
		c-0.206-0.783-0.433-1.548-0.456-2.515C45.54,25.415,46.225,25.872,46.874,26.361z M81.549,26.595
		c0.657,0.374,1.238,0.529,1.759,0.529c2.172,0,3.295-2.711,4.524-3.615c-0.67-0.41-1.296-0.583-1.876-0.583
		C83.909,22.926,82.425,25.083,81.549,26.595z M76.307,27.166c0.555,0.235,1.047,0.339,1.491,0.339c2.257,0,3.212-2.671,4.441-3.995
		c-0.576-0.294-1.117-0.42-1.621-0.42C78.379,23.089,76.874,25.596,76.307,27.166z M86.462,26.595
		c0.657,0.477,1.274,0.672,1.856,0.672c1.954,0,3.487-2.206,4.648-3.302c-0.693-0.486-1.35-0.686-1.969-0.686
		C89.025,23.28,87.453,25.31,86.462,26.595z M138.763,24.153c-0.241,0-0.484-0.021-0.73-0.072c0.296,1.865,1.372,3.031,2.849,3.539
		c0.382,0.132,0.783,0.182,1.197,0.182c0.637,0,1.303-0.117,1.98-0.236c0.676-0.118,1.363-0.236,2.039-0.236
		c0.436,0,0.867,0.049,1.287,0.179c-0.473-2.366-2.274-3.817-4.566-3.998c-0.086-0.007-0.172-0.011-0.258-0.011
		c-0.615,0-1.232,0.164-1.863,0.327S139.426,24.153,138.763,24.153z M71.291,28.078c0.507,0.203,0.96,0.293,1.37,0.293
		c2.284,0,3.204-2.784,4.33-4.29c-0.539-0.216-1.031-0.312-1.481-0.312C73.227,23.769,72.023,26.237,71.291,28.078z M91.705,26.935
		c0.658,0.558,1.314,0.779,1.951,0.779c1.846,0,3.537-1.856,4.673-2.836c-0.669-0.633-1.386-0.885-2.099-0.885
		C94.346,23.994,92.502,25.764,91.705,26.935z M24.395,33.67c2.468-0.487,3.832-2.01,3.88-4.337
		c0.036-1.641-0.859-3.497-0.111-4.911c-3.734,0.289-5.012,4.751-3.541,8.222C24.612,33.052,24.446,33.303,24.395,33.67z
		 M66.264,29.904c0.396,0.112,0.755,0.163,1.084,0.163c2.508,0,3.193-2.992,4.17-4.843c-0.358-0.069-0.689-0.102-0.996-0.102
		C67.783,25.123,66.991,27.707,66.264,29.904z M101.234,25.065c-0.811,0-1.636,0.221-2.221,0.615
		c-0.863,0.576-1.163,1.62-2.059,1.711c0.405,0.897,1.438,1.158,2.735,1.158c0.188,0,0.38-0.006,0.577-0.015
		c1.299-0.596,1.983-1.819,3.533-2.174C103.377,25.459,102.318,25.065,101.234,25.065z M61.477,31.958
		c0.192,0.02,0.373,0.029,0.545,0.029c3.134,0,3.15-3.126,4.132-5.28c-0.271-0.05-0.524-0.072-0.764-0.072
		C62.615,26.635,61.833,29.744,61.477,31.958z M104.072,29.97c1.609,0,3.456-1.368,4.75-1.78c-0.555-1.038-1.641-1.582-2.857-1.582
		c-0.297,0-0.604,0.032-0.91,0.099c-1.254,0.273-2.092,1.691-3.191,1.828C102.461,29.6,103.234,29.97,104.072,29.97z
		 M129.129,30.363c-0.7,0.894-1.596,1.591-2.166,2.624c0.277,0.394,0.545,0.717,0.959,0.717c0.152,0,0.324-0.043,0.522-0.145
		c0.514,1.621,1.896,3.771,4.191,3.771c0.048,0,0.098-0.002,0.146-0.003c1.512-0.061,3.083-1.523,2.735-3.429
		c-0.246-1.334-4.309-4.905-1.824-5.139c0.047-0.004,0.094-0.007,0.139-0.007c1.75,0,2.82,3.061,1.686,4.119
		c0.404,0.245,0.633,0.659,1.146,0.798c0.633-0.924,1.561-1.554,2.051-2.626c-0.214-0.337-0.492-0.67-0.929-0.67
		c-0.131,0-0.276,0.03-0.438,0.1c-0.225-1.915-2.45-3.266-4.337-3.266c-1.251,0-2.354,0.595-2.628,2.011
		c-0.438,2.302,4.24,5.758,1.943,6.28c-0.104,0.023-0.207,0.034-0.309,0.034c-1.69,0-3.014-3.072-1.863-4.259
		C129.909,30.874,129.528,30.611,129.129,30.363z M37.174,29.792h0.008c0.757,0,0.975,0.774,1.815,1.026
		c0.354-0.222,0.599-0.547,0.918-0.801c-0.792-0.956-1.454-2.045-2.626-2.627c-2.224,2.189-4.676,4.152-6.727,6.508
		c0.382,0.186,0.423,0.722,0.913,0.802c0.32-0.244,0.484-0.656,1.025-0.685c0.673,0.312,1.3,1.267,2.166,2.167
		c1.139,1.185,2.918,2.302,1.369,3.542c0.365,0.163,0.41,0.65,0.911,0.682c2.2-2.136,4.606-4.069,6.618-6.391
		c-0.705-0.666-1.829-2.818-2.729-2.818c-0.303,0-0.582,0.245-0.81,0.874c2.233,1.736,0.16,3.961-1.37,4.683
		c-0.569-0.645-1.265-1.167-1.709-1.942c0.37-0.281,0.513-0.799,1.16-0.799c0.03,0,0.062,0.001,0.094,0.003
		c0.513,0.097,0.593,0.622,1.141,0.685c0.307-0.271,0.696-0.453,0.797-0.915c-1.002-1.044-1.84-2.263-3.078-3.081
		c-0.297,0.269-0.685,0.45-0.796,0.91c0.243,0.365,0.614,0.604,0.683,1.144c-0.29,0.395-0.615,0.753-1.026,1.027
		c-0.61-0.527-1.22-1.062-1.597-1.828C35.338,31.555,35.987,29.795,37.174,29.792z M109.379,31.534c1.459,0,3.219-0.808,4.577-1.171
		c-0.466-0.921-1.15-1.646-2.165-1.828c-0.23-0.042-0.453-0.061-0.671-0.061c-1.63,0-2.905,1.055-4.122,1.43
		C107.479,31.135,108.355,31.534,109.379,31.534z M145.759,28.766c-0.511,0.074-1.013,0.149-1.485,0.149
		c-0.352,0-0.688-0.042-0.999-0.155c0.49,2.299,1.528,3.742,3.534,4.112c0.299,0.055,0.616,0.075,0.945,0.075
		c0.443,0,0.906-0.037,1.368-0.073c0.462-0.037,0.921-0.073,1.358-0.073c0.686,0,1.318,0.091,1.812,0.413
		c-0.436-2.691-1.956-4.26-4.453-4.565c-0.179-0.022-0.357-0.032-0.539-0.032C146.791,28.617,146.27,28.692,145.759,28.766z
		 M56.803,34.356c0.079,0.004,0.156,0.005,0.232,0.005c3.188,0,3.551-2.837,3.985-5.601c-0.176-0.021-0.344-0.03-0.504-0.03
		C57.422,28.73,57.453,32.33,56.803,34.356z M20.065,39.381c1.936-0.527,3.322-1.849,3.532-3.882
		c0.195-1.854-1.105-3.862-0.569-5.596l-0.115-0.111c-2.125,0.71-3.59,2.453-3.648,4.908C19.226,36.358,20.44,37.75,20.065,39.381z
		 M114.168,33.912c1.408,0,3.08-0.705,4.35-0.925c-0.163-1.404-1.606-2.028-3.08-2.028c-1.111,0-2.239,0.356-2.848,0.999
		c-0.221,0.057-0.426,0.129-0.696,0.129c-0.068,0-0.14-0.005-0.218-0.015C112.138,33.485,113.073,33.912,114.168,33.912z
		 M52.346,36.982c0.054,0.002,0.105,0.003,0.157,0.003c3.184,0,3.101-3.267,3.723-5.823c-0.041-0.001-0.079-0.001-0.119-0.001
		C52.838,31.161,52.458,34.122,52.346,36.982z M137.234,34.701c0.284,0.319,0.535,0.679,0.91,0.913
		c0.297-0.43,1.132-0.884,1.825-0.884c0.5,0,0.926,0.235,1.026,0.884c-1.728,1.2-2.907,2.952-4.787,3.995
		c-0.576-0.071-0.57-0.728-1.146-0.799c-0.274,0.257-0.646,0.425-0.8,0.799c1.248,1.335,2.314,2.858,3.769,3.995
		c0.317-0.168,0.513-0.47,0.798-0.684c-0.137-0.548-0.68-0.69-0.798-1.254c1.459-1.578,3.044-3.042,4.786-4.339
		c1.188,0.925,0.035,2.11-0.455,2.969c0.285,0.286,0.371,0.77,0.911,0.799c0.856-0.822,1.893-1.458,2.509-2.513
		c-1.893-2.103-3.802-4.184-5.699-6.28C138.877,32.843,138.076,33.799,137.234,34.701z M119.174,36.52
		c1.33,0,2.848-0.398,3.913-0.564c-0.37-1.144-1.253-2.062-2.513-2.285c-0.189-0.032-0.375-0.047-0.559-0.047
		c-1.301,0-2.466,0.729-3.664,0.849C116.59,36.081,117.778,36.52,119.174,36.52z M48.329,39.957c3.078,0,3.013-3.694,3.218-6.171
		c-3.107,0.14-3.396,3.262-3.418,6.166C48.197,39.955,48.263,39.957,48.329,39.957z M151.435,34.121
		c-0.397,0-0.792,0.051-1.177,0.103c-0.383,0.052-0.756,0.104-1.111,0.104c-0.427,0-0.828-0.074-1.191-0.311
		c0.016,1.891,1.225,3.375,2.285,3.996c1.586,0.932,4.686,0.245,6.383,1.026c-0.307-3.046-2.131-4.717-4.9-4.908
		C151.626,34.124,151.529,34.121,151.435,34.121z M19.379,41.551c0.126-1.994-1.385-3.953-1.026-5.826l-0.114-0.111
		c-1.966,0.896-3.261,2.621-3.197,5.024c0.04,1.825,1.407,2.991,1.253,4.795C18.08,44.822,19.254,43.467,19.379,41.551z
		 M28.043,37.441c-3.085,0.392-4.743,6.469-0.913,6.964c0.104,0.014,0.207,0.021,0.308,0.021c1.05,0,1.925-0.694,2.71-1.389
		c0.784-0.694,1.477-1.389,2.164-1.389c0.062,0,0.125,0.006,0.187,0.019c0.263,0.05,0.593,0.307,0.684,0.566
		c0.532,1.635-1.448,2.724-3,2.724c-0.477,0-0.914-0.104-1.224-0.325c-1.938,1.166,1.008,2.361,1.944,3.086
		c0.649-0.3,0.638-0.969,0.569-1.486c3.203-0.285,5.078-6.092,1.482-7.078c-0.209-0.058-0.413-0.084-0.613-0.084
		c-1.046,0-1.977,0.716-2.812,1.432c-0.837,0.715-1.58,1.43-2.25,1.43c-0.09,0-0.178-0.012-0.265-0.04
		c-0.267-0.082-0.524-0.407-0.57-0.795c-0.131-1.313,1.452-2.329,2.791-2.329c0.458,0,0.888,0.119,1.211,0.387
		c0.319-0.283,0.62-0.591,0.793-1.029c-0.782-0.813-1.654-1.541-2.623-2.168C28.282,36.42,27.768,36.637,28.043,37.441z
		 M123.73,36.833c-1.036,0-2.152,0.24-3.156,0.608c0.281,1.742,1.562,2.18,3.139,2.18c1.131,0,2.415-0.226,3.592-0.354
		C126.902,37.477,125.426,36.833,123.73,36.833z M44.475,43.491c3.152-0.009,2.615-3.637,2.855-6.281
		c-0.132-0.004-0.155-0.117-0.291-0.117c-0.015,0-0.032,0.001-0.051,0.005C44.333,37.607,43.996,40.77,44.475,43.491z
		 M155.08,39.952c-0.217,0-0.422,0.008-0.619,0.016c-0.195,0.008-0.383,0.016-0.562,0.016c-0.504,0-0.952-0.063-1.379-0.374
		c-0.113,0-0.137,0.092-0.227,0.116c-0.23,4.869,4.73,4.549,8.212,5.708c-0.092-3.417-2.264-5.45-5.364-5.481H155.08z
		 M126.802,40.192c-0.712,0-1.47,0.079-2.233,0.215c0.119,2.312,2.254,2.706,4.378,2.706c0.737,0,1.472-0.047,2.12-0.079
		C130.766,40.936,128.99,40.192,126.802,40.192z M42.993,40.752c-1.773,0.377-2.839,2.204-2.623,4.109
		c0.086,0.782,0.691,1.506,0.457,2.399c2.502-0.257,2.435-2.402,2.285-4.798C43.066,41.814,43.278,41.206,42.993,40.752z
		 M12.871,51.485c1.762-0.628,2.576-1.968,2.741-3.54c0.246-2.341-1.636-3.884-1.481-6.278h-0.348
		c-2.142,1.031-3.168,4.319-2.166,6.735C12.016,49.354,13.122,50.093,12.871,51.485z M148.523,42.803
		c-2.809,0.388-5.225,1.161-8.098,1.486c-0.462-0.069-0.508-0.56-0.912-0.686c-0.4,0.138-0.82,0.252-0.912,0.686
		c0.707,0.67,1.514,3.242,2.523,3.242c0.213,0,0.436-0.115,0.668-0.386c0.125-0.657-0.445-0.621-0.343-1.257
		c0.468-0.1,0.833-0.306,1.368-0.343c0.549,0.933,1.277,1.691,1.715,2.741c-0.256,0.356-0.346,0.869-0.801,1.026
		c-0.596-0.011-0.445-0.771-1.027-0.799c-0.205,0.331-0.775,0.291-0.797,0.799c0.888,1.396,1.811,2.755,2.736,4.11
		c0.473-0.061,0.67-0.396,1.029-0.57c-0.062-0.435-0.359-0.634-0.346-1.145c1.332-2.55,3.155-4.603,4.455-7.192
		C149.373,43.933,149.122,43.203,148.523,42.803z M145.446,46.801c-0.535-0.339-0.733-1.015-1.144-1.483
		c0.58-0.45,1.686-0.37,2.397-0.687C146.752,45.368,145.966,46.254,145.446,46.801z M134.606,47.375
		c-0.131-2.048-1.585-3.325-3.54-3.428c-0.043-0.003-0.088-0.004-0.132-0.004c-0.281,0-0.563,0.042-0.845,0.084
		c-0.28,0.042-0.559,0.084-0.836,0.084c-0.273,0-0.545-0.042-0.81-0.164C128.344,47.244,131.887,46.895,134.606,47.375z
		 M39.567,44.746c-0.946,0.293-1.733,0.793-2.167,1.6c-0.819,1.546,0.076,3.396,0.229,5.14
		C40.725,51.074,39.853,47.244,39.567,44.746z M156.054,45.658c-0.575,5.259,4.475,4.889,7.534,6.508
		c0.227-3.097-1.219-5.176-3.771-5.82C158.426,45.992,156.727,46.203,156.054,45.658z M137.918,51.596
		c0.034-2.786-2.534-3.472-4.986-3.472c-0.401,0-0.799,0.019-1.182,0.049C131.75,51.374,135.204,51.117,137.918,51.596z
		 M12.643,54.108c0.012-2.321-2.246-3.716-2.166-5.935h-0.229c-1.311,0.787-2.029,2.242-2.166,3.423
		c-0.386,3.244,1.705,4.078,2.166,6.396C11.777,57.376,12.643,55.677,12.643,54.108z M21.078,50.849
		c0.521,0,1.084,0.166,1.493,0.404c0.194-0.413,0.507-0.707,0.57-1.252c-1.078-0.524-2.087-1.112-3.307-1.486
		c-1.266,2.618-2.587,5.18-3.766,7.878c1.087,0.442,2.25,1.392,3.311,1.485c0.165-0.365,0.422-0.645,0.456-1.144
		c-1.015-0.294-2.21-1.597-1.14-2.627c1.795,1.134,4.212,1.644,5.699,3.086c-0.102,0.465-0.427,0.71-0.454,1.259
		c0.358,0.13,0.602,0.382,1.025,0.456c0.9-1.58,1.754-3.194,2.398-5.028c-0.318-0.177-0.631-0.354-1.031-0.456
		c-0.365,0.316-0.308,1.058-0.911,1.141c-2.064-0.75-3.888-1.737-5.7-2.738C19.836,51.114,20.422,50.849,21.078,50.849z
		 M149.094,50.116c0.086,0.557,0.303,0.995,0.689,1.255c0.33-0.274,0.842-0.432,1.336-0.432c0.678,0,1.32,0.299,1.4,1.002
		c-1.791,1.063-3.768,1.939-5.711,2.854c-0.508-0.213-0.445-0.995-1.025-1.144c-0.399,0.017-0.508,0.328-0.912,0.343
		c0.638,1.842,1.584,3.365,2.394,5.027c0.457-0.086,0.896-0.177,1.038-0.569c0.033-0.577-0.439-0.634-0.348-1.259
		c1.681-1.212,3.773-2.01,5.705-2.969c0.9,1.154-0.285,2.305-1.254,2.624c0.171,0.477,0.319,0.973,0.684,1.257
		c0.986-0.648,2.336-0.933,3.077-1.827c-1.195-2.604-2.541-5.065-3.761-7.648C151.175,49,150.184,49.602,149.094,50.116z
		 M35.123,55.823c2.643-0.965,1.834-4.572,0.912-6.852C33.274,49.828,33.867,53.8,35.123,55.823z M159.473,51.827
		c-1.275,5.111,3.99,5.59,6.852,7.305c0.426-2.516-0.318-4.017-1.596-5.137c-1.489-1.301-3.785-1.116-5.137-2.054L159.473,51.827z
		 M140.768,56.506c0.262-3.462-3.025-3.972-5.704-4.108C134.869,55.868,138.385,55.614,140.768,56.506z M31.241,56.165
		c-0.167,1.577,0.882,2.727,1.03,4.109c3.033-0.699,1.814-4.714,0.913-6.85C32.181,54.071,31.383,54.853,31.241,56.165z
		 M88.971,54.108c-0.273,0.331-0.786,0.428-1.027,0.802h0.019C88.509,54.91,89.252,54.545,88.971,54.108z M10.021,60.388
		c-0.251-2.084-2.474-3.405-2.509-5.479l-0.114-0.114c-1.344,0.787-2.017,2.465-2.051,3.882c-0.074,2.602,2.108,4.271,2.621,6.052
		C9.383,63.917,10.249,62.28,10.021,60.388z M84.752,55.708c-0.416,0.186-0.859,0.254-1.315,0.254c-0.662,0-1.349-0.146-2.015-0.291
		c-0.665-0.146-1.309-0.291-1.882-0.291c-0.732,0-1.349,0.236-1.752,1.013c0.834,0.219,1.317,1.156,2.217,1.156
		c0.06,0,0.119-0.004,0.182-0.013c0.524-0.074,0.554-0.511,1.142-0.688c0.086,0.001,0.173,0.002,0.258,0.002
		C83.021,56.851,84.257,56.651,84.752,55.708z M79.497,56.849c-0.318-0.011-0.535-0.143-0.455-0.57h0.455V56.849z M137.918,56.624
		c-0.701,3.448,3.215,3.848,5.244,4.563C143.47,57.613,140.552,57.257,137.918,56.624z M155.825,66.783
		c0.035,0.496,0.302,0.767,0.342,1.258c1.341-0.183,2.509-0.536,3.425-1.142c-0.649-2.777-1.805-6.034-2.742-8.906
		c-0.46,0.031-0.773,0.217-1.139,0.342c-0.033,0.531,0.228,0.759,0.228,1.258c-1.868,0.827-3.902,1.495-6.155,1.937
		c-0.182-0.356-0.342-0.722-0.455-1.141c-0.456,0.003-0.688,0.229-1.146,0.229c0.701,3.217,1.809,6.034,2.742,9.018
		c1.402-0.151,2.383-0.732,3.763-0.912c0.228-0.459-0.206-0.819-0.229-1.366c-0.463,0.152-0.871,0.223-1.232,0.223
		c-1.785,0-2.391-1.715-2.643-3.537c0.842-0.291,1.607-0.667,2.622-0.799c0.114,0.496,0.296,0.922,0.229,1.6
		c-0.365,0.172-0.732,0.34-1.141,0.457c-0.02,0.627,0.078,1.13,0.455,1.369c1.322-0.499,2.826-0.824,4.102-1.369
		c-0.079-0.484-0.237-0.896-0.454-1.258c-0.377,0.052-0.628,0.232-1.063,0.232c-0.025,0-0.051,0-0.076-0.001
		c-0.541-0.223-0.581-0.944-0.799-1.486c0.787-0.202,1.46-0.521,2.281-0.687C157.547,63.694,158.334,66.56,155.825,66.783z
		 M30.448,58.22c-1.078,0.87-1.836,2.057-1.603,3.769c0.167,1.188,1.317,2.219,1.483,3.197c2.917-1.529,0.929-4.649,0.456-6.966
		H30.448z M162.215,58.453c-0.199,0.48-0.314,0.771-0.337,1.248c-0.272,4.341,4.265,4.555,6.612,6.511
		c0.855-2.17-0.308-4.628-1.709-5.709c-1.289-0.992-3.176-0.896-4.453-1.939L162.215,58.453z M13.44,63.131
		c1.983,2.009,4.372,3.619,6.167,5.821c0.092,0.472-0.182,0.588-0.114,1.026c0.321,0.143,0.583,0.346,1.05,0.346
		c0.03,0,0.06-0.001,0.091-0.003c0.456-1.288,0.991-2.51,1.366-3.879c-0.318-0.143-0.581-0.334-1.027-0.345
		c-0.46,0.111-0.271,0.869-0.796,0.912c-0.319-0.364-0.923-0.445-1.027-1.023c0.452-0.881,0.616-2.045,1.14-2.855
		c0.131-0.091,0.408-0.137,0.684-0.137s0.553,0.046,0.684,0.137c0.092,0.545-0.182,0.734-0.114,1.252
		c0.365,0.129,0.673,0.314,1.14,0.346c0.466-1.66,1.248-3.018,1.596-4.794c-0.193-0.306-0.695-0.29-1.026-0.46
		c-0.261,0.27-0.301,0.765-0.684,0.913c-2.553,0.49-5.723,0.365-8.441,0.685C13.965,61.823,13.577,62.348,13.44,63.131z
		 M17.764,63.239c0.139,0,0.278,0.002,0.417,0.002c0.138,0.002,0.276,0.002,0.413,0.002h0.102c-0.147,0.66-0.479,1.124-0.575,1.829
		c-0.866-0.389-1.556-0.95-2.051-1.715C16.57,63.255,17.159,63.239,17.764,63.239z M87.488,60.731
		c-0.289-0.045-0.854-0.153-1.352-0.153c-0.445,0-0.837,0.086-0.928,0.381c0.21,0.145,0.755,0.272,1.259,0.272
		C86.975,61.231,87.439,61.102,87.488,60.731z M145.213,66.211c0.565-3.388-2.395-4.215-5.016-4.793
		C139.457,64.975,143.047,65.188,145.213,66.211z M8.198,67.47c-0.32-2.262-2.958-3.565-3.307-5.597
		c-0.563,0.341-1.253,1.369-1.373,1.715c-1.495,4.235,2.045,5.804,3.198,8.105C7.764,70.704,8.431,69.184,8.198,67.47z
		 M87.361,62.56c0.143-0.006,0.283-0.012,0.42-0.012c0.096,0,0.188,0.003,0.277,0.011c0.031-0.331-0.496-0.512-0.991-0.512
		c-0.414,0-0.807,0.127-0.833,0.396c0.178,0.103,0.432,0.128,0.709,0.128C87.079,62.572,87.221,62.566,87.361,62.56z M83.818,62.834
		c-0.527-0.203-1.024-0.407-1.548-0.407c-0.085,0-0.171,0.005-0.259,0.017c0.548,0.822,1.932,0.809,2.627,1.483
		c0.394-0.131,0.809-0.247,0.912-0.685h-0.013C84.9,63.243,84.345,63.039,83.818,62.834z M28.275,63.243
		c-1.15,0.851-1.754,2.417-1.258,4.113c0.331,1.092,1.675,1.809,1.716,2.855c3.061-1.644,0.381-5.074-0.343-6.854L28.275,63.243z
		 M164.152,65.072c-2.137,4.843,3.25,6.282,5.706,8.219c0.365-0.732,0.466-1.883,0.341-2.74c-0.514-3.457-3.691-3.756-5.927-5.364
		L164.152,65.072z M142.136,66.211c-1.083,3.489,2.519,4.152,4.564,5.251C147.527,68.008,144.506,67.122,142.136,66.211z
		 M26.561,68.497c-0.49,0.539-1.002,1.047-1.14,1.715c-0.52,2.523,1.499,3.809,2.286,5.249
		C30.425,73.173,27.45,70.262,26.561,68.497z M6.942,75.233c-0.114-2.835-3.055-3.802-3.652-6.052c-0.115,0-0.132-0.095-0.229-0.117
		c-0.953,0.722-1.402,2.342-1.373,3.429c0.089,2.92,2.93,4.625,4.001,6.165C6.555,77.86,6.988,76.457,6.942,75.233z M10.363,79.342
		c0.059-0.007,0.113-0.01,0.164-0.01c0.17,0,0.305,0.035,0.439,0.07c0.134,0.036,0.268,0.072,0.437,0.072
		c0.065,0,0.135-0.006,0.213-0.019c0.014-0.521,0.075-0.992,0.342-1.255c1.847,0.101,4.56,0.308,6.282,0.912v1.256
		c0.226,0.099,0.528,0.12,0.852,0.12c0.131,0,0.267-0.003,0.401-0.007c0.304-3.126,1.043-6.405,1.141-9.359
		c-1.37-0.12-2.497-0.474-3.882-0.573c-0.074,0.422-0.119,0.869-0.223,1.258c2.474,0.042,3.101,2.563,1.939,4.68
		c-0.788-0.239-2.041-0.018-2.509-0.568c0.125-0.401,0.011-1.048,0.336-1.257c0.139-0.101,0.354-0.125,0.593-0.125
		c0.109,0,0.223,0.005,0.336,0.01c0.113,0.005,0.227,0.01,0.335,0.01c0.078,0,0.153-0.002,0.224-0.009
		c-0.052-0.542,0.199-0.784,0.114-1.372c-1.481-0.15-2.883-0.388-4.337-0.57c-0.2,0.337-0.273,0.799-0.233,1.371
		c0.535,0.114,1.174,0.121,1.374,0.571c-0.006,0.527,0.062,1.13-0.229,1.371c-0.781-0.136-1.733-0.094-2.287-0.459
		c0.236-1.749,0.123-3.852,2.102-3.851c0.224,0,0.475,0.026,0.755,0.084c0.12-0.354,0.246-1.127,0.114-1.372
		c-1.147-0.183-2.313-0.348-3.54-0.454C11.184,73.008,10.671,76.072,10.363,79.342z M143.503,71.351
		c-0.923,3.794,2.61,4.072,4.337,5.593C148.906,73.437,145.897,72.147,143.503,71.351z M165.526,72.036
		c-2.61,4.578,3.112,6.512,5.016,8.679c0.627-0.933,0.798-2.379,0.57-3.425C170.508,74.446,167.402,73.544,165.526,72.036z
		 M27.13,80.483c2.087-2.763-0.911-5.054-2.051-6.736C22.862,76.083,25.285,79.308,27.13,80.483z M159.928,74.663
		c-0.596-0.285-1.521-0.399-2.312-0.399c-0.326,0-0.63,0.019-0.877,0.055c-1.186,0.168-1.66,0.264-2.281,0.687
		c-1.652,1.124-2.746,4.963-1.139,7.536c0.791,1.271,2.643,2.065,4.45,2.065c1.083,0,2.151-0.285,2.965-0.926
		C163.543,81.476,162.732,75.986,159.928,74.663z M160.961,80.37c-0.4,1.057-1.937,1.643-3.478,1.643
		c-1.905,0-3.815-0.895-3.597-2.898c0.115-1.031,0.861-1.773,2.509-2.056c0.395-0.067,0.806-0.103,1.215-0.103
		C159.783,76.956,161.877,77.948,160.961,80.37z M83.265,80.03c0.179,0.049,0.368,0.072,0.561,0.072c0.683,0,1.403-0.294,1.839-0.76
		c0.25-1.229,0.039-2.812-0.115-3.993c-0.017-0.001-0.032-0.001-0.048-0.001c-0.979,0-0.883,0.981-0.755,1.963
		c0.128,0.98,0.29,1.962-0.559,1.962c-0.102,0-0.219-0.015-0.353-0.045c-1.197-0.271-0.222-2.663-0.912-3.653
		c-0.05-0.102-0.113-0.135-0.185-0.135c-0.075,0-0.159,0.037-0.248,0.074c-0.088,0.037-0.182,0.074-0.274,0.074
		c-0.031,0-0.062-0.004-0.092-0.014C82.343,77.252,81.777,79.61,83.265,80.03z M89.098,80.162c0.979,0,1.924-0.555,1.696-1.734
		c-0.216-1.115-1.699-0.762-2.28-1.712c0.19-0.363,0.379-0.493,0.57-0.493c0.246,0,0.498,0.21,0.764,0.421
		c0.267,0.21,0.55,0.421,0.86,0.421c0.028,0,0.057-0.002,0.086-0.006c0.27-1.097-0.653-1.624-1.599-1.624
		c-1.133,0.001-2.296,0.756-1.479,2.194c0.604,0.499,1.824,0.385,2.051,1.259c-0.223,0.336-0.435,0.462-0.645,0.462
		c-0.598,0-1.172-1.008-1.863-1.033C86.953,79.516,88.044,80.162,89.098,80.162z M80.758,79.571
		c0.943-1.513-0.226-3.703-2.134-3.703c-0.502,0-1.055,0.151-1.634,0.506c0.267,1.409,0.393,2.957,0.798,4.227
		C79.064,80.361,80.302,80.301,80.758,79.571z M78.804,76.773c0.747,0,1.203,0.71,1.252,1.42c0.049,0.711-0.308,1.421-1.187,1.421
		c-0.122,0-0.255-0.014-0.398-0.043c-0.004-0.981-0.376-1.6-0.339-2.627C78.375,76.825,78.6,76.773,78.804,76.773z M93.422,77.058
		c-0.228,1.069-0.325,2.268-0.576,3.312c0.275,0.113,0.564,0.202,0.912,0.23c0.416-0.998,0.223-2.598,0.803-3.428
		c0.082-0.015,0.154-0.021,0.223-0.021c0.162,0,0.287,0.035,0.415,0.07c0.127,0.035,0.255,0.07,0.42,0.07
		c0.028,0,0.059-0.001,0.089-0.003c0.034-0.269,0.176-0.428,0.107-0.802c-1.174-0.197-2.283-0.453-3.538-0.568
		c-0.159,0.146-0.108,0.494-0.114,0.796C92.595,76.818,93.143,76.804,93.422,77.058z M5.233,85.508
		c3.078-4.267-1.902-6.936-3.312-9.363c-0.632,0.628-1.055,1.575-1.145,2.396C0.413,81.883,3.415,83.925,5.233,85.508z
		 M144.871,76.259c-1.676,3.063,1.761,4.687,3.432,6.169C150.033,79.166,146.648,77.406,144.871,76.259z M72.757,81.755
		c0.273,0,0.479-0.073,0.699-0.128c0.018-1.008-0.432-1.549-0.455-2.514c0.809,0.602,1.419,1.395,2.164,2.057
		c0.354-0.027,0.651-0.117,0.914-0.23c-0.2-1.552-0.616-2.881-1.026-4.225c-0.06-0.014-0.112-0.019-0.159-0.019
		c-0.115,0-0.194,0.035-0.273,0.072c-0.079,0.035-0.157,0.071-0.272,0.071c-0.029,0-0.061-0.002-0.095-0.008
		c-0.091,0.967,0.572,1.78,0.342,2.51c-0.888-0.483-1.356-1.942-2.422-1.942c-0.165,0-0.344,0.036-0.541,0.114
		c0.089,1.62,0.695,2.729,0.911,4.228C72.62,81.75,72.69,81.755,72.757,81.755z M96.163,81.056c0.248,0.091,0.446,0.233,0.79,0.233
		c0.036,0,0.074-0.002,0.115-0.005c0.245-0.448,0.121-1.255,0.576-1.485c0.49,0.003,0.879,0.11,1.026,0.456
		c0.102,0.682-0.222,1.141,0,1.6c0.06-0.014,0.112-0.02,0.157-0.02c0.113,0,0.192,0.035,0.27,0.071
		c0.08,0.035,0.159,0.07,0.275,0.07c0.029,0,0.061-0.003,0.096-0.008c0.035-0.77,0.434-1.568,0-2.17
		c2.012-1.083,0.012-2.871-1.863-2.871c-0.102,0-0.203,0.005-0.303,0.016C96.848,78.242,96.494,79.636,96.163,81.056z
		 M98.424,78.005c0.556,0,1.19,0.465,0.818,1.108c-0.143,0.067-0.292,0.095-0.442,0.095c-0.38,0-0.764-0.175-1.041-0.321
		C97.643,78.26,98.012,78.005,98.424,78.005z M101.338,82.766c0.137,0,0.217,0.059,0.297,0.117S101.795,83,101.932,83
		c0.014,0,0.029,0,0.045-0.001c0.559-1.577,1.95-2.316,2.964-3.429c-0.21-0.222-0.409-0.308-0.601-0.308
		c-0.59,0-1.106,0.817-1.681,0.992c0.046-0.688-0.032-1.261-0.112-1.828c-0.342-0.014-0.484-0.229-0.842-0.229
		c-0.022,0-0.046,0.001-0.07,0.003c0.205,1.688,0.131,3.276-0.342,4.566C101.309,82.766,101.324,82.766,101.338,82.766z
		 M70.269,82.66c0.338,0,0.537-0.142,0.793-0.231c-0.444-1.466-0.797-3.008-1.595-4.112c-0.161,0.19-0.513,0.177-0.689,0.342
		c0.403,1.384,0.917,2.658,1.367,3.996C70.188,82.658,70.229,82.66,70.269,82.66z M166.551,79.114
		c-0.471,0.52-0.762,1.657-0.796,2.057c-0.309,3.974,3.431,4.617,4.901,6.96c0.41-0.613,0.706-1.113,0.797-1.824
		C172.013,82.232,168.535,80.886,166.551,79.114z M26.794,85.851c2.04-2.832-1.111-5.002-2.399-6.623
		c-0.216,0.531-0.587,0.802-0.684,1.255C23.096,83.358,25.512,84.587,26.794,85.851z M10.555,91.908c0.09,0,0.183-0.004,0.275-0.008
		c0.093-0.004,0.186-0.006,0.274-0.006c0.059,0,0.116,0,0.17,0.004c0.216-0.244,0.08-0.834,0.114-1.254
		c1.215-0.158,2.367-0.262,3.459-0.262c1.116,0,2.17,0.109,3.165,0.377c0.022,0.387-0.092,0.922,0.107,1.139h1.145v-5.25H18.12
		c-0.28,0.291,0.085,1.22-0.336,1.371h-2.627c-1.031-3.418,2.194-1.883,3.654-2.74c1.503-0.882,1.016-4.212-1.316-4.211
		c-0.229,0-0.478,0.032-0.742,0.102c-0.211,0.243-0.074,0.833-0.113,1.258h0.288c0.508,0,1.006,0.021,1.194,0.34
		c-1.01,0.63-3.204,0.066-3.648,1.258c-0.336-0.779-0.764-1.446-1.488-1.598c-0.249-0.054-0.477-0.079-0.688-0.079
		c-3.329,0-1.889,6.44-2.275,9.436C10.139,91.885,10.336,91.908,10.555,91.908z M12.667,84.943c0.322,0,0.668,0.169,1.002,0.565
		c0.095,0.693,0.25,1.843,0,2.511h-2.166C10.898,86.582,11.664,84.943,12.667,84.943z M145.213,81.396
		c-1.459,3.36,1.755,4.711,3.311,6.163C150.102,84.445,147.237,82.376,145.213,81.396z M5.462,92.697
		c0.524-0.707,0.706-1.084,0.796-1.826c0.56-4.53-3.522-4.632-4.678-7.302c-0.296,0.294-0.731,0.907-0.804,1.142
		C-0.683,89.561,3.746,90.707,5.462,92.697z M27.017,91.102c1.768-3.223-1.62-4.803-3.076-6.278
		C22.148,87.787,25.113,89.891,27.017,91.102z M161.759,94.521c0.199-2.992,0.325-6.064,0.456-9.126
		c-0.059,0.007-0.111,0.01-0.162,0.01c-0.17,0-0.305-0.036-0.439-0.071c-0.134-0.035-0.27-0.071-0.439-0.071
		c-0.065,0-0.136,0.005-0.213,0.018c-0.211,0.323,0.006,1.07-0.228,1.369c-2.196-0.053-4.485,0-6.39-0.342
		c-0.137-0.322-0.126-0.787-0.114-1.255c-0.171-0.064-0.472-0.128-0.748-0.128c-0.201,0-0.39,0.034-0.506,0.128
		c0.024,1.886-0.238,3.481-0.228,5.358c0.314,0.031,0.545,0.131,0.883,0.131c0.079,0,0.163-0.006,0.256-0.018
		c0.217-0.428,0.127-1.156,0.343-1.59c0.124,0.008,0.255,0.012,0.388,0.012c0.201,0,0.41-0.008,0.619-0.016s0.42-0.016,0.621-0.016
		c0.375,0,0.723,0.027,0.992,0.133c-0.021,0.545,0.115,1.254-0.227,1.477c-0.069,0.008-0.145,0.01-0.223,0.01
		c-0.094,0-0.192-0.004-0.291-0.008c-0.1-0.004-0.198-0.008-0.292-0.008c-0.229,0-0.436,0.023-0.562,0.127
		c-0.137,0.32-0.127,0.787-0.115,1.254c1.402,0.152,2.93,0.188,4.451,0.229V90.76c-0.141,0.031-0.269,0.047-0.389,0.047
		c-0.921,0-1.248-0.889-0.869-1.76c0.82,0.053,1.692,0.057,2.399,0.221c-0.098,1.738,0.083,3.752-1.745,3.752
		c-0.224,0-0.477-0.031-0.764-0.094c-0.131,0.34-0.223,1.158,0,1.49c0.078-0.004,0.156-0.004,0.23-0.004
		c0.467,0,0.902,0.033,1.34,0.066c0.437,0.031,0.873,0.064,1.344,0.064C161.338,94.543,161.543,94.535,161.759,94.521z
		 M166.781,86.196c-0.982,0.709-1.198,2.46-1.026,3.65c0.369,2.527,3.721,3.832,4.332,5.596c0.585-0.537,0.899-1.176,1.025-1.938
		C171.75,89.697,168.57,88.107,166.781,86.196z M145.674,86.536h-0.113c-1.922,2.939,1.213,4.763,2.279,6.503
		C150.617,90.342,146.912,87.797,145.674,86.536z M27.707,96.125c1.322-3.393-2.091-4.471-3.767-5.936
		C22.582,93.523,25.569,95.168,27.707,96.125z M6.487,99.66c1.004-1.594,0.548-3.895-0.342-5.025
		c-0.639-0.809-2.051-1.248-3.083-2.047c-0.638-0.502-0.974-1.426-1.481-1.486c-1.112,1.893-0.531,4.42,0.683,5.816
		C3.449,98.293,5.576,98.643,6.487,99.66z M145.213,91.557c-2.16,2.51,0.655,5.111,1.943,6.621
		C149.266,95.812,146.9,92.605,145.213,91.557z M16.071,103.523c1.337,0,2.673-0.516,3.65-1.695c1.363-1.643,1.363-4.82-0.342-6.621
		c0.775-0.148,0.507-1.037,0.342-1.703c-0.937,0.041-2.001,0.367-2.874,0.367c-0.151,0-0.298-0.012-0.437-0.033
		c-0.377-0.115-0.246-0.742-0.451-1.027c-0.117-0.008-0.231-0.014-0.34-0.014c-0.387,0-0.71,0.064-0.919,0.242
		c0.278,1.627,0.483,3.318,0.798,4.91c0.092,0.01,0.18,0.016,0.265,0.016c0.409,0,0.725-0.102,0.989-0.242
		c-0.021-0.496-0.234-0.938,0-1.369c0.352-0.086,0.746-0.125,1.181-0.125c0.136,0,0.276,0.004,0.42,0.012
		c0.536,0.639,1.077,1.408,0.797,2.623c-0.311,1.33-1.893,2.029-3.455,2.029c-1.919,0-3.809-1.055-3.281-3.283
		c0.205-0.867,0.98-1.295,2.058-1.719c-0.051-0.479,0.058-1.119-0.342-1.256h-0.093c-0.91,0-2.384,0.309-3.446,0.459v1.031
		c0.113,0.297,0.616,0.221,0.797,0.455C9.591,100.508,12.836,103.523,16.071,103.523z M166.439,93.152
		c-0.469,0.686-0.834,0.932-1.027,1.482c-1.51,4.371,2.691,5.562,3.533,8.227c0.855-0.873,1.379-2.092,1.368-3.428
		c-0.011-3.012-2.575-4.322-3.763-6.168L166.439,93.152z M28.618,101.148c1.227-3.625-2.389-4.287-4.11-5.707
		C23.393,98.953,26.452,100.168,28.618,101.148z M144.303,96.697c-2.259,2.457,0.546,4.807,1.48,6.729
		C148.389,101.59,146.022,97.689,144.303,96.697z M2.264,98.293c-0.376,0.617-0.565,1.164-0.575,1.943
		c-0.035,3.76,3.567,4.895,6.166,6.275C9.713,101.311,4.447,100.625,2.264,98.293z M72.659,98.635c0.118,0.291-0.349,0.541,0,0.686
		c0.018-0.078,0.071-0.117,0.172-0.117c0.017,0,0.036,0,0.056,0.002C72.864,98.834,73.15,98.658,72.659,98.635z M159.195,105.723
		c0.053,0,0.106-0.002,0.164-0.008c0.375-1.643,1.174-3.465,1.26-5.025c-0.293-0.113-0.582-0.23-0.992-0.23h-0.035
		c-0.393,0.182-0.187,0.957-0.689,1.029c-0.069,0.004-0.138,0.004-0.205,0.004c-0.932,0-1.771-0.363-2.613-0.727
		c-0.841-0.365-1.683-0.729-2.617-0.729c-0.375,0-0.764,0.059-1.174,0.199c-1.996,0.68-3.342,5.01-1.711,6.848
		c1.328,1.498,4.253,1.162,6.156,2.285c0.296,0.326-0.052,0.707,0,1.141h0.02c0.219,0,0.372,0.061,0.523,0.123
		c0.152,0.061,0.303,0.123,0.516,0.123c0.059,0,0.124-0.006,0.194-0.018c0.302-1.34,0.695-2.588,1.024-3.885
		c-0.307-0.182-0.682-0.295-1.139-0.342c-0.308,0.342-0.227,1.061-0.797,1.146c-1.5-0.912-5.482-0.525-5.699-2.738
		c-0.166-1.664,0.791-2.145,1.989-2.145c0.797,0,1.7,0.211,2.455,0.428c0.935,0.268,1.894,0.498,2.509,1.025
		c0.027,0.486-0.171,0.736-0.229,1.143C158.435,105.521,158.717,105.723,159.195,105.723z M165.641,100.123
		c-1.084,0.84-1.699,2.436-1.596,3.881c0.171,2.486,2.844,3.791,3.19,5.938c0.644-0.594,1.227-1.188,1.482-1.945
		c1.208-3.486-1.711-5.768-2.963-7.76L165.641,100.123z M74.14,100.459c-0.129-0.027-0.195-0.123-0.359-0.123
		c-0.029,0-0.061,0.002-0.097,0.01c-0.034,0.154-0.159,0.217-0.113,0.457c0.042,0.006,0.081,0.01,0.117,0.01
		C73.955,100.812,74.049,100.635,74.14,100.459z M30.329,106.172c0.876-3.699-2.725-3.893-4.565-5.256
		C24.874,104.568,28.202,105.547,30.329,106.172z M143.39,101.602c-2.536,1.916-0.296,5.127,0.798,6.969
		C146.865,106.648,144.648,102.941,143.39,101.602z M82.092,101.822c-0.041,0-0.103,0.035-0.195,0.127
		c0.432,0.223-0.586-0.072-0.349,0.342c0.812,0.387,2.109,0.607,3.239,0.607c0.266,0,0.523-0.012,0.763-0.037
		c-0.667-0.666-2.25-0.416-3.312-0.689C82.183,102.049,82.199,101.822,82.092,101.822z M90.453,102.291
		c-1.164-0.205-2.543-0.199-3.763-0.342c0.091,0.512,0.634,0.572,1.237,0.572c0.094,0,0.191-0.002,0.287-0.004
		c0.096,0,0.191-0.002,0.286-0.002c0.499,0,0.958,0.041,1.151,0.346C89.46,102.32,90.293,102.73,90.453,102.291z M94.448,101.949
		c-0.087-0.02-0.163-0.029-0.231-0.029c-0.425,0-0.504,0.35-0.682,0.6h0.799C94.282,102.244,94.545,102.268,94.448,101.949z
		 M75.365,102.605c-0.141,0-0.307-0.027-0.476-0.059c-0.169-0.027-0.341-0.057-0.493-0.057c-0.252,0-0.449,0.08-0.483,0.371
		c0.053-0.004,0.107-0.004,0.164-0.004c0.312,0,0.677,0.041,1.044,0.084c0.367,0.041,0.737,0.084,1.062,0.084
		c0.41,0,0.749-0.066,0.921-0.285c-0.581-0.061-1.062-0.227-1.37-0.568C75.78,102.514,75.613,102.605,75.365,102.605z
		 M79.608,103.146c0.09-0.035,0.18-0.072,0.304-0.072c0.046,0,0.097,0.006,0.155,0.018c0.115-0.461-0.639-0.049-0.571-0.465
		c0.093,0,0.191,0.006,0.283,0.006c0.213,0,0.396-0.029,0.407-0.229c-0.588-0.074-1.522-0.162-2.326-0.162
		c-0.558,0-1.054,0.043-1.327,0.162c0.822,0.279,2.189,0.025,2.85,0.457c-0.016,0-0.032-0.002-0.046-0.002
		c-0.269,0-0.333,0.189-0.181,0.344c0.055,0.01,0.104,0.014,0.147,0.014C79.429,103.217,79.519,103.182,79.608,103.146z
		 M69.576,103.426h0.083c0.761,0,1.922-0.373,2.659-0.451c-0.353-0.209-0.762-0.307-1.16-0.307
		C70.491,102.668,69.857,102.941,69.576,103.426z M82.239,102.975c-0.1-0.096-0.269-0.123-0.464-0.123
		c-0.073,0-0.149,0.006-0.226,0.01c-0.079,0.189,0.107,0.26,0.306,0.26C82.042,103.121,82.239,103.059,82.239,102.975z
		 M100.768,102.975c-0.065-0.008-0.131-0.014-0.189-0.014c-0.147,0-0.252,0.045-0.197,0.242c0.092,0,0.189,0.006,0.282,0.006
		c0.212,0,0.394-0.031,0.401-0.234c-0.031,0.01-0.068,0.014-0.11,0.014C100.896,102.988,100.832,102.98,100.768,102.975z
		 M66.948,103.203c-0.059,0.217,0.273,0.369,0.554,0.369c0.199,0,0.372-0.076,0.363-0.26c-0.106-0.121-0.352-0.221-0.58-0.221
		C67.157,103.092,67.035,103.123,66.948,103.203z M75.725,104.172c-0.809,0-1.212,0.951-2.041,1.084
		c-0.159,0.516,0.143,0.568,0.498,0.568c0.046,0,0.093-0.002,0.14-0.002c0.047-0.002,0.094-0.002,0.14-0.002
		c0.199,0,0.382,0.018,0.478,0.121c0.068,1.211-0.081,2.209-0.114,3.311c0.108,0.1,0.295,0.123,0.505,0.123
		c0.075,0,0.152-0.004,0.229-0.006c0.078-0.004,0.155-0.006,0.23-0.006c0.061,0,0.12,0.002,0.176,0.006
		c0-1.758,0.192-3.307,0.113-5.141C75.952,104.189,75.835,104.172,75.725,104.172z M82.695,106.512
		c1.379-1.432-0.025-2.275-1.389-2.275c-1.297,0-2.556,0.764-1.347,2.504c-1.772,1.568,0,2.824,1.588,2.824
		c1.046,0,2.013-0.545,1.832-1.906C83.304,107.092,82.905,107.129,82.695,106.512z M81.375,105.02c0.414,0,0.827,0.199,0.864,0.58
		c0.03,0.477-0.4,0.691-0.841,0.691c-0.383,0-0.775-0.162-0.874-0.463C80.432,105.279,80.903,105.02,81.375,105.02z M82.239,108.34
		c-0.163,0.232-0.538,0.35-0.912,0.35c-0.375,0-0.75-0.117-0.913-0.35c-0.233-0.869,0.338-1.244,0.935-1.244
		c0.334,0,0.675,0.117,0.889,0.328C82.324,107.883,82.324,107.887,82.239,108.34z M90.566,107.887
		c-0.062-0.279-0.734,0.053-0.684-0.344v-3.092h-1.141c-0.558,1.467-2.576,2.066-2.28,4.006c0.229,0.1,0.538,0.125,0.872,0.125
		c0.168,0,0.342-0.006,0.518-0.012c0.176-0.008,0.35-0.014,0.518-0.014c0.131,0,0.256,0.004,0.373,0.014
		c0.033,0.383-0.09,0.92,0.114,1.141h0.911c-0.079-0.459,0.109-0.648,0.115-1.025c0.027-0.092,0.133-0.107,0.254-0.107
		c0.029,0,0.059,0,0.09,0.002c0.029,0.002,0.061,0.002,0.09,0.002c0.165,0,0.302-0.027,0.25-0.242V107.887z M88.742,107.658h-1.254
		c0.297-0.582,0.729-1.027,1.14-1.486C88.891,106.443,88.669,107.197,88.742,107.658z M95.998,104.613
		c-0.393-0.057-0.79-0.113-1.137-0.113c-0.623,0-1.09,0.182-1.104,0.871c0.685,0.234,1.767,0.062,2.405,0.344
		c-0.89,1.049-1.659,2.225-1.829,3.996c0.107,0.1,0.294,0.121,0.502,0.121c0.074,0,0.152-0.004,0.23-0.006s0.156-0.006,0.231-0.006
		c0.061,0,0.12,0.002,0.175,0.006c0.178-1.043,0.422-1.645,0.805-2.402c0.49-0.998,1.322-1.51,1.249-2.736
		c-0.124,0.029-0.263,0.041-0.413,0.041C96.779,104.729,96.391,104.672,95.998,104.613z M3.519,105.715
		c-0.646,2.133,0.382,4.312,1.601,5.367c1.34,1.162,3.448,1.076,4.902,2.166C11.491,108.07,5.952,107.363,3.519,105.715z
		 M32.386,110.963c0.672-3.432-3.168-3.898-5.022-4.791C26.709,109.58,30.021,110.578,32.386,110.963z M143.503,109.826
		c-0.166-1.35-1.521-2.352-1.71-3.43c-2.975,1.592-0.535,4.729,0,6.965C143.104,112.752,143.707,111.447,143.503,109.826z
		 M164.152,107.084c-0.404,0.375-0.961,0.75-1.135,1.025c-2.119,3.275,0.97,5.771,1.711,8.91c1.487-0.764,2.074-2.209,2.164-3.658
		c0.201-3.326-1.971-3.883-2.506-6.277H164.152z M25.312,116.449c0.399-0.016,0.507-0.332,0.912-0.344
		c-1.214-2.947-2.272-6.074-3.88-8.562c-1.244,0.389-2.359,0.914-3.533,1.371c0.091,0.479,0.398,0.74,0.455,1.254
		c0.537-0.264,1.016-0.381,1.441-0.381c1.622,0,2.46,1.688,2.776,3.234c-0.808,0.369-1.55,0.805-2.509,1.025
		c-0.157-0.451-0.528-0.686-0.454-1.369c0.147-0.576,1.021-0.422,1.14-1.027c-0.194-0.34-0.205-0.854-0.686-0.912
		c-1.31,0.594-2.849,0.951-3.879,1.824c0.177,0.322,0.353,0.635,0.456,1.027c0.474-0.051,0.85-0.217,1.26-0.342
		c0.37,0.348,0.804,0.639,0.797,1.369c-0.531,0.463-1.276,0.701-2.058,0.912c-1.038-1.293-2.033-4.182,0.348-4.676
		c0-0.576-0.341-0.805-0.456-1.256c-1.232,0.332-2.178,0.945-3.312,1.365c1.077,3.039,2.451,5.766,3.653,8.676
		c0.037,0.002,0.072,0.004,0.106,0.004c0.454,0,0.664-0.252,1.032-0.344c0.024-0.598-0.396-0.746-0.34-1.367
		c1.852-0.918,3.773-1.779,5.812-2.512C24.907,115.553,24.758,116.352,25.312,116.449z M157.422,111.994
		c-1.073-0.49-2.086-1.031-3.307-1.369c-0.17,0.398-0.508,0.625-0.455,1.254c1.049,0.223,2.348,1.523,1.254,2.512
		c-1.893-0.953-4.303-1.396-5.82-2.74c0.063-0.469,0.326-0.74,0.348-1.256c-0.332-0.143-0.594-0.342-1.052-0.342
		c-0.028,0-0.058,0-0.087,0.002c-0.684,1.717-1.442,3.354-2.062,5.139c0.467,0.023,0.645,0.336,1.129,0.336h0.016
		c0.312-0.4,0.302-1.139,0.918-1.248c1.863,0.9,4.172,1.373,5.699,2.623c-0.096,0.697-0.65,0.891-1.306,0.891
		c-0.509,0-1.079-0.117-1.544-0.207c-0.12,0.412-0.286,0.783-0.457,1.143c1.061,0.537,2.246,0.951,3.42,1.373
		C155.244,117.424,156.396,114.771,157.422,111.994z M66.491,110.854c-4.742,4.34-9.696,8.482-14.26,13.016
		c0.389,1.289,0.717,2.629,1.14,3.879c5.819-4.877,11.318-10.062,16.773-15.293c-0.621-0.143-1.007-0.52-1.709-0.576
		c-5.055,4.297-9.752,8.973-14.944,13.133c-0.03-0.355-0.121-0.646-0.234-0.912c4.491-4.461,9.485-8.404,13.923-12.902
		C66.884,111.145,66.834,110.854,66.491,110.854z M34.779,115.529c0.28-3.549-3.203-3.342-5.363-4.447
		C28.875,114.373,32.101,115.457,34.779,115.529z M119.085,124.668c-4.147-4.004-8.651-8.094-12.542-12.213
		c-0.462-0.492-1.09-1.299-1.872-1.299c-0.098,0-0.198,0.012-0.3,0.041c0.791,1.305,2.051,2.383,3.311,3.533
		c2.412,2.227,4.531,4.357,6.96,6.736c0.717,0.715,3.34,2.482,3.419,3.428c0.029,0.291-0.297,0.707-0.457,1.029
		c-3.897-3.596-8.582-8.17-12.663-12.105c-0.818-0.789-1.478-1.561-2.3-1.561c-0.369,0-0.771,0.156-1.234,0.535
		c2.439,2.924,5.312,5.424,8.1,7.996c2.81,2.578,5.209,5.611,8.326,7.877C118.277,127.361,118.705,126.029,119.085,124.668z
		 M139.626,111.537h-0.112c-2.703,1.613-0.901,4.428-0.457,6.85C141.93,117.6,141.154,113.209,139.626,111.537z M71.291,112.678
		c-0.083-0.039-0.162-0.055-0.237-0.055c-0.459,0-0.803,0.607-1.13,0.854c-0.445,3.332-0.468,7.074-1.032,10.279
		c-1.157-0.746-2.49-1.318-3.541-2.174c0.114-1.334,0.412-2.488,0.343-3.994c-1.665,1.109-3.478,2.072-3.762,4.564
		c-0.068,0.01-0.129,0.016-0.187,0.016c-0.153,0-0.27-0.035-0.388-0.072c-0.117-0.037-0.235-0.072-0.391-0.072
		c-0.054,0-0.111,0.004-0.175,0.014c0.542,0.779,1.603,1.033,2.912,1.033h0.052c0.229-0.275-0.211-0.643,0.114-0.918
		c1.71,0.857,3.317,1.689,4.909,2.742c1.249,0.822,2.428,2.389,4.129,2.389c0.248,0,0.506-0.033,0.777-0.107
		c0.434-0.359,0.047-1.543,0.342-2.051c2.269,1.379,3.911,3.387,5.82,5.139c0.193-4.719,0.369-9.447,0.568-14.158
		c-1.578-1.197-3.504-2.047-5.933-2.4c-0.615,2.816-0.307,6.543-0.568,9.701c-1.026-0.646-2.395-0.951-3.655-1.369
		C70.847,119.295,71.769,115.736,71.291,112.678z M70.468,123.262c0.505,0,1.081,0.492,1.621,0.607
		c-0.113,0.451,0.251,0.662,0,0.912c-0.764-0.348-1.728-0.48-1.83-1.488C70.327,123.271,70.397,123.262,70.468,123.262z
		 M5.69,112.678c-0.013,0.146-0.149,0.16-0.115,0.344c-0.155,0.26-0.161,1.146-0.114,1.482c0.466,3.734,4.417,4.42,7.181,5.252
		C13.566,114.596,8.481,114.496,5.69,112.678z M96.043,114.281c-2,0.461-3.903,1.043-5.249,2.168
		c0.012,4.723-0.192,9.24-0.114,14.041c1.658-1.156,3.238-3.383,5.363-4.453c0.193,0.496,0.15,1.221,0.457,1.602
		c4.314-0.297,5.871-4.33,9.922-5.258c0.215,0.512,0.644,0.682,1.149,0.682c0.821,0,1.849-0.443,2.504-0.568
		c-0.759-1.1-1.852-2.662-2.964-2.965c-0.073,0.609,0.32,1.215,0,1.717c-0.16-0.016-0.312-0.023-0.457-0.023
		c-1.523,0-2.224,0.83-3.311,1.271c-0.318-2.064-0.301-4.773,0-6.85c-0.773-0.943-1.755-2.264-2.848-2.51
		c-1.062,2.682-0.109,6.629,0.111,9.479c-0.05-0.002-0.1-0.002-0.148-0.002c-2.008,0-2.894,1.113-4.296,1.709
		C95.981,120.979,96.459,117.104,96.043,114.281z M98.671,126.264c-0.14-0.111,0.229-0.574,0.615-0.574c0.022,0,0.046,0,0.067,0.004
		C99.242,126.004,98.824,126.004,98.671,126.264z M100.153,123.639c-0.411,0.852-1.276,1.236-2.167,1.6
		C97.656,124.035,98.951,123.672,100.153,123.639z M161.992,113.934c-1.412,0.832-2.233,2.668-2.176,4.111
		c0.09,2.018,2.182,3.713,2.062,5.711c1.721-0.703,2.595-2.488,2.509-4.457c-0.107-2.309-1.902-3.455-2.172-5.365H161.992z
		 M31.587,115.992c-0.148,3.416,2.873,3.662,5.702,4.111C37.607,116.512,34.066,116.779,31.587,115.992z M138.488,119.416
		c-0.018-1.295-0.998-2.207-0.914-3.424c-3.19,0.865-1.754,4.268-1.365,6.852C137.654,122.34,138.505,121.023,138.488,119.416z
		 M141.109,126.264c0.359-0.129,0.359-0.627,0.799-0.682c3.071,0.051,5.447,0.791,8.445,0.916c0.338-0.615,0.781-1.123,1.027-1.83
		c-1.535-2.424-3.562-4.359-4.908-6.965c0.018-0.398,0.325-0.516,0.336-0.918c-0.354-0.172-0.547-0.502-1.025-0.564
		c-0.73,1.135-1.494,2.23-2.051,3.535c0.221,0.279,0.507,0.484,0.912,0.572c0.328-0.207,0.422-0.646,0.802-0.799
		c0.364,0.312,0.77,0.598,0.907,1.146c-0.646,0.791-1.006,2.68-2.133,2.68c-0.238,0-0.514-0.086-0.831-0.285
		c-0.091-0.627,0.386-0.684,0.343-1.256c-0.423-0.072-0.559-0.432-1.027-0.459c-0.893,1.459-1.881,2.838-2.621,4.451
		c0.443,0.029,0.48,0.461,0.946,0.461C141.055,126.268,141.081,126.268,141.109,126.264z M147.265,121.814
		c0.638,0.623,1.294,1.223,1.607,2.168c-0.816-0.141-1.728-0.178-2.519-0.344C146.461,122.832,147.002,122.461,147.265,121.814z
		 M31.816,127.176c0.411-0.117,0.707-0.352,0.912-0.678c-0.923-1.328-1.733-2.766-2.627-4.117c-0.466,0.027-0.598,0.395-1.02,0.463
		c-0.046,0.57,0.427,0.627,0.335,1.256c-0.57,0.602-1.362,0.996-2.167,1.367c-0.62-0.902-1.282-1.758-1.716-2.854
		c0.708-0.439,1.278-1.008,2.173-1.258c0.519,0.055,0.438,0.701,0.911,0.797c0.383-0.074,0.559-0.354,0.912-0.451
		c-0.792-1.574-1.653-3.068-2.736-4.34c-0.398,0.137-0.814,0.25-0.917,0.684c0.097,0.398,0.467,0.531,0.456,1.025
		c-1.675,1.25-3.374,2.482-5.471,3.311c-0.348-0.182-0.49-0.578-0.684-0.914c-0.394,0.223-0.935,0.287-1.027,0.801
		c0.914,1.371,1.62,2.941,2.736,4.117c0.377-0.166,0.764-0.303,0.913-0.691c-0.098-0.354-0.433-0.479-0.456-0.912
		c0.484-0.615,1.237-0.969,1.938-1.375c0.576,0.955,1.252,1.803,1.711,2.857c-0.554,0.475-1.107,0.947-1.939,1.146
		c-0.45-0.117-0.376-0.768-0.911-0.803c-0.24,0.262-0.713,0.277-0.798,0.689c0.889,1.393,1.608,2.959,2.736,4.105
		c0.433-0.027,0.484-0.428,0.913-0.461c-0.074-0.523-0.439-0.77-0.571-1.254c1.716-1.248,3.499-2.436,5.483-3.424
		C31.303,126.469,31.349,127.035,31.816,127.176z M13.405,125.475c0.121,0,0.245-0.008,0.369-0.016
		c0.125-0.01,0.249-0.018,0.373-0.018c0.109,0,0.218,0.008,0.325,0.025c0.435,0.068,0.872,0.396,1.247,0.396
		c0.083,0,0.164-0.018,0.241-0.057c0.547-5.074-4.592-4.561-7.648-6.168c-0.65,3.227,2.269,5.594,4.901,5.828
		C13.275,125.471,13.34,125.475,13.405,125.475z M156.738,124.555c0.096,1.914,1.846,3.238,1.596,5.48
		c1.938-0.623,2.734-2.258,2.854-3.998c0.167-2.453-1.545-3.834-1.937-5.824C157.557,121.098,156.658,122.922,156.738,124.555z
		 M134.378,120.441c-2.957,0.719-1.853,4.082-1.716,6.621C135.752,126.881,135.376,122.279,134.378,120.441z M39.018,123.965
		c0.484,0,0.978-0.035,1.468-0.096c-0.075-3.08-3.404-2.914-6.164-3.314C34.441,123.111,36.604,123.965,39.018,123.965z
		 M131.521,124.439c-3.077,0.455-2.586,3.535-2.506,6.502c1.886-0.301,3.009-1.973,2.855-3.992
		C131.797,126.061,131.238,125.301,131.521,124.439z M42.026,128.014c0.627,0,1.268-0.059,1.878-0.152
		c-0.13-3.104-3.482-2.771-6.276-2.967C37.766,127.297,39.823,128.014,42.026,128.014z M11.504,126.264
		c-0.155,1.49,0.479,2.758,1.024,3.424c1.607,1.973,4.811,1.92,7.193,2.174C19.797,126.854,15.053,127.543,11.504,126.264z
		 M142.363,136.998c-0.159-0.447-0.467-0.752-0.913-0.91c-0.278,0.25-0.421,0.639-0.911,0.684c-0.711-0.359-1.23-0.9-1.709-1.482
		c0.775-0.781,1.344-1.775,2.279-2.402c0.609,0.422,1.186,0.873,1.596,1.482c0.046,0.59-0.495,0.57-0.569,1.025
		c0.272,0.307,0.456,0.693,0.911,0.807c1.084-1.318,2.359-2.441,3.307-3.887c-0.297-0.186-0.502-0.48-0.793-0.682
		c-0.576,0.074-0.57,0.723-1.146,0.799c-1.59-1.416-3.367-2.641-4.673-4.344c0.183-0.426,0.611-0.602,0.685-1.139
		c-0.41-0.098-0.453-0.568-0.956-0.568c-0.022,0-0.047,0.002-0.071,0.004c-1.002,1.318-2.25,2.385-3.189,3.766
		c0.312,0.211,0.466,0.586,0.91,0.678c0.342-0.182,0.49-0.572,0.913-0.678c0.683,0.564,1.51,0.998,1.938,1.824
		c-0.81,0.752-1.396,1.721-2.28,2.395c-0.559-0.654-1.459-0.973-1.83-1.824c-0.011-0.541,0.525-0.541,0.57-1.025
		c-0.199-0.332-0.494-0.57-0.912-0.691c-0.335,0.4-1.025,1.043-1.709,1.83c-0.906,1.045-2.344,2.135-0.689,2.631
		c0.417-0.145,0.427-0.691,0.98-0.691c0.015,0,0.029,0,0.045,0.002c1.642,1.293,3.283,2.588,4.57,4.23
		c0.023,0.588-0.534,0.605-0.57,1.135c0.264,0.273,0.49,0.576,0.912,0.691C140.221,139.5,141.223,138.176,142.363,136.998z
		 M155.825,126.498h-0.114c-1.487,0.879-2.609,2.283-2.621,4.107c-0.012,1.955,1.629,3.461,1.254,5.596
		c3.328-0.514,3.865-6.098,1.823-8.453C155.951,127.434,156.281,126.574,155.825,126.498z M127.419,128.209
		c-2.741,0.4-2.507,3.488-2.507,6.391C127.807,134.473,128.389,130.869,127.419,128.209z M36.945,133.23
		c0.423-0.109,0.649-0.412,0.913-0.686c-1.049-1.57-2.315-2.932-3.648-4.221c-0.268,0.301-0.712,0.426-0.797,0.914
		c0.296,0.346,0.694,0.598,0.797,1.139c-1.578,1.467-3.157,2.932-4.907,4.223c-1.169-0.953-0.069-2.221,0.571-2.967
		c-0.286-0.324-0.542-0.68-0.913-0.912c-0.854,0.855-1.938,1.488-2.628,2.51c1.989,2.086,3.665,4.477,5.826,6.395
		c0.974-0.617,1.709-1.49,2.621-2.168c-0.182-0.469-0.41-0.885-0.912-1.027c-0.284,0.426-1.037,0.773-1.705,0.773
		c-0.524,0-0.995-0.215-1.145-0.773c1.694-1.307,3.055-2.949,4.902-4.115C36.41,132.473,36.615,132.916,36.945,133.23z
		 M45.278,131.746c0.642-0.072,1.275-0.51,1.981-0.51c0.134,0,0.271,0.016,0.411,0.053c-0.095-2.041-1.825-2.428-3.651-2.428
		c-1.009,0-2.049,0.119-2.856,0.139c0.365,1.643,1.753,2.775,3.595,2.775C44.927,131.775,45.101,131.766,45.278,131.746z
		 M120.803,137.797c2.695-0.01,3.447-3.016,3.195-6.051c-1.64,0.006-2.848,1.17-3.088,2.736c-0.09,0.67,0.079,1.605,0,2.289
		C120.863,137.223,120.529,137.518,120.803,137.797z M51.775,134.369c-0.377-1.631-1.649-2.051-3.202-2.051
		c-1.071,0-2.274,0.201-3.409,0.34c0.119,1.811,1.736,2.43,3.426,2.43C49.782,135.088,51.01,134.779,51.775,134.369z M22,133.92
		c-1.709-1.359-4.877-0.486-6.617-1.488c-0.12,0-0.136,0.096-0.227,0.113c0.267,4.496,4.176,4.998,8.554,5.025
		C23.608,135.648,22.941,134.656,22,133.92z M151.949,132.545c-1.591,0.867-2.906,2.1-2.969,4.113
		c-0.052,1.658,1.215,3.496,0.57,5.361c2.314-0.377,3.768-2.498,3.768-4.906c0-1.643-0.992-3.102-1.141-4.568H151.949z
		 M119.891,134.832c-0.078-0.004-0.154-0.006-0.229-0.006c-3.156,0-2.993,3.33-3.768,5.715c0.098,0.006,0.193,0.008,0.287,0.008
		C119.393,140.549,119.773,137.25,119.891,134.832z M52.801,138.361c1.414-0.027,2.424-1.139,3.541-1.248
		c-0.501-1.316-1.458-1.717-2.598-1.717c-1.314,0-2.871,0.533-4.248,0.805c0.245,1.301,1.666,2.162,3.198,2.162
		C52.73,138.363,52.766,138.363,52.801,138.361z M111.107,143.045c0.219,0.035,0.428,0.051,0.625,0.051
		c2.751,0,3.536-3.119,3.705-5.525c-0.139-0.012-0.271-0.018-0.398-0.018C111.902,137.553,112.104,140.906,111.107,143.045z
		 M57.211,141.072c1.013,0,2.028-0.297,2.67-0.879c0.22-0.055,0.421-0.127,0.697-0.127c0.066,0,0.137,0.002,0.213,0.012
		c-0.56-1.484-1.503-1.932-2.604-1.932c-1.319,0-2.865,0.643-4.24,0.906C54.203,140.414,55.704,141.072,57.211,141.072z
		 M147.955,138.027h-0.229c-1.505,0.678-2.971,1.598-3.312,3.314c-0.387,1.973,0.914,4.064,0.119,6.045
		c2.988-0.52,4.521-3.139,3.99-6.166C148.342,140.178,147.664,139.174,147.955,138.027z M26.155,142.814
		c0.491-0.068,0.97-0.141,1.413-0.141c0.335,0,0.65,0.041,0.936,0.15c-0.223-1.996-1.197-3.451-2.856-3.994
		c-0.46-0.152-0.963-0.199-1.484-0.199c-0.37,0-0.75,0.023-1.131,0.047c-0.381,0.021-0.765,0.045-1.143,0.045
		c-0.767,0-1.512-0.094-2.169-0.469v0.344c0.412,2.521,2.109,4.123,4.56,4.342c0.128,0.01,0.257,0.016,0.387,0.016
		C25.161,142.955,25.665,142.885,26.155,142.814z M40.585,142.281c0.762,0,1.575-0.57,1.491-1.516
		c-0.046-0.586-0.412-1.141-1.175-1.141c-0.191,0-0.407,0.035-0.649,0.113c-0.684,0.223-0.923,0.602-0.912,1.256
		C39.359,141.891,39.955,142.281,40.585,142.281z M110.993,139.965c-0.325-0.068-0.624-0.102-0.896-0.102
		c-2.751,0-2.912,3.281-4.011,5.129c0.318,0.061,0.612,0.088,0.887,0.088C109.719,145.08,110.36,142.285,110.993,139.965z
		 M61.79,143.574c0.264,0,0.54-0.021,0.827-0.066c1.161-0.182,1.893-1.379,2.979-1.379c0.032,0,0.065,0.002,0.098,0.004
		c-0.477-1.236-1.383-1.658-2.426-1.658c-1.479,0-3.231,0.85-4.414,1.432C59.407,142.992,60.439,143.574,61.79,143.574z
		 M129.699,140.877c-1.456,0.545-0.701,2.547,0.627,2.547c0.092,0,0.188-0.01,0.284-0.029c1.196-0.248,1.213-2.645-0.267-2.645
		C130.154,140.75,129.938,140.789,129.699,140.877z M100.836,146.473c0.463,0.15,0.889,0.217,1.279,0.217
		c2.516,0,3.621-2.789,4.086-4.67c-0.393-0.092-0.744-0.135-1.062-0.135C102.444,141.885,102.14,144.92,100.836,146.473z
		 M63.642,143.965c0.565,1.051,1.457,1.447,2.422,1.447c1.704,0,3.64-1.229,4.429-2.252c-0.646-0.783-1.355-1.068-2.091-1.068
		C66.795,142.092,65.069,143.455,63.642,143.965z M101.064,143.508c-0.459-0.143-0.871-0.207-1.243-0.207
		c-2.444,0-3.176,2.771-4.349,4.314c0.514,0.201,0.986,0.291,1.418,0.291C99.17,147.906,100.373,145.412,101.064,143.508z
		 M143.39,143.395c-2.417,0.312-3.825,1.584-4.103,3.764c-0.217,1.672,0.465,3.924-0.23,5.139c3.119-0.359,4.822-2.818,4.446-6.047
		C143.395,145.301,142.818,144.369,143.39,143.395z M74.369,145.225c0.382-0.189,0.816-0.332,1.026-0.689
		c-0.636-0.734-1.35-1.012-2.083-1.012c-1.728,0-3.566,1.537-4.762,2.266c0.532,0.754,1.544,1.098,2.585,1.098
		C72.512,146.887,73.94,146.283,74.369,145.225z M33.122,147.537c0.246,0,0.495,0.023,0.746,0.078
		c-0.438-1.928-1.299-2.988-2.735-3.537c-0.446-0.17-0.928-0.229-1.431-0.229c-0.598,0-1.223,0.084-1.848,0.168
		c-0.624,0.084-1.247,0.168-1.839,0.168c-0.491,0-0.96-0.057-1.392-0.221c0.547,2.492,2.075,3.855,4.458,4.109
		c0.139,0.016,0.276,0.023,0.413,0.023c0.621,0,1.224-0.141,1.823-0.281C31.917,147.678,32.514,147.537,33.122,147.537z
		 M96.043,144.535c-0.512-0.184-0.975-0.264-1.394-0.264c-2.454,0.002-3.45,2.762-4.425,4.029c0.539,0.223,1.037,0.32,1.498,0.32
		C94.057,148.621,95.43,146.115,96.043,144.535z M45.619,144.877v0.912c0.354,0.328,0.646,0.457,0.877,0.457
		c0.435,0,0.653-0.459,0.653-0.916s-0.216-0.916-0.65-0.916C46.269,144.414,45.975,144.545,45.619,144.877z M75.735,148.074
		c0.161,0.02,0.316,0.029,0.466,0.029c2.1,0,3.149-1.789,4.436-2.656c-0.614-0.732-1.282-1.016-1.959-1.016
		c-1.746,0-3.556,1.873-4.652,2.727C74.459,147.715,75.052,147.99,75.735,148.074z M90.907,145.225
		c-0.61-0.318-1.154-0.453-1.646-0.453c-2.105,0-3.256,2.469-4.623,3.529c0.656,0.408,1.284,0.58,1.874,0.58
		C88.572,148.881,90.172,146.771,90.907,145.225z M85.778,145.561c-0.66-0.508-1.277-0.713-1.857-0.713
		c-1.882,0-3.368,2.148-4.644,2.996c0.659,0.664,1.341,0.928,2.012,0.928C83.161,148.771,84.93,146.709,85.778,145.561z
		 M124.971,146.732c0.4,0,0.717-0.281,0.624-1.057c-0.05-0.418-0.38-0.727-0.831-0.727c-0.098,0-0.201,0.014-0.307,0.043
		C122.869,145.443,124.111,146.732,124.971,146.732z M50.856,148.355c-0.016,0.355,0.175,0.713,0.626,0.713
		c0.118,0,0.253-0.023,0.408-0.078c0.256-0.16,0.335-0.863,0.112-1.146c-0.201-0.143-0.38-0.203-0.533-0.203
		C51.094,147.641,50.872,147.998,50.856,148.355z M133.58,152.07c-0.191,1.543,0.177,3.205-0.684,4.453
		c2.759-0.068,4.684-1.801,5.021-4.34c0.176-1.352-0.354-2.801,0.227-4.109C135.592,148.207,133.883,149.674,133.58,152.07z
		 M119.355,149.793c0.023,0,0.049-0.002,0.073-0.006c0.687-0.082,0.728-1.449-0.017-1.449c-0.096,0-0.204,0.023-0.327,0.074
		C118.295,148.75,118.691,149.793,119.355,149.793z M39.452,152.297c-0.421-1.59-1.435-2.76-2.849-3.193
		c-0.36-0.111-0.726-0.154-1.096-0.154c-0.742,0-1.499,0.176-2.262,0.354s-1.533,0.355-2.299,0.355
		c-0.319,0-0.636-0.031-0.953-0.105c0.513,2.279,2.457,3.664,4.922,3.664c0.106,0,0.212-0.002,0.32-0.008
		c1.352-0.066,2.428-0.945,3.765-0.945C39.147,152.264,39.298,152.273,39.452,152.297z M68.861,150.77
		c-0.438,0-0.787,0.252-1.163,0.502c-0.376,0.252-0.779,0.502-1.323,0.502c-0.236,0-0.498-0.047-0.796-0.16
		c-0.171,0.324-0.24,0.752-0.342,1.146c0.451,0.234,1.083,0.279,1.488,0.562c-0.371,1.578-0.718,3.168-1.375,4.457
		c-0.081,0.01-0.157,0.016-0.229,0.016c-0.256,0-0.454-0.061-0.652-0.123s-0.399-0.123-0.66-0.123h-0.055
		c0,0.461-0.227,0.688-0.227,1.143c1.789,0.492,3.407,1.158,5.363,1.49c-0.03-0.484,0.302-0.617,0.227-1.146
		c-0.514-0.211-1.237-0.207-1.482-0.688c0.643-2.475,1.495-4.742,2.053-7.305C69.374,150.848,69.104,150.77,68.861,150.77z
		 M101.635,159.834c0.394,0.201,0.863,0.291,1.354,0.291c1.438,0,3.047-0.783,3.434-1.889c0.695-1.951-0.221-3.447-1.71-4.221
		c-0.224-0.045-0.438-0.064-0.644-0.064c-1.084,0-1.893,0.533-2.434,0.635c-0.379-1.236,0.079-2.521,1.336-2.521
		c0.047,0,0.096,0.002,0.146,0.006c-0.39,0.9,0.342,1.467,1.081,1.467c0.365,0,0.734-0.137,0.971-0.439
		c0.383-1.732-0.571-2.334-1.77-2.334c-1.288,0-2.857,0.693-3.36,1.42C98.688,154.139,99.783,158.891,101.635,159.834z
		 M103.156,155.363c0.061,0,0.123,0.004,0.188,0.014c0.891,0.137,1.723,2.676,0.57,3.428c-0.127,0.039-0.246,0.057-0.359,0.057
		c-0.793,0-1.297-0.873-1.4-1.75C102.051,156.236,102.348,155.363,103.156,155.363z M81.094,155.725
		c0.861-2.15-1.189-3.383-3.169-3.383c-1.792,0-3.528,1.012-2.986,3.27c0.131,0.549,0.432,0.639,0.683,1.256
		c-0.479,0.461-1.048,0.625-1.253,1.143c-0.952,2.352,1.192,3.664,3.3,3.664c1.91,0,3.791-1.078,3.316-3.438
		c-0.138-0.68-0.575-0.732-1.025-1.6C80.616,156.354,80.934,156.137,81.094,155.725z M79.042,159.834
		c-0.143,0.432-0.69,0.65-1.291,0.65c-0.885,0-1.889-0.477-1.902-1.449c-0.005-0.34,0.045-1.01,0.797-1.256
		c0.059-0.02,0.131-0.029,0.213-0.029C77.668,157.75,79.436,158.662,79.042,159.834z M78.838,155.814
		c-1.267,0-3.249-1.514-1.278-2.141c0.205-0.066,0.423-0.098,0.639-0.098c0.646,0,1.268,0.283,1.414,0.775
		C79.935,155.43,79.478,155.814,78.838,155.814z M128.104,154.586c-0.982,1.654-0.354,4.238-1.596,5.822
		c0.057,0.002,0.113,0.004,0.17,0.004c3.09,0,5.092-1.832,5.415-4.801c0.159-1.373-0.039-2.572,0.348-3.201
		C130.32,152.502,128.924,153.188,128.104,154.586z M88.661,159.082c-0.595,0-1.243,0.406-1.285,1.1
		c-0.067,1.033,1.288,1.844,2.744,1.844c0.536,0,1.087-0.111,1.585-0.361c3.078-1.553,2.531-8.516-0.798-9.02
		c-0.19-0.029-0.38-0.043-0.567-0.043c-2.539,0-4.616,2.598-2.964,5.064c0.466,0.697,1.241,1.006,2.32,1.006
		c0.522,0,1.117-0.072,1.782-0.207c-0.107,1.209-0.345,2.295-1.666,2.295c-0.086,0-0.176-0.006-0.271-0.014
		C89.912,159.582,89.318,159.082,88.661,159.082z M90.338,157.436c-0.012,0-0.023,0.002-0.035,0.002
		c-0.718,0-1.175-0.875-1.227-1.75c-0.049-0.875,0.308-1.75,1.219-1.75c0.151,0,0.317,0.025,0.499,0.078
		C91.638,154.824,91.667,157.385,90.338,157.436z M44.767,156.059c0.082,0,0.169,0.004,0.255,0.008
		c0.087,0.004,0.174,0.008,0.259,0.008c0.079,0,0.155-0.004,0.227-0.012c-0.583-1.625-1.854-2.748-3.541-2.965
		c-0.165-0.02-0.328-0.031-0.49-0.031c-0.913,0-1.795,0.307-2.661,0.609c-0.865,0.305-1.715,0.607-2.566,0.607
		c-0.186,0-0.371-0.014-0.557-0.045c0.381,2.025,2.626,3.037,4.882,3.037c1.356,0,2.717-0.367,3.679-1.098
		C44.364,156.082,44.554,156.059,44.767,156.059z M125.623,156.234c-1.402,0-2.412,0.516-3.226,1.314
		c-1.203,1.184-0.997,3.133-1.595,4.688c-0.229,0.586-0.84,0.961-0.684,1.48c0.337,0.062,0.654,0.09,0.953,0.09
		c1.338,0,2.289-0.566,2.926-1.115c1.619-1.416,1.784-4.238,2.51-6.395C126.196,156.254,125.902,156.234,125.623,156.234z
		 M51.373,159.482c0.094,0,0.19,0.002,0.289,0.008c-0.502-1.631-1.961-2.572-3.533-2.736c-0.143-0.016-0.283-0.023-0.421-0.023
		c-1.007,0-1.867,0.383-2.726,0.764s-1.718,0.764-2.724,0.764c-0.133,0-0.268-0.008-0.406-0.021
		c0.414,1.906,2.466,2.777,4.559,2.777c1.575,0,3.172-0.494,4.112-1.41C50.756,159.516,51.048,159.482,51.373,159.482z
		 M119.021,159.146c-4.008,0-4.192,4.537-5.75,7.195c0.391,0.053,0.752,0.076,1.09,0.076c1.691,0,2.764-0.623,3.586-1.445
		c1.402-1.404,1.419-3.984,2.285-5.705C119.793,159.186,119.391,159.146,119.021,159.146z M58.25,162.006h0.034
		c-0.834-1.561-2.267-2.297-3.902-2.297c-0.107,0-0.214,0.002-0.322,0.008c-2.16,0.133-3.365,2.176-5.482,2.176
		c-0.073,0-0.147-0.002-0.222-0.008c0.654,1.5,2.308,2.439,4.231,2.439c0.22,0,0.444-0.012,0.671-0.037
		c1.692-0.189,2.928-1.443,4.001-2.051C57.552,162.123,57.841,162.006,58.25,162.006z M112.145,161.658
		c-3.706,0-4.281,4.373-5.943,6.736c0.528,0.131,1.027,0.189,1.496,0.189c1.674,0,2.967-0.764,3.867-1.781
		c1.207-1.381,1.059-3.502,2.163-4.918C113.143,161.729,112.618,161.658,112.145,161.658z M65.01,164.061
		c-0.828-1-1.915-1.713-3.307-1.824c-0.119-0.012-0.235-0.016-0.35-0.016c-2.581,0-3.894,2.457-6.267,2.637
		c0.726,1.359,2.166,2.115,3.947,2.115c0.274,0,0.557-0.02,0.847-0.055c1.845-0.242,3.134-1.771,4.216-2.516
		C64.394,164.283,64.862,164.326,65.01,164.061z M105.106,163.717c-3.438,0-4.482,3.963-6.208,6.053
		c0.639,0.293,1.291,0.416,1.924,0.416c1.241,0,2.4-0.48,3.207-1.102c1.253-0.971,1.424-2.396,2.057-3.654
		c0.279-0.566,0.896-0.768,0.912-1.369C106.293,163.822,105.668,163.717,105.106,163.717z M68.663,164.174
		c-0.142-0.012-0.279-0.018-0.413-0.018c-2.819,0-3.919,2.695-6.318,2.984c0.568,1.375,2.234,1.945,3.662,1.945
		c0.034,0,0.067-0.002,0.101-0.002c2.848-0.068,3.988-2.451,5.938-3.199C71.041,164.67,69.75,164.271,68.663,164.174z
		 M100.038,165.43c-0.67-0.305-1.396-0.439-2.095-0.439c-1.041,0-2.025,0.299-2.697,0.779c-1.113,0.793-1.477,2.441-2.4,3.537
		c-0.439,0.535-1.002,0.65-1.254,1.26c0.82,0.371,1.624,0.539,2.385,0.539c1.742,0,3.262-0.885,4.238-2.25
		c0.525-0.742,0.594-1.756,1.027-2.4C99.49,166.084,100.197,165.924,100.038,165.43z M77.674,167.254
		c0.37-0.127,0.684-0.311,1.026-0.451c-0.522-1.107-2.093-1.496-3.288-1.496c-0.085,0-0.167,0.004-0.248,0.006
		c-2.803,0.145-3.732,3.111-6.046,3.543c0.793,1.113,1.952,1.559,3.167,1.559C74.542,170.414,76.993,168.883,77.674,167.254z
		 M93.08,166.455c-0.575-0.404-1.119-0.617-1.83-0.686c-0.189-0.018-0.373-0.025-0.549-0.025c-3.517,0-4.374,3.449-6.518,4.938
		c0.824,0.578,1.729,0.816,2.615,0.816c1.522,0,2.987-0.707,3.881-1.617c0.685-0.701,0.867-1.777,1.482-2.514
		C92.437,167.047,93.184,166.939,93.08,166.455z M85.892,166.918c-0.764-0.703-1.316-0.943-2.278-1.033
		c-0.175-0.016-0.343-0.023-0.506-0.023c-3.303,0-4.282,3.186-6.688,4.25c0.791,0.789,1.969,1.154,3.15,1.154
		c0.13,0,0.26-0.004,0.39-0.012c2.543-0.186,4.046-2.191,5.021-3.773C85.334,167.346,85.688,167.201,85.892,166.918z
		 M133.011,80.144c-0.28-5.921-2.185-11.263-4.11-15.646c-0.53-1.213-1.641-3.16-1.596-3.882c0.047-0.73,0.936-1.708,1.369-2.511
		c0.545-1.021,0.944-1.73,1.252-2.626c-1.822,0.382-3.031,1.38-4.673,1.939c-4.433-7.229-12.231-13.35-20.541-17.354
		c-2.931-1.408-6.28-2.771-9.581-3.083c-0.312-0.028-0.611-0.044-0.896-0.044c-3.881,0-5.272,2.671-4.922,6.895
		c-0.703-0.16-1.467-0.232-2.232-0.232c-1.446,0-2.893,0.26-3.924,0.689c2.01-4.278,0.093-7.481-4.478-7.481
		c-0.356,0-0.73,0.02-1.118,0.06c-3.078,0.316-6.451,1.669-9.354,2.97c-8.411,3.775-15.946,10.05-20.536,16.556
		c-1.434-0.733-2.814-1.521-4.449-2.054c0.701,1.851,1.84,3.261,2.626,5.022c-2.394,4.666-5.768,11.143-6.396,18.042
		c-0.17,1.879-0.545,6.137,1.254,6.165h0.021c1.829,0,2.228-3.923,2.955-4.911c2.623-0.756,2.76-4.01,3.878-6.28
		c0.08,0.015,0.151,0.021,0.217,0.021c0.311,0,0.498-0.13,0.808-0.134c0.662-0.57,1.053-1.335,1.481-2.171
		c0.383-0.735,0.604-1.954,1.596-2.053c-0.809,4.703-2.553,9.303-3.991,14.157c-1.401,4.735-3.374,9.562-3.766,14.72
		c-0.074,0.994-0.074,2.115,0,3.086c0.609,7.912,5.546,13.748,12.208,15.988c-0.239-0.021-0.558-0.125-0.84-0.125
		c-0.155,0-0.3,0.031-0.413,0.125c0,1.562,0.644,2.475,1.481,3.195c0.951-0.646,2.263-0.93,2.171-2.625
		c-0.251-0.365-0.979-0.246-1.374-0.457c0.08-0.002,0.163-0.004,0.249-0.004c0.204,0,0.422,0.006,0.648,0.014
		c0.226,0.006,0.459,0.012,0.691,0.012c1.027,0,2.034-0.127,2.292-0.934c-0.404,0.037-0.798,0.055-1.186,0.055
		c-10.183,0-14.54-12.506-11.705-22.209c1.173,0.611,2.251,1.324,3.078,2.289c-1.385,5.885,0.308,12.404,2.742,15.525
		c-0.44-0.258-1-0.412-1.489-0.799c-0.506-0.408-0.927-1.285-1.351-1.285c-0.044,0-0.088,0.01-0.132,0.029
		c0.48,3.213,4.703,3.285,6.504,4.791c-1.596-0.074-2.925-1.027-4.223-1.027c0.814,1.246,3.639,2.32,6.154,2.32
		c0.825,0,1.616-0.117,2.292-0.379c0.564-0.219,1.583-1.146,1.482-1.482c-0.238,0.141-0.582,0.215-0.924,0.215
		c-0.332,0-0.665-0.07-0.9-0.215c0.674-0.234,1.813-0.012,1.938-0.799c-0.197,0.01-0.392,0.014-0.582,0.014
		c-7.572,0-9.624-7.402-7.974-14.4c1.032,0.564,1.956,1.238,2.85,1.941c-0.364,5.32,1.728,8.777,3.883,11.186
		c-2.338-0.744-3.416-2.752-5.136-4.109c0.288,2.312,3.942,5.195,6.831,5.195c1.172,0,2.219-0.475,2.864-1.654
		c-2.759-2.6-5.649-5.943-5.245-11.189c0.257-3.336,1.584-6.588,1.938-9.822c-0.05-0.008-0.095-0.014-0.136-0.014
		c-0.573,0-0.291,0.846-0.547,1.154c-0.212-0.258-0.441-0.377-0.685-0.377c-0.215,0-0.443,0.092-0.684,0.264
		c0.142-0.713-0.363-0.77-0.456-1.25h0.018c0.603,0,0.862-0.34,1.122-0.688c0.392,0.307,0.708,0.689,1.408,0.689
		c0.024,0,0.048,0,0.073-0.002c0.092-0.992,0.849-2.245,0-2.853c-0.069-0.023-0.13-0.033-0.185-0.033
		c-0.157,0-0.256,0.084-0.339,0.168c-0.082,0.085-0.146,0.169-0.233,0.169c-0.044,0-0.093-0.022-0.154-0.075
		c0.262-0.723-0.48-1.039-0.342-1.256c0.536-0.071,0.833-0.385,1.139-0.687c0.21,0.177,0.184,0.582,0.564,0.582
		c0.036,0,0.077-0.004,0.12-0.012c0.085-0.842-0.126-1.392-0.113-2.164c-0.024-0.01-0.049-0.014-0.073-0.014
		c-0.086,0-0.168,0.053-0.239,0.106c-0.071,0.054-0.133,0.107-0.182,0.107c-0.033,0-0.058-0.025-0.078-0.09
		c0.23-0.833,0.206-1.141-0.34-1.597c0.041,0.004,0.08,0.006,0.116,0.006c0.229,0,0.37-0.091,0.338-0.349
		c-0.261-0.995-0.67-1.84-1.139-2.63c-0.013-0.001-0.027-0.002-0.041-0.002c-0.16,0-0.314,0.097-0.449,0.193
		c-0.136,0.097-0.253,0.193-0.341,0.193c-0.031,0-0.058-0.011-0.081-0.038c0.172-0.782-0.268-0.953-0.569-1.259
		c0.213-0.056,0.303-0.233,0.576-0.233c0.034,0,0.07,0.003,0.11,0.009c-0.287-0.963-1.239-1.778-2.18-1.778
		c-0.149,0-0.297,0.021-0.444,0.064c0.399-0.757-0.399-1.064-0.343-1.372c2.122-0.479,0.526-1.657-0.347-2.51
		c-1.584-1.562-3.435-3.765-3.989-5.251c2.319,1.788,4.006,4.213,6.502,5.82c0.309-0.584,0.256-0.841,0.684-1.254
		c-1.904-1.975-3.972-3.791-5.932-5.71c-0.034-0.009-0.064-0.013-0.091-0.013c-0.123,0-0.176,0.083-0.215,0.167
		c-0.039,0.083-0.063,0.167-0.124,0.167c-0.034,0-0.08-0.026-0.146-0.093c-0.24-0.141-0.422-0.331-0.569-0.57
		c0.24-0.267,0.416-0.58,0.69-0.799c2.371,1.891,4.439,4.683,6.844,6.163c0.548-0.739-0.403-1.215-0.796-1.598
		c-1.495-1.437-3.129-2.949-4.452-4.34c0.485-0.508,0.894-1.078,1.373-1.598c0.095,0.101,0.27,0.121,0.467,0.121
		c0.07,0,0.143-0.003,0.216-0.005c0.073-0.003,0.146-0.005,0.217-0.005c0.044,0,0.086,0,0.127,0.003
		c0.729-0.448,1.007-1.347,1.708-1.828c0.177,0.073,0.336,0.104,0.481,0.104c0.776,0,1.148-0.901,1.685-1.246
		c0.27,0.224,0.517,0.314,0.747,0.314c0.84,0,1.459-1.2,2.104-1.572c0.236,0.115,0.455,0.164,0.661,0.164
		c0.933,0,1.583-1.012,2.195-1.532c0.112,0.453,0.603,0.685,1.115,0.685c0.291,0,0.588-0.075,0.828-0.226
		c0.103,1.913,0.729,3.303,2.507,3.54c0.045-0.719-0.159-1.684,0.342-1.943c2.234,0.694,4.047,1.812,5.928,2.855
		c0.049,0.75,0.545,1.047,0.576,1.828c-0.224,0.261-0.563,0.343-0.941,0.343c-0.557,0-1.199-0.178-1.688-0.229
		c-0.608,0.188-0.534,1.055-1.025,1.369c-0.344,0.05-0.664,0.073-0.963,0.073c-2.421,0-3.508-1.523-5.192-2.473
		c-0.006-0.302,0.034-0.653-0.12-0.799c-0.884-0.287-1.875-0.484-2.856-0.684c0.411,1.004,1.044,1.773,1.824,2.398
		c0.195-0.072,0.388-0.104,0.577-0.104c1.061,0,2.009,1.012,2.74,1.588c-0.161,0.333-0.412,0.579-0.799,0.685
		c-1.903-0.95-3.931-1.777-5.823-2.738c0-0.305,0.045-0.653-0.116-0.802c-0.825-0.016-1.753-0.3-2.563-0.3
		c-0.219,0-0.429,0.021-0.627,0.072c0.765,0.756,1.368,1.674,2.394,2.17c0.239-0.103,0.341-0.346,0.68-0.346h0.005
		c1.731,0.936,3.778,1.549,5.362,2.627c-0.536,0.251-0.928,0.971-1.709,1.03c-0.015,0.001-0.033,0.001-0.05,0.001
		c-0.732,0-3.693-0.928-3.603-1.599c-0.896-0.06-1.78-0.129-2.732-0.129c-0.225,0-0.454,0.004-0.688,0.013
		c0.6,0.887,1.722,1.243,2.507,1.941C64.167,68.075,64.503,68,64.853,68c0.758,0,1.578,0.353,2.213,0.61
		c-1.024,0.575-2.392,0.805-3.988,0.805c-0.152,0-0.306-0.003-0.461-0.007c-1.197,1.614-2.156,3.477-3.079,5.368
		c2.621,2.857,5.038,6.058,4.446,11.872c-0.518,5.175-2.953,9.818-2.28,14.953c0.333,2.549,1.482,4.939,2.736,6.395
		c2.713,3.137,6.948,4.061,11.182,5.48c4.11,1.381,7.466,3.188,10.043,6.627c2.462-3.105,5.796-4.967,9.922-6.285
		c4.259-1.354,8.579-2.176,11.411-4.904c2.082-2.008,3.539-5.566,3.426-9.137c-0.143-4.188-2.07-8.717-2.172-13.356
		c-0.107-4.954,2.285-8.474,5.02-11.188c-0.99-1.672-1.925-3.405-2.848-5.14c-0.23,0.019-0.473,0.028-0.722,0.028
		c-1.362,0-2.93-0.271-3.85-0.826c0.601-0.192,1.329-0.496,1.976-0.496c0.371,0,0.717,0.101,0.994,0.382
		c0.746-0.658,1.943-0.87,2.514-1.711c-0.946-0.203-2.088-0.206-3.084-0.343c-0.531,1.335-2.656,1.072-3.996,1.595
		c-0.533-0.414-1.23-0.668-1.597-1.252c2.022-0.598,3.9-2.206,6.07-2.206c0.181,0,0.364,0.012,0.549,0.035
		c0.757-0.656,1.64-1.172,2.172-2.057c-0.896,0.212-2.218-0.008-3.085,0.229c-0.188,0.154-0.039,0.648-0.229,0.802
		c-2.102,0.823-4.097,1.763-6.16,2.626c-0.354-0.256-0.656-0.562-0.912-0.912c0.892-0.334,1.622-1.291,2.623-1.291
		c0.214,0,0.439,0.044,0.684,0.146c0.684-0.847,1.572-1.471,2.051-2.512c-0.941,0.233-1.835,0.517-2.85,0.685
		c-0.273,1.194-1.31,1.346-2.395,1.939c-1.049,0.58-1.509,1.122-2.518,1.122c-0.23,0-0.488-0.028-0.788-0.09
		c0.081-0.576-0.067-0.924-0.228-1.258c-0.176-0.097-0.365-0.131-0.558-0.131c-0.231,0-0.468,0.049-0.695,0.099
		c-0.229,0.05-0.446,0.1-0.639,0.1c-0.267,0-0.486-0.095-0.622-0.413c0.975-2.406,3.283-3.484,4.338-5.82
		c0.013,0,0.025,0.001,0.037,0.001c0.793,0,1.065-0.507,1.559-0.8c0.382,0.264,0.686,0.614,1.256,0.688
		c0.438-0.135,0.596-0.549,1.023-0.688c0.53,0.545,0.98,1.341,1.824,1.483c0.027,0.004,0.055,0.006,0.082,0.006
		c0.146,0,0.293-0.059,0.441-0.117c0.146-0.059,0.297-0.117,0.451-0.117c0.02,0,0.039,0.001,0.059,0.003
		c0.55,0.053,1.148,0.759,1.76,0.759c0.211,0,0.422-0.083,0.633-0.306c0.5,0.626,1.189,1.7,2.183,1.7
		c0.244,0,0.505-0.064,0.788-0.217c0.742,0.815,1.291,2.256,2.693,2.256c0.188,0,0.391-0.026,0.611-0.083
		c0.582,0.827,1.254,1.561,1.938,2.282c-1.261,1.387-2.852,2.681-4.218,3.882c-0.412,0.358-1.266,0.858-0.914,1.597
		c0.721-0.08,1.283-0.772,1.826-1.258c1.754-1.563,3.437-3.3,5.248-4.68c-0.057,0.627,0.906,0.716,0.57,1.257
		c-0.088,0.083-0.169,0.113-0.249,0.113c-0.214,0-0.41-0.221-0.669-0.227c-0.775,0.719-1.709,1.723-2.965,2.854
		c-1.02,0.922-4.394,2.758-2.278,4.112c1.961-1.808,3.853-3.678,5.926-5.369c-1.514,2.516-3.771,4.289-5.583,6.511
		c0.215,0.425,0.354,0.938,0.797,1.143c-0.523,0.126-0.571,0.728-0.684,1.253c-0.146-0.102-0.291-0.146-0.435-0.146
		c-0.792,0-1.527,1.367-1.845,2.202c0.102,0.349,0.627,0.28,0.797,0.57c-0.637,0.046-0.417,0.956-0.797,1.255
		c-0.318-0.025-0.22-0.469-0.565-0.469c-0.036,0-0.077,0.005-0.125,0.016c-0.69,0.368-0.781,1.346-1.14,2.054
		c0.643,1.046-0.6,1.779-0.457,3.312c0.009-0.001,0.02-0.001,0.028-0.001c0.538,0,0.767,0.867,1.46,1.027
		c-0.438,0.17-0.558,0.657-0.684,1.137c-0.023,0.004-0.044,0.004-0.064,0.004c-0.125,0-0.184-0.062-0.242-0.127
		s-0.117-0.129-0.241-0.129c-0.04,0-0.087,0.006-0.142,0.023c-0.017,0.932,0.005,1.818,0.342,2.402
		c0.047,0.01,0.09,0.016,0.126,0.016c0.39,0,0.214-0.557,0.558-0.588c0.153,0.496,0.639,0.65,1.146,0.799
		c-0.422,0.223-0.422,0.873-0.57,1.369c-0.297,0-0.438-0.352-0.667-0.352c-0.07,0-0.149,0.035-0.245,0.125
		c-0.354,0.602,0.045,1.854,0.336,2.514c0.14,0.031,0.263,0.045,0.372,0.045c0.619,0,0.776-0.459,1.114-0.734
		c0.207,0.518,0.52,0.924,1.025,1.141c-0.414,0.232-0.607,0.684-0.682,1.262c-0.197-0.273-0.424-0.395-0.668-0.395
		c-0.223,0-0.461,0.1-0.706,0.273c-0.017-0.543,0.136-1.254-0.427-1.254h-0.029c0.371,2.93,1.111,4.957,0.918,7.535
		c-0.252,3.244-1.928,5.85-3.653,7.646c-0.52,0.537-1.894,1.416-1.943,1.828c-0.068,0.631,0.753,1.014,1.032,1.377
		c4.996-1.658,7.402-5.914,7.528-12.449c1.003-0.434,1.562-1.336,2.622-1.713c1.015,4.443-0.229,9.281-2.052,11.99
		c-1.375,0.758-3.136,1.639-4.788,1.934c-0.158,0.031-0.336,0.033-0.519,0.033h-0.136c-0.454,0-0.919,0.02-1.176,0.428
		c0.42,0.207,1,0.271,1.627,0.271c0.927,0,1.955-0.141,2.711-0.156c-0.691,0.773-1.639,1.279-3.094,1.279
		c-0.18,0-0.365-0.008-0.561-0.023c0.395,1.613,2.426,2.439,4.496,2.439c2.086,0,4.211-0.838,4.744-2.555
		c-1.023,0.473-2.403,1.729-4.24,1.729c-0.321,0-0.656-0.039-1.006-0.127c2.898-2.121,7.867-2.172,8.784-6.285
		c-1.35,0.367-2.559,2.467-3.995,2.744c2.524-3.65,3.729-10.312,2.4-16.102c0.905-0.799,1.84-1.574,2.963-2.162
		c2.8,10.525-0.257,22.986-11.062,23.404c-0.159,0.568,0.57,0.621,0.91,0.912c0.707,0.605,1.266,1.723,2.281,1.945
		c0.2-0.492,0.085-1.291,0.113-1.945c-0.068-0.1-0.211-0.125-0.377-0.125c-0.08,0-0.165,0.006-0.25,0.012
		c-0.086,0.006-0.172,0.012-0.252,0.012c-0.166,0-0.309-0.025-0.373-0.127c9.529-2.055,13.541-10.506,12.891-21.812
		c-0.568-9.883-4.98-17.613-5.592-26.827c1.871,1.215,1.592,4.577,4.339,4.913c0.815,2.303,1.026,5.211,3.534,5.822
		c0.547,1.563,0.619,5.158,2.494,5.158c0.077,0,0.158-0.006,0.24-0.02C133.193,84.719,133.073,81.536,133.011,80.144z
		 M127.875,64.383c0.01,0.07-0.016,0.097-0.056,0.097c-0.097,0-0.277-0.144-0.286-0.207c-0.502,0.333,0.023,1.697-0.457,2.054
		c-1.008-0.248-1.231-1.281-1.709-2.054c0.445-0.733,0.639-1.72,1.367-2.173C127.121,62.856,127.472,63.648,127.875,64.383z
		 M127.533,57.419c0.023-0.005,0.047-0.008,0.064-0.008c0.135,0,0.152,0.114,0.163,0.238c-1.3,2.196-2.423,4.578-3.763,6.733
		c-0.585-0.367-0.957-0.949-1.365-1.482c1.355-1.683,3.168-2.921,4.443-4.681c-1.795,1.173-3.267,2.667-4.906,3.996
		c-0.598-0.273-0.997-0.747-1.26-1.369C123.109,59.692,125.373,58.608,127.533,57.419z M122.854,64.842
		c-0.268-0.039-0.473-0.136-0.684-0.227v-0.688C122.496,64.133,122.734,64.43,122.854,64.842z M120.86,64.879
		c-0.075,0-0.16,0.007-0.247,0.014c-0.088,0.008-0.18,0.015-0.266,0.015c-0.129,0-0.251-0.015-0.349-0.065
		c-0.172-0.164-0.099-0.587-0.108-0.915c-0.256,0.16-0.041,0.796-0.114,1.145c-0.816-1.05-0.099-1.56,0.651-1.56
		c0.793,0,1.62,0.568,0.715,1.674C121.205,64.932,121.067,64.879,120.86,64.879z M120.803,76.488
		c-0.287-0.106-0.526-0.171-0.746-0.171c-0.254,0-0.482,0.085-0.736,0.285c0.143-0.751-0.217-1.004-0.347-1.483
		c0.081,0.014,0.153,0.019,0.222,0.019c0.467,0,0.644-0.29,0.804-0.589c0.308,0.263,0.456,0.685,1.03,0.685
		C120.921,75.621,120.711,75.903,120.803,76.488z M120.119,87.33c-0.297-0.074-0.568-0.174-0.812-0.174
		c-0.205,0-0.393,0.07-0.557,0.289c-0.295-0.342,0.102-1.369-0.532-1.369c-0.015,0-0.029,0.001-0.044,0.002
		c0.257-0.394,1.096-0.199,1.032-0.915c0.528,0.16,0.572,0.806,1.264,0.806c0.032,0,0.068,0,0.104-0.005
		C120.426,86.509,119.793,86.624,120.119,87.33z M119.085,83.797c-0.226-0.132-0.418-0.184-0.61-0.184
		c-0.232,0-0.465,0.077-0.756,0.184c0.068-0.679-0.063-1.154-0.457-1.369c0.342-0.343,1.038-0.34,1.141-0.915
		c0.447,0.227,0.436,0.916,1.1,0.916c0.014,0,0.027,0,0.041-0.001C119.418,82.908,118.906,83,119.085,83.797z M105.736,49.884
		c-0.021,0.913,0.584,1.638,0.35,2.398c-1.688-0.636-1.807-2.835-2.97-3.995c0.148-0.625-0.012-0.688-0.115-1.258
		c-0.034-0.006-0.067-0.007-0.099-0.007c-0.188,0-0.287,0.088-0.388,0.177c-0.099,0.088-0.198,0.176-0.384,0.176
		c-0.014,0-0.027,0-0.041-0.001c-1.184-0.755-1.768-2.112-3.076-2.743c0.106-0.104,0.26-0.138,0.43-0.138
		c0.32,0,0.699,0.122,0.938,0.138c0.211-0.217-0.479-0.505-0.685-0.685c0.061-0.028,0.125-0.041,0.194-0.041
		c0.402,0,0.947,0.43,1.439,0.859c0.492,0.431,0.933,0.86,1.125,0.86c0.158,0,0.148-0.291-0.135-1.108
		c0.763,0.542,1.834,0.194,2.506,0.916c0.104,1.229,1.117,1.552,1.375,2.623c-0.162,0.075-0.311,0.108-0.445,0.108
		c-0.628,0-1.012-0.69-1.5-0.903c-0.009-0.001-0.018-0.001-0.027-0.001c-0.288,0-0.262,0.324-0.428,0.457
		C104.473,48.413,105.334,48.923,105.736,49.884z M98.058,56.426c-0.075,0-0.144,0.052-0.226,0.104s-0.177,0.105-0.304,0.105
		c-0.034,0-0.071-0.004-0.11-0.013c0.062-0.923,0.535-1.435,0.568-2.398c1.277,0.209,1.267,3.234-0.114,3.312
		c0.007-0.451,0.2-0.714,0.343-1.03C98.156,56.448,98.105,56.426,98.058,56.426z M93.935,65.788c0.182,0,0.362,0.066,0.514,0.199
		c-0.104,0.578-0.496,0.869-0.913,1.141c-0.262-0.162-0.045-0.795-0.113-1.141C93.572,65.854,93.754,65.788,93.935,65.788z
		 M93.643,65.186c0.152-0.111,0.321-0.15,0.468-0.15c0.179,0,0.326,0.058,0.374,0.115c0.047,0.058-0.004,0.115-0.223,0.115
		C94.125,65.266,93.926,65.244,93.643,65.186z M92.328,62.088c-0.287,0-0.577-0.096-0.85-0.328c0.133,0.047,0.256,0.067,0.371,0.067
		c0.41,0,0.721-0.262,0.996-0.523c0.012-0.094-0.162-0.142-0.338-0.142c-0.175,0-0.352,0.048-0.346,0.142
		c-0.148-0.57,0.666-1.074,1.514-1.074c0.264,0,0.529,0.048,0.772,0.158C94.165,61.087,93.254,62.088,92.328,62.088z M91.705,64.842
		c0.07-0.095,0.433-0.255,0.707-0.255c0.176,0,0.314,0.065,0.318,0.255H91.705z M91.364,67.242c-0.342-0.226-0.685-0.462-0.57-1.144
		h0.911C91.787,66.674,91.507,66.882,91.364,67.242z M90.907,63.703c0.251-0.208,0.211-0.707,0.457-0.916
		c0.417-0.023,0.743-0.132,1.179-0.132c0.094,0,0.195,0.006,0.303,0.018C92.498,63.312,91.781,63.583,90.907,63.703z M92.276,58.453
		c0.448-0.462,1.124-0.694,2.021-0.694c0.085,0,0.173,0.002,0.263,0.006c-0.528,0.797-1.07,1.595-2.398,1.598
		C92.186,58.936,93.018,58.655,92.276,58.453z M92.276,53.082c-0.399,0.018-0.509,0.325-0.912,0.343
		c0.097-1.238,0.878-1.791,1.825-2.171c0.237,0.757,0.181,1.503,0,2.284c-0.297,0.049-0.623,0.061-0.8,0.231
		C91.986,53.672,92.379,53.487,92.276,53.082z M91.705,48.173c0.793,0.331,1.539,1.608,1.025,2.51
		c-0.035,0.01-0.063,0.014-0.09,0.014c-0.114,0-0.139-0.089-0.166-0.179s-0.053-0.179-0.169-0.179c-0.009,0-0.019,0-0.029,0.001
		c-0.237,0.228-0.431,0.85-0.754,0.85c-0.049,0-0.103-0.015-0.158-0.048C91.295,49.97,91.512,49.083,91.705,48.173z M94.214,42.349
		h0.099c1.111,0,1.92,0.3,1.965,1.37c-0.407,0.074-0.935,0.201-1.395,0.201c-0.49,0-0.901-0.146-1.006-0.657
		c0.024,0.004,0.047,0.006,0.066,0.006c0.088,0,0.138-0.036,0.188-0.071c0.051-0.035,0.102-0.07,0.188-0.07
		c0.037,0,0.078,0.006,0.13,0.021C94.459,42.794,94.089,42.823,94.214,42.349z M102.09,43.491c-0.175,0.052-0.345,0.074-0.506,0.074
		c-0.868,0-1.547-0.664-2.115-1.102c-0.759-0.578-1.454-1.026-2.279-1.485C99.578,41.271,101.049,41.577,102.09,43.491z
		 M101.178,41.779c1.106,0.04,1.848,1.084,2.623,1.598c0.865,0.57,2.854,0.465,2.166,2.057
		C103.972,44.74,103.036,42.758,101.178,41.779z M98.898,43.719c-1.941-0.141-2.223-1.943-4.311-1.943
		c-0.045,0-0.092,0.002-0.14,0.003c0.468-0.247,0.985-0.364,1.495-0.364C97.313,41.415,98.642,42.26,98.898,43.719z M95.36,48.515
		c-0.674-0.148-1.454-0.727-1.255-1.369c0.393,0.406,0.859,0.733,1.481,0.91C95.73,48.427,95.217,48.148,95.36,48.515z
		 M95.246,50.34c-0.014,0.001-0.026,0.002-0.039,0.002c-0.447,0-0.458-0.436-0.873-0.458c-0.239,0.081-0.061,0.581-0.382,0.581
		c-0.022,0-0.048-0.003-0.075-0.008c-0.45-0.547-0.273-1.705-0.576-2.401C94.316,48.458,95.166,49.015,95.246,50.34z M94.796,53.538
		c-0.016,0.001-0.03,0.001-0.046,0.001c-0.164,0-0.268-0.051-0.354-0.102c-0.087-0.052-0.155-0.103-0.255-0.103
		c-0.068,0-0.152,0.024-0.264,0.09c0.058-1.01,0.144-1.988,0.571-2.624C95.16,51.245,95.262,52.851,94.796,53.538z M95.574,54.108
		h0.013c-0.245,1.617-1.145,2.582-2.507,3.086c0.057-0.59,0.599-0.698,0.678-1.257c-0.043-0.067-0.093-0.093-0.149-0.093
		c-0.085,0-0.185,0.055-0.294,0.11s-0.229,0.11-0.356,0.11c-0.038,0-0.075-0.004-0.112-0.015
		C93.072,54.786,94.455,54.108,95.574,54.108z M94.105,59.701c0.264-0.302,0.747-0.396,0.912-0.792
		c-0.034-0.157-0.284-0.098-0.457-0.121c0.292-1,1.569-1.018,2.395-1.48c0.25,0.577-1.051,2.475-2.388,2.475
		C94.412,59.782,94.258,59.756,94.105,59.701z M95.93,57.08c-0.092-0.399,0.165-0.438,0.113-0.801
		c-0.014-0.001-0.027-0.001-0.041-0.001c-0.189,0-0.289,0.088-0.389,0.176c-0.1,0.089-0.198,0.178-0.385,0.178
		c-0.03,0-0.062-0.002-0.098-0.008c0.314-1.283,1.244-2.561,2.287-2.743C97.537,55.115,96.989,56.643,95.93,57.08z M97.872,53.424
		c-0.334-0.14-0.503-0.24-0.683-0.24c-0.148,0-0.307,0.067-0.57,0.24c-0.092-0.101-0.125-0.26-0.119-0.456
		c-0.033-0.008-0.062-0.012-0.088-0.012c-0.107,0-0.152,0.062-0.197,0.125c-0.044,0.063-0.089,0.126-0.195,0.126
		c-0.025,0-0.055-0.004-0.09-0.012c-0.115-0.913,0.018-1.962,0.233-2.855c0.998,0.141,1.038,1.241,1.14,2.285
		c0.051,0.073,0.097,0.096,0.143,0.096c0.035,0,0.068-0.013,0.107-0.024c0.039-0.013,0.08-0.025,0.129-0.025
		c0.053,0,0.115,0.016,0.19,0.064C97.976,53.073,97.976,53.09,97.872,53.424z M94.214,45.658c-0.098-0.364,0.378-0.156,0.456-0.34
		c-0.092-0.248-0.291-0.39-0.222-0.801c1.157,0.331,1.677,1.292,1.829,2.629c-0.025,0.003-0.052,0.004-0.079,0.004
		C95.416,47.15,94.732,46.226,94.214,45.658z M96.163,44.976c0.786,0.003,0.974,0.619,1.709,0.682
		c-0.21-0.628-0.831-0.841-1.486-1.026c0.209-0.104,0.433-0.15,0.659-0.15c1.194,0,2.521,1.303,2.651,2.665
		C98.256,47.135,96.613,46.356,96.163,44.976z M97.438,47.079c0.41,0,0.607,0.623,1.119,0.866c0.422,0.203,0.969,0.032,1.368,0.343
		c0.844,0.656,1.196,2.167,1.025,3.422c-1.874-0.897-2.126-3.428-3.761-4.563C97.281,47.1,97.361,47.079,97.438,47.079z
		 M100.153,47.717c-0.011-0.208,0.136-0.242,0.114-0.456c0.734,0.229,1.024,0.913,1.988,0.913c0.021,0,0.042,0,0.064-0.001
		c0.58,1.101,1.577,2.37,1.252,3.992C101.556,51.565,101.396,49.094,100.153,47.717z M106.275,48.968
		c0.621,0,0.594,0.647,0.951,0.916c0.336-0.094-0.149-0.285,0-0.57c1.247,1.187,2.205,2.661,2.394,4.911
		c-1.979-0.912-2.617-3.165-3.419-5.254C106.227,48.969,106.252,48.968,106.275,48.968z M106.422,46.345
		c0.252-0.137,0.627-0.14,0.689-0.456c1.441,1.104,2.633,2.464,3.197,4.451C108.155,50.322,107.392,47.934,106.422,46.345z
		 M108.252,45.658c0.118-0.03,0.236-0.043,0.357-0.043c1.28,0,2.669,1.589,3.523,2.441c1.07,1.073,2.144,2.025,2.509,3.197
		c-0.177,0.026-0.345,0.04-0.507,0.04c-1.877,0-2.78-1.722-3.826-3.006C109.534,47.337,108.64,46.529,108.252,45.658z
		 M111.107,50.914c1.604,1.021,2.849,3.004,2.736,5.365c-1.807-0.97-2.941-2.618-3.42-4.908
		C110.594,51.163,111.199,51.39,111.107,50.914z M113.615,52.511c0.32-0.146,0.622-0.21,0.906-0.21c1.414,0,2.381,1.604,3.082,2.72
		c0.812,1.271,1.312,2.746,1.37,4.001C116.112,57.924,115.029,55.052,113.615,52.511z M116.808,52.398
		c0.058,0.039,0.117,0.079,0.161,0.079c0.032,0,0.056-0.022,0.064-0.079c0.054-0.048,0.155-0.101,0.234-0.101
		c0.055,0,0.1,0.027,0.109,0.101c2.234,1.53,3.533,4,4.793,6.511c-0.329,0.142-0.592,0.341-1.055,0.341
		c-0.027,0-0.057-0.001-0.086-0.002C119.211,57.376,118.072,54.822,116.808,52.398z M121.029,62.559
		c-0.02,0.07-0.077,0.102-0.145,0.102c-0.172,0-0.413-0.196-0.311-0.444c0.041-0.011,0.074-0.016,0.104-0.016
		C120.914,62.201,120.833,62.518,121.029,62.559z M90.453,42.122c0.324-2.51,1.633-3.68,4.383-3.68c0.24,0,0.49,0.009,0.751,0.026
		c3.397,0.228,6.47,1.476,9.239,2.854c7.912,3.939,14.979,9.406,19.287,16.67c-0.415,0.117-0.672,0.398-1.141,0.46
		c-1.498-1.743-2.621-3.856-3.999-5.717c0.028-0.08,0.069-0.109,0.118-0.109c0.068,0,0.15,0.057,0.23,0.113
		c0.08,0.058,0.158,0.114,0.219,0.114c0.001,0,0.001,0,0.002,0c-0.525-1.262-1.835-1.74-2.51-2.853
		c-0.256,0.092,0.229,0.698-0.111,0.912c-0.035,0.002-0.07,0.003-0.105,0.003c-0.936,0-1.658-0.707-2.175-1.257
		c-2.099-2.231-3.876-4.504-6.618-6.056c-0.393,0.368,0.325,0.585,0.113,1.142c-1.806-0.653-2.809-2.208-4.678-3.079
		c-1.922-0.899-4.532-1.736-6.979-1.736c-0.223,0-0.445,0.006-0.666,0.021c-1.334,0.085-2.388,0.847-2.625,2.17
		c-0.264,1.5,0.854,2.866,0.688,3.995c-0.107,0.749-0.852,1.071-1.468,1.071c-0.128,0-0.25-0.014-0.36-0.042
		C90.658,46.792,90.201,44.124,90.453,42.122z M89.652,45.433c0.525,0.006,0.743,0.322,0.686,0.912
		c-0.049,0.009-0.099,0.013-0.145,0.013C89.672,46.358,89.381,45.841,89.652,45.433z M89.996,46.916
		c0.725,0.248,1.195,1.299,0.911,2.174c-0.053-0.006-0.105-0.009-0.155-0.009c-0.458,0-0.77,0.226-1.211,0.465
		C89.016,48.741,89.598,47.509,89.996,46.916z M88.285,44.862c0.977,0.199,1.403,2.131,0.686,2.97
		c-0.161-0.065-0.28-0.18-0.343-0.346c-0.226,0.082-0.307,0.306-0.343,0.57C87.34,47.42,87.842,45.684,88.285,44.862z M82.467,48.63
		c-0.009-0.001-0.018-0.001-0.027-0.001c-0.294,0-0.263,0.326-0.429,0.46c-0.496-0.646,0.118-1.862,0.912-1.943
		c0.348,0.622,0.381,2.068-0.337,2.282C82.643,49.058,82.507,48.898,82.467,48.63z M82.125,51.253
		c1.089,0.168,1.333,1.181,1.83,1.942c-0.219,0.028-0.384,0.057-0.52,0.057c-0.203,0-0.344-0.062-0.511-0.284
		c-0.359,0.029-0.405,0.375-0.794,0.375c-0.066,0-0.142-0.01-0.231-0.033C81.795,52.736,81.857,51.787,82.125,51.253z
		 M83.835,51.026c-0.199,0.105-0.21,0.404-0.336,0.57c-1.061-0.603-0.263-2.262,0.228-2.966c0.816,0.056,0.742,1.006,1.254,1.371
		c-0.24,0.445-0.497,0.875-0.797,1.252C84.085,51.157,83.864,51.188,83.835,51.026z M85.208,50.572
		c0.57,0.374,1.003,0.898,1.142,1.711c-0.174,0.116-0.29,0.155-0.395,0.155c-0.171,0-0.314-0.105-0.632-0.155
		c-0.311,0.155-0.235,0.692-0.697,0.692c-0.032,0-0.065-0.002-0.101-0.007C84.327,52.097,84.703,51.094,85.208,50.572z
		 M86.007,50.572c-0.491-1.548,0.491-2.532,1.026-3.426c0.562,0.497,0.934,1.875,0.227,2.399c-0.035,0.009-0.066,0.013-0.092,0.013
		c-0.205,0-0.162-0.244-0.363-0.244C86.541,49.736,86.566,50.447,86.007,50.572z M88.484,49.174c0.391,0,0.685,0.282,0.6,0.941
		c-0.018,0.131-0.414,0.744-0.755,0.744c-0.099,0-0.192-0.051-0.271-0.177c-0.331,0.243-0.52,0.625-0.912,0.802
		C86.701,50.15,87.758,49.174,88.484,49.174z M86.35,44.405c0.529,0.039,1.013,0.124,1.367,0.341
		c0.091,1.023-0.336,1.118-1.026,1.144C86.382,45.624,86.018,44.942,86.35,44.405z M85.437,45.658
		c1.117,0.206,1.111,2.192,0.342,2.744c-0.034,0.01-0.064,0.014-0.089,0.014c-0.116,0-0.144-0.091-0.17-0.18
		c-0.027-0.091-0.054-0.181-0.169-0.181c-0.009,0-0.018,0-0.027,0.001c-0.321,0.029-0.22,0.475-0.563,0.475
		c-0.036,0-0.075-0.006-0.122-0.016C84.331,47.367,85.032,46.455,85.437,45.658z M84.297,45.202c0.593,0.379,0.01,1.432-0.113,1.828
		c-0.232-0.114-0.296-0.177-0.438-0.177c-0.082,0-0.188,0.02-0.365,0.062C83.39,46.051,83.921,45.708,84.297,45.202z M81.993,46.704
		c-0.034,0-0.066-0.004-0.095-0.014c0.096-0.778,0.827-0.924,1.258-1.372C83.532,45.657,82.518,46.704,81.993,46.704z
		 M56.803,48.056c3.885-3.208,8.64-5.892,14.031-7.874c1.835-0.678,5.081-1.781,7.741-1.781c2.636,0.001,4.697,1.086,4.235,4.748
		c-0.142,1.114-0.843,2.093-1.597,3.196c-0.64,0.934-1.292,2.533-2.207,2.533c-0.299,0-0.626-0.17-0.99-0.591
		c0.195-2.535,3.196-3.524,2.507-6.509c-0.229-0.998-1.127-1.825-2.622-1.941c-0.299-0.023-0.598-0.035-0.896-0.035
		c-4.778,0-9.452,2.877-12.682,4.373c-0.016,0-0.03,0.001-0.044,0.001c-0.426,0,0.144-0.301,0.044-0.572
		c-2.941,2.008-5.509,5.367-9.465,6.736C54.933,49.363,55.983,48.735,56.803,48.056z M76.99,52.283
		c0.038-1.267,0.471-2.671,1.461-2.671c0.241,0,0.516,0.084,0.826,0.272C78.848,51.016,78.141,51.875,76.99,52.283z M78.701,52.968
		c-0.23,0.099-0.537,0.119-0.865,0.119c-0.128,0-0.259-0.003-0.39-0.006c0.308-0.299,0.672-0.551,1.025-0.799
		C78.769,52.294,78.701,52.665,78.701,52.968z M51.775,58.22c0.558-3.022,1.927-5.227,3.654-7.078
		c0.038-0.008,0.071-0.011,0.101-0.011c0.16,0,0.225,0.093,0.355,0.122c0.512-0.358,0.86-0.887,1.374-1.252
		c0.02-0.003,0.039-0.005,0.056-0.005c0.092,0,0.147,0.045,0.166,0.119c-1.681,2.688-3.152,5.599-4.338,8.793
		C52.63,58.743,52.168,58.511,51.775,58.22z M52.459,61.76c-0.889,0.1-1.288,0.684-1.482,1.482
		c-0.067-0.013-0.127-0.019-0.183-0.019c-0.141,0-0.248,0.036-0.354,0.071c-0.106,0.036-0.213,0.071-0.355,0.071
		c-0.041,0-0.085-0.003-0.133-0.01c0.673-1.041,1.674-1.745,2.395-2.74C52.875,60.676,52.426,61.458,52.459,61.76z M58.626,50.458
		c0.024,0,0.047,0.001,0.07,0.001c0.929,0,1.332-0.616,2.211-0.913c0.021-0.004,0.04-0.005,0.057-0.005
		c0.151,0,0.163,0.111,0.055,0.119c-1.333,2.011-2.786,3.989-3.996,6.277c-0.335,0.642-0.716,1.715-1.662,1.715
		c-0.015,0-0.032-0.001-0.048-0.002C54.808,54.542,57.02,52.206,58.626,50.458z M63.642,45.889c0.114,0.003,0.091,0.14,0.114,0.228
		c-1.185,1.786-3.141,2.792-5.356,3.543c0.011-0.942,0.889-0.873,1.482-1.145C61.22,47.894,62.36,46.696,63.642,45.889z
		 M62.273,46.345c0.281,0.362-0.559,0.585-0.796,0.801C61.271,46.861,62.09,46.62,62.273,46.345z M62.729,46.002
		c0.068-0.347,0.377-0.459,0.571-0.685C63.619,45.607,63.003,45.928,62.729,46.002z M78.436,42.53c0.127,0,0.258-0.074,0.402-0.149
		s0.3-0.15,0.481-0.15c0.021,0,0.043,0,0.065,0.003c-0.284,0.848-1.116,1.153-2.25,1.153c-0.121,0-0.245-0.003-0.372-0.01
		c0.228-1.006,0.735-1.717,1.958-1.717c0.067,0,0.136,0.002,0.208,0.007c-0.153,0.335-0.525,0.461-0.686,0.797
		C78.308,42.511,78.371,42.53,78.436,42.53z M73.342,43.263c-1.025,2.132-2.696,3.618-5.25,4.223
		c0.139-1.686,1.352-2.301,2.856-2.624c0.08,0.309-0.227,0.229-0.233,0.456C72.053,45.093,72.35,43.83,73.342,43.263z
		 M74.711,44.495c0.106,0,0.22,0.008,0.341,0.022c-0.045,0.297-0.342,0.348-0.342,0.685c0.684-0.265,1.071-0.826,1.824-1.026
		c-0.569,1.337-1.595,2.21-3.192,2.515C73.237,45.397,73.522,44.495,74.711,44.495z M78.131,44.061
		c0.028-0.007,0.053-0.01,0.075-0.01c0.103,0,0.138,0.068,0.171,0.137c0.034,0.068,0.068,0.137,0.168,0.137
		c0.039,0,0.089-0.01,0.154-0.035c0.54-0.032,0.456-0.694,0.796-0.913c0.019-0.004,0.037-0.005,0.052-0.005
		c0.142,0,0.168,0.113,0.297,0.119c-0.52,1.647-1.579,2.758-3.427,3.083C76.672,45.416,77.356,44.694,78.131,44.061z M75.965,41.89
		c0.004,0.106,0.089,0.132,0.201,0.132c0.057,0,0.121-0.006,0.185-0.014c0.063-0.007,0.127-0.014,0.183-0.014
		c0.043,0,0.081,0.005,0.112,0.016c-0.147,1.446-1.543,1.646-2.963,1.822c0.617-1.813,1.972-2.899,4.333-2.966
		C77.754,41.631,76.323,41.22,75.965,41.89z M71.519,42.463c0.045,0.137,0.11,0.183,0.194,0.183c0.089,0,0.2-0.053,0.326-0.106
		c0.126-0.053,0.269-0.106,0.423-0.106c0.064,0,0.13,0.009,0.197,0.03c-0.925,1.023-1.947,1.943-3.891,1.943
		c-0.035,0-0.07-0.001-0.105-0.002c1.243-1.762,3.1-2.91,5.932-3.082C73.946,42.312,72.498,41.808,71.519,42.463z M68.092,43.947
		c-0.927,0.729-1.944,1.545-3.539,1.711c-0.084-0.717,1.301-1.54,2.09-1.54c0.117,0,0.22,0.019,0.304,0.058
		c0.968-0.399,1.469-1.272,2.519-1.598C69.262,43.091,68.646,43.513,68.092,43.947z M65.922,46.231
		c-0.058,0.095-0.114,0.186-0.114,0.343c1.038-0.223,1.195-1.317,2.284-1.486c-1.465,1.996-3.526,3.4-6.16,4.227
		c0.005-0.83,0.901-0.964,1.368-1.369C64.041,47.303,64.576,46.231,65.922,46.231z M61.932,50.001
		c0.581-0.269,1.287-0.049,1.709-0.573c0.182,0.836-0.694,1.58-1.253,2.398c-0.958,1.383-1.607,3.266-3.307,3.882
		C58.684,53.681,60.62,50.592,61.932,50.001z M66.036,47.945c0.046-0.011,0.086-0.016,0.122-0.016c0.099,0,0.162,0.036,0.223,0.072
		c0.062,0.036,0.123,0.071,0.22,0.071c0.037,0,0.077-0.005,0.125-0.017c-0.832,2.028-1.943,3.768-3.767,4.798
		C63.425,50.655,64.594,49.16,66.036,47.945z M67.523,48.173c0.078-0.014,0.178-0.021,0.284-0.021c0.353,0,0.748,0.091,0.399,0.363
		c0.034,0.021,0.068,0.03,0.102,0.03c0.135,0,0.271-0.15,0.432-0.301c0.16-0.15,0.342-0.301,0.569-0.301
		c0.012,0,0.025,0,0.038,0.001c-0.736,1.583-1.488,3.152-3.311,3.651C66.081,50.004,66.959,49.243,67.523,48.173z M72.659,46.002
		c0.09,0.513-0.274,0.571-0.342,0.913c0.027,0.237,0.5,0.035,0.57,0.23c-0.976,1.612-2.03,3.144-3.996,3.768
		C69.41,48.607,70.937,46.719,72.659,46.002z M74.595,47.261c0.125,0.427-0.284,0.321-0.227,0.684
		c0.559-0.165,0.672-0.772,1.367-0.799c-1.083,2-2.474,3.691-4.559,4.681C71.587,49.576,72.681,48.008,74.595,47.261z
		 M75.052,51.827c0.011,0.205-0.137,0.242-0.113,0.456c0.331-0.125,0.546-0.364,0.796-0.573c0.136,0.043,0.292,0.203,0.115,0.231
		c-0.57,1.295-1.408,2.319-2.395,3.197c-0.118-0.012-0.228-0.017-0.334-0.017c-0.464,0-0.833,0.091-1.262,0.131
		C72.887,54.074,72.92,51.898,75.052,51.827z M76.298,53.298c0.041,0,0.082,0.004,0.122,0.012c-0.627,0.631-1.499,1.009-1.709,2.057
		C74.46,54.818,75.473,53.298,76.298,53.298z M74.71,51.026c-0.131-1.694,0.826-3.277,2.735-3.309
		c-0.021,0.468-0.444,0.541-0.569,0.913c0.03,0.004,0.059,0.006,0.088,0.006c0.147,0,0.274-0.053,0.391-0.106
		c0.117-0.053,0.222-0.106,0.328-0.106c0.072,0,0.144,0.024,0.218,0.092C77.514,50.027,76.209,50.625,74.71,51.026z M79.612,50.683
		c0.491,0.271,0.671,0.855,0.802,1.482c-0.054-0.005-0.103-0.008-0.147-0.008c-0.514,0-0.511,0.343-0.883,0.696
		C79.145,52.18,79.27,51.128,79.612,50.683z M81.094,47.6c0.019-0.003,0.036-0.004,0.052-0.004c0.103,0,0.157,0.045,0.183,0.121
		c-0.251,0.775-0.713,1.341-1.142,1.942C79.947,48.884,80.752,48.173,81.094,47.6z M80.772,52.736c0.274,0,0.595,0.188,0.557,0.46
		c-0.072,0.01-0.138,0.016-0.198,0.016c-0.321,0-0.503-0.134-0.716-0.243C80.456,52.805,80.605,52.736,80.772,52.736z
		 M81.212,50.914c-0.035-0.009-0.066-0.013-0.093-0.013c-0.107,0-0.151,0.063-0.196,0.126c-0.044,0.063-0.089,0.127-0.198,0.127
		c-0.025,0-0.055-0.004-0.088-0.012c-0.365-0.896,0.104-1.754,0.691-2.053c0.677,0.422,0.482,1.899,0.113,2.507
		C81.151,51.584,81.218,51.216,81.212,50.914z M52.917,51.941c0.357,0.034-0.24,0.466,0.119,0.466c0.029,0,0.064-0.003,0.107-0.009
		c-1.037,1.619-2.052,3.268-2.848,5.139c-0.423-0.226-0.89-0.405-1.255-0.688C50.18,55.063,51.49,53.438,52.917,51.941z
		 M45.278,56.393c2.404,0.938,4.49,2.205,6.838,3.199c-0.154,0.754-0.81,1.01-1.254,1.48c-1.754-1.096-3.003-2.697-4.673-3.879
		c1.072,1.783,2.872,2.829,4.105,4.45c-0.309,0.606-0.759,1.065-1.255,1.487C47.598,61.07,46.452,58.713,45.278,56.393z
		 M47.785,62.901c-0.158,0.833-0.496,1.482-1.368,1.598c-0.217-0.955,0.184-2.239,0.342-3.194
		C47.375,61.563,47.334,62.475,47.785,62.901z M43.221,75.46c-0.4,2.836-0.24,5.953-2.735,6.737
		c-0.144-8.399,2.376-15.133,5.477-20.669c0.101,0.055,0.206,0.103,0.227,0.231c-0.068,0.77-0.41,1.27-0.683,1.828
		c0.044,0.365,0.217,0.615,0.227,1.027C44.629,67.618,43.75,71.682,43.221,75.46z M46.874,71.237
		c-0.57,2.379-1.002,4.946-2.856,6.167c-0.027-4.336,1.168-8.428,2.399-11.876c0.25-0.057,0.432-0.187,0.603-0.187
		c0.103,0,0.203,0.048,0.31,0.187c0.331-0.44,0.347-1.645,0.932-1.645c0.063,0,0.133,0.015,0.209,0.044
		C48.48,66.303,47.444,68.822,46.874,71.237z M50.18,68.152c-0.427,1.104-0.992,2.703-2.395,2.74
		c0.194-2.054,1.124-4.352,1.597-6.509c0.604-0.037,1.106-0.188,1.709-0.225C51.056,65.379,50.67,66.899,50.18,68.152z
		 M51.553,67.02c-0.039,0-0.078-0.004-0.12-0.01c0.229-0.573,0.297-1.3,0.57-1.824C52.746,65.407,52.434,67.02,51.553,67.02z
		 M52.117,73.747c0.549,0.069,0.509,0.719,1.121,0.719c0.074,0,0.158-0.01,0.254-0.031c-0.018,0.593-0.68,0.546-0.457,1.369
		c-0.211-0.193-0.407-0.258-0.592-0.258c-0.369,0-0.694,0.256-1.01,0.258c0.206-0.778-0.32-0.821-0.457-1.255
		C51.61,74.529,51.981,74.254,52.117,73.747z M57.83,82.768c-0.414-0.125-0.73-0.271-1.041-0.271c-0.217,0-0.433,0.071-0.676,0.271
		c0.045-0.653,0.022-1.24-0.455-1.372c0.478-0.206,1.094-0.274,1.145-0.913c0.432,0.212,0.457,0.83,1.105,0.83
		c0.078,0,0.165-0.009,0.263-0.028C57.834,81.579,57.533,82.19,57.83,82.768z M58.626,90.523c-0.362-0.133-0.593-0.238-0.832-0.238
		c-0.189,0-0.385,0.066-0.656,0.238c0.314-0.666-0.194-0.758-0.336-1.256c0.457-0.107,0.799-0.334,0.911-0.795
		c0.332,0.393,0.765,0.676,1.368,0.795C58.825,89.588,58.489,89.822,58.626,90.523z M54.518,82.768
		c-0.021,0.001-0.041,0.002-0.06,0.002c-0.405,0-0.55-0.252-0.853-0.342c-0.382,0.049-0.404,0.461-0.836,0.461
		c-0.025,0-0.053-0.002-0.081-0.005c0.056-0.655,0.278-1.298-0.342-1.488c0.274-0.368,1.002-0.294,1.025-0.913
		c0.469,0.168,0.546,0.725,1.182,0.725c0.09,0,0.191-0.012,0.307-0.037C54.43,81.457,54.277,82.126,54.518,82.768z M51.32,85.164
		c0.393-0.291,1.06-0.309,1.139-0.91c0.411,0.25,0.522,0.801,1.176,0.801c0.025,0,0.051,0,0.078-0.002
		c-0.24,0.368-0.456,0.759-0.57,1.255c-0.232-0.187-0.438-0.271-0.649-0.271c-0.223,0-0.452,0.097-0.719,0.271
		C51.82,85.734,51.82,85.2,51.32,85.164z M55.658,84.253c0.412,0.257,0.536,0.8,1.212,0.8c0.014,0,0.027,0,0.042-0.001
		c-0.383,0.186-0.286,0.855-0.457,1.255c-0.237-0.143-0.519-0.287-0.764-0.287c-0.208,0-0.391,0.104-0.496,0.401
		c-0.079-0.524-0.307-0.901-0.45-1.369C55.229,84.964,55.457,84.619,55.658,84.253z M55.314,78.999
		c-0.21-0.133-0.413-0.188-0.609-0.188c-0.304,0-0.595,0.13-0.878,0.303c0.138-0.704-0.165-0.972-0.335-1.368
		c0.609-0.035,0.906-0.389,1.026-0.913c0.392,0.272,0.52,0.809,1.181,0.809c0.058,0,0.12-0.004,0.186-0.013
		C55.628,78.019,55.15,78.185,55.314,78.999z M51.091,77.172c0.418,0.212,0.562,0.697,1.187,0.697c0.056,0,0.116-0.005,0.181-0.014
		c-0.274,0.42-0.639,0.799-0.342,1.486c-0.375-0.075-0.676-0.191-0.978-0.191c-0.197,0-0.396,0.05-0.617,0.191
		c0.125-0.713,0.034-0.867-0.227-1.368C50.829,77.974,50.982,77.597,51.091,77.172z M50.065,80.714
		c0.352,0.243,0.48,0.716,1.071,0.716c0.088,0,0.186-0.011,0.296-0.034c-0.216,0.462-0.626,0.744-0.457,1.604
		c-0.33-0.087-0.558-0.277-0.797-0.459c-0.257,0.122-0.501,0.256-0.684,0.459c-0.355-0.14,0.125-1.198-0.571-1.258
		C49.324,81.419,49.969,81.335,50.065,80.714z M48.812,90.072c-0.376-0.045-0.57-0.273-0.798-0.461
		c-0.286,0.102-0.444,0.312-0.797,0.348c0.126-0.713,0.028-0.873-0.228-1.371c0.415-0.193,0.958-0.258,1.025-0.801
		c0.565,0.158,0.56,0.893,1.253,0.912C48.966,89.006,48.687,89.336,48.812,90.072z M49.107,86.254c-0.249,0-0.496,0.086-0.751,0.281
		c0.183-0.75-0.331-0.811-0.456-1.256c0.57-0.113,0.877-0.487,1.14-0.912c0.385,0.195,0.459,0.704,1.041,0.704
		c0.064,0,0.135-0.006,0.214-0.02c-0.309,0.551-0.474,0.585-0.343,1.483C49.659,86.359,49.381,86.254,49.107,86.254z M51.162,89.793
		c-0.167,0-0.339,0.076-0.526,0.279c-0.206-0.393,0.171-1.102-0.456-1.254c0.342-0.312,1.174-0.119,1.14-0.799
		c0.516,0.1,0.425,0.805,1.041,0.805c0.031,0,0.064-0.002,0.098-0.006c-0.08,0.523-0.587,0.621-0.456,1.371
		C51.705,90.002,51.439,89.793,51.162,89.793z M53.713,93.838c-0.274-0.172-0.562-0.299-0.863-0.299c-0.2,0-0.406,0.055-0.619,0.191
		c0.228-0.434,0.08-0.93-0.115-1.262c0.076,0.012,0.145,0.018,0.209,0.018c0.507,0,0.654-0.361,0.817-0.701
		c0.319,0.375,0.502,0.871,1.14,0.912C54.095,93.08,53.519,93.068,53.713,93.838z M54.564,90.115c-0.193,0-0.386,0.055-0.617,0.186
		c0.187-0.781-0.145-0.785-0.342-1.367h0.053c0.478,0,0.799-0.152,0.86-0.576c0.478,0.207,0.643,0.729,1.251,0.803
		c-0.147,0.389-0.637,0.424-0.455,1.141C55.025,90.191,54.796,90.115,54.564,90.115z M57.259,94.416
		c-0.265-0.141-0.521-0.283-0.762-0.283c-0.176,0-0.343,0.076-0.499,0.283c-0.273-0.42-0.234-1.137-0.57-1.49
		c0.097,0.018,0.185,0.023,0.264,0.023c0.565,0,0.736-0.398,0.876-0.822c0.4,0.248,0.344,0.945,0.99,0.945
		c0.079,0,0.168-0.01,0.271-0.033C57.657,93.52,57.212,93.719,57.259,94.416z M59.653,98.178c-0.332-0.119-0.645-0.275-0.919-0.275
		c-0.216,0-0.408,0.098-0.563,0.391c-0.068-0.725-0.189-0.941-0.577-1.482c0.032,0.002,0.063,0.002,0.093,0.002
		c0.58,0,0.853-0.309,0.939-0.807c0.439,0.219,0.618,0.703,1.287,0.703c0.061,0,0.125-0.004,0.194-0.012
		C59.721,97.09,59.384,97.404,59.653,98.178z M61.232,93.152h0.015c-0.352,0.355-0.534,0.588-0.339,1.264
		c-0.046,0.041-0.096,0.059-0.148,0.059c-0.195,0-0.416-0.25-0.538-0.408c-0.216,0.164-0.593,0.164-0.684,0.455
		c-0.216-0.387-0.023-1.195-0.57-1.256c0.411-0.189,1.054-0.158,1.026-0.797C60.395,92.711,60.596,93.152,61.232,93.152z
		 M59.311,81.17c0.083,0.017,0.158,0.023,0.225,0.023c0.541,0,0.602-0.479,0.8-0.824c0.41,0.356,0.496,1.026,1.254,1.026
		c-0.375,0.385-0.479,0.533-0.343,1.258c-0.046,0.039-0.095,0.056-0.146,0.056c-0.211,0-0.453-0.287-0.651-0.398
		c-0.297,0.123-0.429,0.413-0.798,0.457C59.926,81.886,59.174,81.542,59.311,81.17z M59.969,85.197c0.073,0,0.156-0.01,0.253-0.033
		c-0.193,0.419-0.649,0.57-0.457,1.372c-0.434-0.144-0.654-0.368-0.93-0.368c-0.152,0-0.321,0.067-0.551,0.254
		c0.205-0.815-0.313-0.907-0.454-1.369c0.545-0.021,1.006-0.132,1.138-0.57C59.463,84.56,59.395,85.197,59.969,85.197z
		 M57.941,77.058c0.52,0.11,0.557,0.689,1.186,0.689c0.023,0,0.045-0.001,0.069-0.002c-0.159,0.334-0.308,0.682-0.228,1.253
		c-0.344-0.07-0.615-0.152-0.847-0.152c-0.245,0-0.445,0.091-0.64,0.382c-0.371-0.316-0.211-1.154-0.569-1.482
		c0.093,0.013,0.178,0.021,0.257,0.021C57.664,77.766,57.887,77.5,57.941,77.058z M54.791,74.217c0.562,0,0.688-0.43,0.867-0.812
		c0.415,0.197,0.534,0.691,1.147,0.691c0.034,0,0.069-0.001,0.106-0.005c-0.336,0.475-0.315,0.714-0.229,1.483
		c-0.395-0.06-0.604-0.308-0.914-0.456c-0.284,0.131-0.38,0.453-0.797,0.456c0.132-0.707-0.17-0.973-0.342-1.368
		C54.688,74.214,54.741,74.217,54.791,74.217z M54.24,70.931c0.08,0,0.171-0.012,0.278-0.039c-0.178,0.399-0.475,0.664-0.343,1.372
		c-0.205-0.121-0.435-0.188-0.653-0.188c-0.3,0-0.574,0.127-0.721,0.416c-0.091-0.594-0.273-1.096-0.569-1.486
		c0.066,0.008,0.127,0.012,0.185,0.012c0.55,0,0.732-0.365,0.842-0.807C53.717,70.321,53.672,70.931,54.24,70.931z M53.492,64.042
		c-0.131-0.146-0.334-0.19-0.559-0.19c-0.169,0-0.35,0.025-0.521,0.051c-0.171,0.024-0.332,0.05-0.462,0.05
		c-0.068,0-0.126-0.008-0.175-0.024c-0.193-0.929,0.331-1.302,0.883-1.302C53.355,62.625,54.095,63.219,53.492,64.042z
		 M54.283,62.444c-1.699-0.586-0.546-3.311,0-4.338c0.525,0.089,0.559,0.665,0.912,0.916C55.15,60.417,54.649,61.36,54.283,62.444z
		 M54.86,63.813c0.005-2.854,1.526-5.161,2.512-7.42c0.319,0.113-0.033,0.484,0,0.687c0.4-0.1,0.422-0.564,0.569-0.915h0.343
		C58.125,58.983,57.634,63.263,54.86,63.813z M58.171,61.874c-0.366-1.733,0.684-4.029,1.367-5.48
		c0.199-0.098,0.286-0.178,0.425-0.178c0.068,0,0.148,0.019,0.259,0.063c0.239-0.713,0.592-1.31,1.025-1.828
		c0.02-0.002,0.037-0.003,0.054-0.003c0.098,0,0.154,0.041,0.176,0.117c-0.321,1.377-0.707,2.889-1.255,4.223
		C59.721,60.006,59.264,61.509,58.171,61.874z M60.564,60.274c0.344-1.987,1.242-4.38,2.279-6.049h0.006
		c0.111,0,0.188-0.034,0.263-0.068c0.076-0.034,0.152-0.068,0.262-0.068c0.044,0,0.095,0.006,0.154,0.02
		c0.312-0.676,0.802-1.178,1.366-1.597c0.021-0.003,0.04-0.004,0.058-0.004c0.1,0,0.153,0.04,0.172,0.118
		c-0.435,1.115-1.22,2.238-1.824,3.653C62.633,57.833,62.273,59.96,60.564,60.274z M64.783,57.993
		c-0.375,0.494-1.063,1.141-1.847,1.141c-0.03,0-0.062,0-0.093-0.002c0.639-2.399,1.665-4.422,2.965-6.163
		c0.135,0.051,0.253,0.073,0.358,0.073c0.828,0,0.876-1.39,1.926-1.557C66.902,53.478,66.172,56.19,64.783,57.993z M65.962,58.012
		c-0.087,0-0.176-0.006-0.268-0.02c0.534-1.905,2.097-3.764,2.855-5.827c0.139,0.029,0.254,0.042,0.356,0.042
		c0.583,0,0.647-0.425,1.473-0.611c-0.284,1.354-1.127,2.672-1.829,3.884C67.976,56.462,67.332,58.012,65.962,58.012z M69.803,57.08
		c-0.257,0.165-0.604,0.218-0.911,0.228c-0.053-1.664,1.122-3.051,1.716-4.453c0.374-0.052,0.618-0.231,1.054-0.231
		c0.027,0,0.056,0.001,0.085,0.003c0.027,0.333-0.263,0.351-0.229,0.685c0.518-0.619,1.036-1.241,1.594-1.825
		C72.829,52.398,70.903,56.356,69.803,57.08z M73.914,59.022c0.105-0.009,0.21-0.014,0.312-0.014c1.749,0,3.018,1.284,4.817,1.494
		c-0.012-0.904-1.425-1.225-2.508-1.715c-1.032-0.462-2.058-0.884-2.896-0.884c-0.985,0-1.713,0.583-1.891,2.37
		c-1.859-1.029-0.93-4.124,1.025-4.337c0.089-0.01,0.177-0.015,0.266-0.015c1.546,0,3.141,1.383,4.294,1.385
		c0-0.772-1.374-1.126-1.938-1.713c0.442-1.179,1.59-1.463,3.008-1.463c0.66,0,1.379,0.062,2.113,0.123
		c0.733,0.062,1.481,0.123,2.199,0.123c0.306,0,0.606-0.011,0.899-0.038c1.948-0.183,2.791-1.01,3.988-1.941
		c-0.09,0.744-0.751,0.92-0.911,1.597c1.903-0.445,1.79-3.91,4.104-4.335c0.229,2.794-1.943,2.549-2.735,4.221
		c0.918-0.297,1.539-0.898,2.621-1.026c0.104,1.36-0.807,1.708-1.139,2.626c0.842-0.406,1.551-0.944,2.792-0.944
		c0.161,0,0.332,0.009,0.513,0.029c0.062,1.479-1.01,2.311-2.236,2.311c-0.128,0-0.256-0.009-0.385-0.026
		c-0.137-0.904,0.924-0.597,1.14-1.141c-0.129-0.085-0.252-0.121-0.369-0.121c-0.676,0-1.157,1.227-1.683,1.606
		c0.224,0.104,0.475,0.138,0.74,0.138c0.267,0,0.547-0.035,0.825-0.07s0.556-0.07,0.813-0.07c0.044,0,0.086,0.001,0.128,0.003
		c-0.669,1.384-1.919,2.182-3.974,2.182c-0.152,0-0.31-0.005-0.47-0.014c0.945,0.767,3.065,0.357,4.443,0.684
		c-0.362,0.969-1.392,1.27-2.725,1.27c-0.151,0-0.308-0.004-0.467-0.011c0.364,0.704,1.204,0.924,2.166,1.026
		c-0.673,0.699-1.823,0.912-3.192,0.912c0.257,0.505,1.135,0.385,1.711,0.57c-1.066,0.393-2.076,0.561-3.042,0.561
		C81.521,64.374,77.858,60.309,73.914,59.022z M90.225,65.87c-0.404,0.014-0.798,0.02-1.188,0.02c-0.942,0-1.852-0.033-2.761-0.066
		c-0.911-0.034-1.821-0.067-2.766-0.067h-0.13c0.072-0.416-0.246-0.437-0.224-0.801c1.119,0.227,2.517,0.36,3.899,0.36
		c1.258,0,2.502-0.11,3.512-0.36C91.044,65.02,90.196,65.513,90.225,65.87z M82.011,65.987c0.062-0.015,0.114-0.021,0.16-0.021
		c0.108,0,0.184,0.033,0.259,0.066c0.075,0.033,0.151,0.066,0.259,0.066h0.006c0.04,0.533-0.313,0.673-0.57,0.912
		C81.869,66.892,82.086,66.292,82.011,65.987z M81.713,65.418c-0.066,0-0.134-0.002-0.201-0.003
		c-0.068-0.001-0.137-0.003-0.203-0.003c-0.032,0-0.065,0-0.097,0.001c0.279-0.174,0.691-0.22,1.142-0.228
		C82.267,65.387,82.006,65.418,81.713,65.418z M80.415,64.383h-0.455c-0.018-0.282,0.177-0.351,0.228-0.57
		c0.041-0.012,0.074-0.018,0.102-0.018C80.529,63.795,80.37,64.192,80.415,64.383z M79.491,65.751c0.199,0,0.42,0.075,0.577,0.118
		c0.09,0.661-0.427,0.711-0.684,1.029c-0.341-0.305,0-0.604-0.228-1.029C79.245,65.783,79.364,65.751,79.491,65.751z M76.419,65.414
		h0.457c0.034,0.305-0.057,0.636,0,1.369C76.294,66.74,76.215,65.902,76.419,65.414z M74.523,67.591c1.646,0,2.736,1.652,4.177,2.16
		c-1.556-0.303-3.232-1.483-4.902-2.056C74.053,67.624,74.293,67.591,74.523,67.591z M71.633,67.581
		c2.565,1.007,4.867,2.282,7.41,3.312c-2.771-0.379-5.786-1.511-8.55-2.281C70.771,68.172,71.319,67.995,71.633,67.581z
		 M102.924,69.973c0.067,0,0.131,0.002,0.192,0.006c0.964,0.066,2.013,0.959,3.085,1.258c1.293,0.362,2.382,0.365,3.652,0.571
		c0.536,1.093,1.174,2.099,1.711,3.196c-2.117,2.516-4.141,5.508-4.453,9.819c-0.199,2.739,0.371,5.313,0.803,7.874
		c0.4,2.457,1.223,4.98,1.25,7.426c0.052,3.926-1.932,6.822-4.109,8.562c-2.68,2.141-6.795,2.699-10.494,3.877
		c-3.777,1.207-6.514,3.047-8.896,5.369c-1.226-0.812-1.664-1.627-2.622-2.402c-6.476-5.207-17.715-3.828-19.972-12.902
		c-0.279-1.117-0.312-2.41-0.229-3.531c0.389-5.08,2.737-9.512,2.281-15.416c-0.193-2.581-1.152-4.928-2.165-6.622
		c-0.41-0.679-1.676-1.837-1.712-2.51c-0.051-1.013,1.712-2.604,2.053-3.542c2.404-0.183,4.446-0.725,6.047-1.711
		c1.853,0.358,4.098,1.034,6.047,1.597c2.057,0.597,4.263,0.938,5.818,1.601c0.931,0.393,1.368,1.105,1.943,1.369
		c0.875,0.404,2.201,0.646,3.532,0.646c0.969,0,1.939-0.128,2.739-0.417c0.751-0.27,1.311-0.881,2.165-1.257
		c1.607-0.698,3.807-1.013,5.711-1.483C98.586,71.032,101.398,69.973,102.924,69.973z M102.09,69.295
		c-2.861,0.747-5.62,1.608-8.668,2.167c2.378-1.237,5.072-2.153,7.528-3.311C101.441,68.425,101.874,68.75,102.09,69.295z
		 M99.392,67.811c0.024,0,0.052,0.001,0.077,0.001c-1.961,1.164-5.129,2.321-6.85,2.969C94.857,69.77,96.143,67.811,99.392,67.811z
		 M97.303,66.098c0.135,0.649-0.436,0.941-0.83,0.941c-0.072,0-0.139-0.01-0.195-0.029c0.09-0.322,0.124-0.704,0.223-1.023
		c0.111,0,0.228-0.007,0.34-0.007C97.032,65.98,97.209,65.999,97.303,66.098z M95.863,65.284c0.104,0,0.216,0.008,0.326,0.016
		c0.113,0.008,0.225,0.016,0.329,0.016c0.076,0,0.149-0.004,0.214-0.016c-0.184,0.173-0.607,0.32-0.967,0.32
		c-0.156,0-0.3-0.027-0.405-0.092C95.408,65.329,95.611,65.284,95.863,65.284z M95.93,67.812c-1.352,1.041-3.026,1.763-4.565,2.624
		c-1.07-1.024-3.12-1.58-5.137-1.58c-1.916,0-3.801,0.502-4.786,1.58c-1.652-0.818-3.129-1.816-4.68-2.74
		c1.988-0.08,3.954-0.11,5.914-0.11C87.076,67.585,91.441,67.737,95.93,67.812z M93.422,63.015c0.199-0.364,0.746-0.392,1.026-0.685
		c-0.022-0.1-0.134-0.11-0.255-0.11h-0.053c-0.018,0.001-0.035,0.001-0.052,0.001c-0.034,0-0.067-0.001-0.099-0.005
		c0.35-0.829,1.25-1.112,2.396-1.144C95.707,62.038,94.749,62.708,93.422,63.015z M98.443,59.022
		c-0.023,0.006-0.045,0.009-0.063,0.009c-0.175,0-0.12-0.231-0.278-0.243c-0.463,0.646-0.788,1.424-1.716,1.601
		c-0.082-0.922,0.657-1.948,1.552-1.948c0.055,0,0.108,0.004,0.164,0.013c0.406-0.141,0.279-0.808,0.824-0.808
		c0.027,0,0.057,0.002,0.088,0.005C99.094,58.375,98.783,58.717,98.443,59.022z M96.277,48.745c0-0.449,0.604-0.306,0.57-0.8
		c1.254,0.793,2.072,2.028,2.166,3.996C97.35,51.622,96.858,50.138,96.277,48.745z M99.925,57.878
		c-0.639-0.431-0.216-1.917-0.571-2.626C100.017,55.498,100.713,57.297,99.925,57.878z M102.09,57.307
		c-1.258-1.257-2.814-2.809-2.621-5.142C101.691,52.415,102.73,54.944,102.09,57.307z M101.863,52.283
		c2.416,0.177,3.385,3.383,3.647,5.71C103.141,57.699,102.98,54.21,101.863,52.283z M104.712,52.968
		c0.138-0.019,0.104-0.209,0.114-0.343c1.904,1.072,3.111,3.425,2.963,6.162C105.762,57.862,105.551,55.1,104.712,52.968z
		 M110.879,60.16c-1.436-0.271-1.789-1.871-2.285-3.08s-1.402-2.483-1.254-3.77c1.077,0.401,1.123,1.839,2.393,2.057
		C110.309,56.735,111.107,58.352,110.879,60.16z M114.186,61.989c-0.047,0.004-0.094,0.006-0.139,0.006
		c-1.326,0-1.885-1.733-2.371-3.207c-0.545-1.642-1.48-3.559-1.252-5.019C111.979,56.127,114.355,58.273,114.186,61.989z
		 M117.604,64.499c-0.033,0.01-0.068,0.014-0.104,0.014c-0.562,0-1.233-1.171-1.377-1.381c-1.156-1.713-1.721-3.929-2.396-5.938
		c0.192,0.016,0.583,0.169,0.788,0.169c0.064,0,0.111-0.015,0.126-0.056c0.023-0.098,0.202-0.343,0.336-0.342
		c0.05,0,0.091,0.032,0.118,0.114c0.832,0.836,1.494,2.003,2.053,3.423C117.468,61.295,118.653,64.204,117.604,64.499z
		 M118.75,63.243c-0.222-1.531-1.168-2.332-1.373-3.881c0.04-0.003,0.079-0.004,0.118-0.004
		C119.396,59.358,120.532,62.83,118.75,63.243z M119.206,70.781c0.454,0.102,0.292,0.818,0.853,0.818
		c0.051,0,0.108-0.007,0.174-0.02c-0.137,0.396-0.439,0.625-0.456,1.141c-0.237-0.127-0.462-0.19-0.688-0.19s-0.45,0.063-0.687,0.19
		c0.211-0.562,0.068-1.019-0.342-1.258C118.46,71.254,119.234,71.419,119.206,70.781z M118.666,78.775
		c0.027,0,0.056-0.001,0.084-0.003c-0.232,0.411-0.609,0.688-0.462,1.483c-0.282-0.246-0.485-0.352-0.714-0.352
		c-0.207,0-0.438,0.089-0.767,0.24c0.125-0.729-0.126-1.093-0.343-1.486c0.079,0.012,0.152,0.018,0.221,0.018
		c0.541,0,0.717-0.361,0.918-0.701C117.855,78.34,118.044,78.775,118.666,78.775z M116.579,87.33
		c-0.167-0.143-0.34-0.191-0.511-0.191c-0.172,0-0.34,0.049-0.495,0.098s-0.296,0.098-0.413,0.098c-0.023,0-0.043-0.002-0.064-0.004
		c0.304-0.537-0.066-0.957-0.227-1.366c0.53-0.039,0.873-0.271,1.025-0.685c0.428,0.121,0.413,0.688,0.956,0.688
		c0.022,0,0.047,0,0.071-0.003C116.772,86.41,116.202,86.701,116.579,87.33z M115.834,75.153c0.487,0,0.604-0.376,0.745-0.719
		c0.274,0.143,0.37,0.468,0.454,0.799c0.32,0.061,0.594,0.168,0.914,0.228c-0.213,0.397-0.729,0.485-0.57,1.255
		c-0.295-0.129-0.574-0.263-0.819-0.263c-0.21,0-0.396,0.098-0.55,0.38c-0.182-0.542-0.09-1.355-0.455-1.714
		C115.658,75.142,115.751,75.153,115.834,75.153z M115.887,82.428h0.008c-0.125,0.479-0.639,0.571-0.457,1.369
		c-0.242-0.174-0.532-0.309-0.823-0.309c-0.186,0-0.37,0.055-0.543,0.191c-0.069-0.424,0.182-1.175-0.228-1.252
		c0.228-0.343,0.888-0.252,0.912-0.801C115.288,81.741,115.238,82.428,115.887,82.428z M113.844,78.084
		c0.535,0.229,0.781,0.744,1.367,0.914c-0.365,0.327-0.648,0.795-0.569,1.257c-0.248-0.185-0.468-0.261-0.686-0.261
		c-0.253,0-0.506,0.104-0.797,0.261c0.204-0.685-0.046-0.936-0.228-1.483C113.363,78.675,113.785,78.564,113.844,78.084z
		 M110.993,84.139c0.091-0.67-0.212-0.936-0.348-1.371c0.462-0.146,0.952-0.269,1.03-0.799c0.529,0.094,0.442,0.802,1.068,0.802
		c0.023,0,0.047-0.001,0.073-0.003c-0.103,0.514-0.644,0.576-0.456,1.371c-0.378-0.123-0.598-0.252-0.82-0.252
		C111.381,83.887,111.219,83.955,110.993,84.139z M112.02,88.02c-0.184-0.508-0.092-1.278-0.455-1.598
		c0.076,0.012,0.146,0.018,0.211,0.018c0.653,0,0.73-0.577,1.156-0.816c-0.035,0.685,0.627,0.667,1.024,0.913
		c-0.229,0.423-0.622,0.667-0.454,1.484c-0.299-0.129-0.627-0.217-0.801-0.461C112.441,87.682,112.201,87.82,112.02,88.02z
		 M112.59,89.959c0.111,0.023,0.211,0.033,0.299,0.033c0.521,0,0.685-0.354,0.839-0.725c0.333,0.357,0.521,0.854,1.142,0.922
		c-0.264,0.387-0.74,0.549-0.57,1.367c-0.256-0.131-0.538-0.295-0.79-0.295c-0.175,0-0.335,0.076-0.464,0.295
		C112.902,91.008,112.863,90.363,112.59,89.959z M112.701,98.863c-0.227-0.197-0.558-0.432-0.865-0.432
		c-0.185,0-0.361,0.086-0.5,0.316c-0.252-0.41,0.455-1.311-0.229-1.596c0.598-0.125,1.162-0.287,1.367-0.799
		c0.24,0.439,0.343,1.025,0.912,1.139C113.078,97.877,112.68,98.162,112.701,98.863z M115.324,94.984
		c-0.207-0.139-0.393-0.189-0.572-0.189c-0.303,0-0.586,0.15-0.908,0.299c0.153-0.84-0.252-1.119-0.457-1.59
		c0.102,0.02,0.191,0.029,0.271,0.029c0.529,0,0.662-0.4,0.869-0.723c0.307,0.418,0.81,0.633,1.254,0.92
		C115.643,94.158,115.12,94.203,115.324,94.984z M116.123,91.328c0.062-0.633-0.022-1.117-0.455-1.256
		c0.26-0.348,0.967-0.25,0.911-0.912c0.64,0.086,0.672,0.771,1.368,0.799c-0.171,0.393-0.479,0.662-0.344,1.369
		c-0.221-0.137-0.418-0.186-0.617-0.186C116.719,91.143,116.449,91.232,116.123,91.328z M118.75,94.635
		c-0.22-0.135-0.438-0.193-0.648-0.193c-0.302,0-0.588,0.117-0.84,0.309c0.246-0.645,0.012-1.236-0.454-1.484
		c0.479-0.17,1.174-0.121,1.253-0.678c0.375,0.309,0.661,0.709,1.26,0.795C119.104,93.781,118.585,93.867,118.75,94.635z
		 M121.143,90.984c-0.211-0.164-0.592-0.164-0.682-0.461c-0.012-0.002-0.023-0.002-0.035-0.002c-0.199,0-0.283,0.117-0.364,0.232
		c-0.082,0.115-0.163,0.232-0.362,0.232c-0.012,0-0.024,0-0.037-0.002c-0.212-0.439,0.006-1.299-0.577-1.373
		c0.333-0.395,1.038-0.406,1.034-1.139c0.543,0.158,0.603,0.807,1.305,0.807c0.055,0,0.113-0.004,0.176-0.012
		C121.332,89.732,120.989,90.102,121.143,90.984z M124.875,89.162c0.012,0,0.024-0.002,0.037-0.002
		c-0.098,0.514-0.611,0.605-0.455,1.363c-0.396-0.1-0.664-0.289-0.972-0.289c-0.153,0-0.317,0.047-0.513,0.176
		c0.184-0.826-0.285-1.012-0.455-1.477c0.106,0.02,0.202,0.029,0.289,0.029c0.563,0,0.709-0.414,0.966-0.717
		C124.141,88.545,124.203,89.162,124.875,89.162z M123.887,85.851c-0.178,0.399-0.479,0.665-0.343,1.364
		c-0.194-0.15-0.43-0.207-0.675-0.207c-0.315,0-0.647,0.096-0.927,0.207c0.245-0.736-0.132-0.933-0.228-1.592
		c0.074,0.012,0.141,0.018,0.202,0.018c0.47,0,0.62-0.32,0.823-0.588C123.087,85.348,123.247,85.836,123.887,85.851z
		 M122.973,82.541c-0.193,0.344-0.455,0.615-0.34,1.257c-0.34-0.09-0.594-0.214-0.9-0.214c-0.143,0-0.296,0.027-0.476,0.097
		c0.065-0.519-0.056-0.855-0.347-1.026c0.24-0.377,0.77-0.451,0.917-0.912C122.198,82.014,122.353,82.508,122.973,82.541z
		 M121.942,78.887c-0.108,0.387-0.321,0.669-0.228,1.257c-0.324-0.103-0.627-0.194-0.924-0.194c-0.225,0-0.447,0.053-0.672,0.194
		c0.141-0.628,0.096-0.987-0.343-1.257c0.347-0.295,0.997-0.295,1.134-0.803C121.326,78.287,121.435,78.784,121.942,78.887z
		 M121.371,67.926c-0.08,0.026-0.156,0.039-0.229,0.039c-0.438,0-0.695-0.474-0.729-0.948c-0.033-0.474,0.16-0.948,0.629-0.948
		c0.065,0,0.137,0.009,0.214,0.029C121.549,66.463,121.811,67.538,121.371,67.926z M125.025,72.606
		c-2.27-1.117-2.382-4.395-3.198-6.964c0.065-0.007,0.126-0.009,0.187-0.009c0.625,0,0.973,0.28,1.53,0.354
		C124.215,67.931,125.219,70.165,125.025,72.606z M128.674,78.202c-1.945-0.459-2.144-3.248-2.623-5.709
		c-0.181-0.916-0.489-2.122-0.798-3.312c-0.415-1.577-1.276-3.132-0.456-3.882l0.115,0.114c0.507,0.468,0.695,0.576,1.254,1.029
		c1.186,2.37,2.028,5.487,2.508,8.79C128.816,76.217,129.238,77.357,128.674,78.202z M131.521,83.68
		c-0.889-0.294-1.195-1.28-1.367-2.053c-0.5-2.365-0.416-5.025-0.91-7.536c-0.484-2.478-1.471-4.693-1.597-7.081
		c0.095-0.13,0.134-0.166,0.188-0.166c0.065,0,0.153,0.051,0.384,0.055c-0.309-0.772-0.24-1.215-0.344-2.17
		c1.818,4.078,3.79,9.399,4.105,14.955C132.048,80.823,132.218,82.834,131.521,83.68z M100.268,84.139
		c-0.309-1.172-1.426-1.634-2.741-1.828c-0.999,1.623,0.097,3.956,1.942,4.11c0.172,0.477,0.49,0.793,0.456,1.479
		c-0.106-0.076-0.234-0.127-0.409-0.127c-0.05,0-0.104,0.006-0.162,0.014c0.104,1.037-0.328,2.617,0.571,2.857
		c0.192-0.262-0.046-0.959,0.343-1.033c0.262,0.045,0.488,0.121,0.568,0.348c-0.062,1.043-0.677,1.529-0.683,2.629
		c0.56-0.098,0.535-0.764,1.024-0.916c0.264,0.52,0.468,1.572,0.115,2.059c-0.021,0.004-0.041,0.004-0.059,0.004
		c-0.124,0-0.185-0.064-0.242-0.127c-0.059-0.064-0.117-0.129-0.238-0.129c-0.041,0-0.088,0.008-0.146,0.025
		c-0.084,0.631-0.215,1.223-0.227,1.938c0.047,0.045,0.088,0.062,0.125,0.062c0.086,0,0.147-0.102,0.224-0.205
		c0.077-0.104,0.17-0.205,0.319-0.205h0.016c0.491,1.414,0.269,3.969-0.341,5.029c-0.308-1.996-0.707-4.217-1.142-6.393
		c-0.238-1.229-0.911-2.363-1.025-3.43c-0.113-1.008,0.234-1.824-0.342-2.625c-0.461-0.637-1.197-0.828-2.172-1.027
		c-0.947,1.642-0.125,3.996,1.924,3.996h0.02c0.184,0.279,0.514,1.002,0.343,1.254c-0.274-0.188-0.661-0.252-1.026-0.342
		c-0.199,0.85-0.137,1.973-0.113,2.738c0.559-0.084,0.25-1.037,0.797-1.143c0.885,0.258,0.975,1.309,1.027,2.402h-0.57
		c-0.166,0.814-0.66,2.02-0.229,2.738c0.285-0.359,0.354-0.938,0.799-1.141c0.518,0.469,0.77,1.812,0.568,2.625
		c-0.111-0.061-0.214-0.086-0.312-0.086c-0.488,0-0.846,0.631-1.283,0.881c-0.207,0.275-0.143,0.809-0.615,0.809
		c-0.021,0-0.045,0-0.068-0.002c-0.074-0.281,0.137-0.826-0.113-0.92c-0.066,0.121-0.137,0.162-0.209,0.162
		c-0.084,0-0.17-0.053-0.26-0.109c-0.09-0.055-0.184-0.111-0.281-0.111c-0.053,0-0.105,0.018-0.162,0.059
		c-0.079,1.758,1.961,1.018,2.85,1.604c-1.039,0.383-2.369,0.057-3.654,0.342c0.547,0.271,1.201,0.355,1.88,0.355
		c1.086,0,2.239-0.215,3.144-0.24c-0.242-0.291-0.947-0.125-1.143-0.457c0.264-0.268,0.145-0.924,0.343-1.26
		c0.058-0.014,0.108-0.02,0.153-0.02c0.412,0,0.293,0.535,0.304,0.932c0.054-0.002,0.11-0.002,0.171-0.002
		c0.669,0,1.803,0.084,2.564,0.35c0.24-0.344,0.497-0.447,0.787-0.447c0.211,0,0.44,0.055,0.693,0.111
		c0.254,0.055,0.53,0.109,0.838,0.109c0.062,0,0.125-0.002,0.189-0.008c0.318-0.277,0.627-1.506,0-1.705
		c0.01,0.223-0.461,0.359-0.628,0.359c-0.038,0-0.062-0.008-0.057-0.023v-0.682c-0.125-0.068-0.242-0.09-0.349-0.09
		c-0.078,0-0.152,0.012-0.219,0.021c-0.065,0.012-0.126,0.021-0.18,0.021c-0.066,0-0.121-0.016-0.164-0.07
		c-0.084-1.275-0.046-3.387,1.14-3.77c0.628,0.027,0.438,0.861,1.026,0.92c0.062-0.799-0.166-2.254-0.799-2.744
		c-0.038-0.008-0.074-0.012-0.108-0.012c-0.216,0-0.364,0.154-0.486,0.309c-0.122,0.156-0.22,0.309-0.333,0.309
		c-0.032,0-0.063-0.01-0.098-0.037c0.182-1.578,2.291-2.104,2.059-3.875c-0.041-0.264-0.576-0.486-0.465-1.027
		c-0.583,0-0.705,0.16-1.079,0.16c-0.106,0-0.235-0.014-0.401-0.047c-0.559,0.799-1.111,2.412-0.455,3.42
		c-0.293-0.104-0.326-0.475-0.733-0.475c-0.052,0-0.112,0.006-0.179,0.02c-0.27,0.49-0.285,1.238-0.799,1.484
		c0.178-2.027,0.48-3.936,1.026-5.596c1.71-0.439,2.952-3.439,0.913-4.446c-0.56,0.2-0.879,0.639-1.598,0.685
		c-1.219,1.526,1.073,3.56-0.455,4.445c-0.08-0.49-0.649-0.49-0.912-0.795c-0.152-0.877-0.821-1.618-0.911-2.737
		C100.106,85.287,100.403,84.678,100.268,84.139z M97.645,83.11c0.25,0.245,0.662,0.326,0.685,0.802h-0.026
		C97.812,83.912,97.545,83.696,97.645,83.11z M97.872,84.368c0.868,0.12,1.585,0.386,1.71,1.255
		C98.637,85.578,98.102,85.124,97.872,84.368z M98.783,84.026c-0.038-0.266,0.02-0.44,0.115-0.57
		c0.48,0.208,0.811,0.553,0.798,1.255C99.286,84.59,98.99,84.35,98.783,84.026z M96.277,87.676c0.146,0.113,0.416,0.111,0.57,0.225
		c-0.019,0.148-0.13,0.217-0.25,0.217C96.407,88.117,96.193,87.951,96.277,87.676z M97.51,89.646c-0.515,0-1.14-0.408-1.124-1.059
		c0.745,0.051,1.351,0.248,1.373,1.023C97.682,89.635,97.598,89.646,97.51,89.646z M97.551,88.584c-0.233,0-0.466-0.342-0.361-0.684
		h0.337c0.062,0.205,0.062,0.475,0.232,0.572C97.695,88.551,97.623,88.584,97.551,88.584z M105.055,92.01
		c-0.384-0.092-0.207-0.486,0.156-0.486c0.057,0,0.119,0.01,0.186,0.033C105.361,91.791,105.088,91.779,105.055,92.01z
		 M105.334,90.521c0.02,0,0.04,0,0.062,0.002c0.022,0.365-0.285,0.4-0.57,0.461C104.848,90.68,105.006,90.521,105.334,90.521z
		 M104.371,91.328c0.387,0.053,0.272,0.947,0,1.025C103.812,92.258,104.416,91.631,104.371,91.328z M103.001,87.676
		c0.179-0.923,0.571-1.634,1.483-1.825C104.439,86.905,103.732,87.295,103.001,87.676z M104.029,84.938
		c0.32,0.399-0.103,0.798-0.551,0.798h-0.02C103.584,85.406,103.602,84.964,104.029,84.938z M102.889,85.623
		c0.422,0.077,0.205,0.787,0,0.913C102.654,86.438,102.769,85.743,102.889,85.623z M103.116,94.416
		c0.364,1.373-0.057,3.889-0.228,5.475c-0.399-0.064-0.172-0.742-0.229-1.143C101.463,98.117,102.238,95.07,103.116,94.416z
		 M101.406,101.264c-0.432-0.252-0.079-0.666,0-1.027C101.966,100.285,101.737,101.156,101.406,101.264z M101.406,89.611
		c0.201,0.109,0.399,0.215,0.344,0.578c-0.025,0.002-0.051,0.006-0.074,0.006C101.395,90.195,101.385,89.918,101.406,89.611z
		 M94.151,100.553c0.122,0,0.237,0.031,0.345,0.064c0.106,0.031,0.203,0.062,0.285,0.062c0.105,0,0.188-0.053,0.236-0.221
		c-0.074-0.154-0.439-0.016-0.457-0.223c0.371-0.277,1.34,0.041,1.482-0.459c-0.002-0.195-0.16-0.236-0.356-0.236
		c-0.071,0-0.146,0.006-0.222,0.01c-0.077,0.006-0.153,0.012-0.225,0.012c-0.154,0-0.285-0.025-0.338-0.129
		c0.714-0.244,1.409-0.148,2.053-0.229c-0.324-0.672-1.408-0.342-1.938-0.57c0.49-2.252,1.499-3.98,1.715-6.508
		c-0.166,0.016-0.324,0.021-0.475,0.021c-0.366,0-0.696-0.037-1.028-0.072c-0.331-0.033-0.663-0.068-1.032-0.068
		c-0.068,0-0.135,0-0.207,0.002c-0.973-2.445-1.554-5.291-3.196-7.072c0.093-0.397-0.165-0.439-0.114-0.799
		c-1.16-1.246-2.271-3.605-4.062-3.605c-0.458,0-0.961,0.154-1.521,0.522c-1.198,0.782-3.022,3.44-3.654,4.68
		c-0.558,0.395-0.952,0.954-1.027,1.823c-0.802,0.949-1.322,2.18-1.601,3.656c-2.478,1.93-3.602,5.219-5.7,7.533
		c0.263,0.234,0.042,0.953,0.115,1.375c0.484,0.041,1.009,0.053,1.367,0.223v1.143c2.23,0.191,4.354,0.334,6.601,0.334
		c1.073,0,2.175-0.031,3.33-0.107c0.171,0.094,0.153,0.371,0.417,0.371c0.043,0,0.093-0.008,0.153-0.023
		c0.824-0.199,2.221-0.242,3.704-0.242c0.55,0,1.112,0.006,1.663,0.012c0.549,0.006,1.085,0.012,1.584,0.012
		c0.411,0,0.797-0.004,1.143-0.016c0.386-0.303,0.471-0.896,0.801-1.256C94.045,100.559,94.1,100.553,94.151,100.553z M92.73,94.869
		h0.029c0.084,0,0.141,0.029,0.194,0.059c0.055,0.029,0.109,0.059,0.192,0.059c0.014,0,0.027-0.002,0.043-0.002
		c-0.731,2.162-3.475,2.299-6.043,2.625c-0.302-0.195-0.57-0.42-0.456-1.029c0.233,0.045,0.486,0.064,0.755,0.064
		c1.46,0,3.339-0.594,4.717-1.09C92.32,95.498,92.846,95.223,92.73,94.869z M92.112,91.65c0.151,0,0.352,0.096,0.397,0.135
		c0.011,0.199-0.143,0.24-0.12,0.455c-0.044,0.014-0.083,0.018-0.114,0.018c-0.269,0-0.128-0.408-0.336-0.473
		C91.95,91.684,92.022,91.65,92.112,91.65z M91.922,93.713c0.197,0,0.16,0.24,0.354,0.24c-0.781,0.963-2.893,1.957-4.672,1.957
		c-0.804,0-1.54-0.203-2.054-0.703c1.983-0.258,4.708-0.084,6.269-1.477C91.859,93.719,91.893,93.713,91.922,93.713z M86.502,81.325
		c0.281,0,0.582,0.065,0.874,0.188c0.038,0.695-0.438,0.97-0.968,0.97c-0.396,0-0.821-0.154-1.085-0.399
		C85.496,81.564,85.961,81.325,86.502,81.325z M83.955,82.884c0.664,0.103,1.613,0.591,2.511,0.591c0.399,0,0.788-0.097,1.136-0.365
		c0.354,0.217,0.849,0.291,0.912,0.802c-0.46,0.257-1.01,0.369-1.582,0.369c-1.061,0-2.2-0.384-2.977-0.939V82.884z M83.418,84.338
		c0.128,0,0.25,0.045,0.382,0.091c0.133,0.045,0.277,0.091,0.449,0.091c0.084,0,0.176-0.011,0.276-0.038
		c0.045,0.217,0.318,0.205,0.227,0.57c0.033,0.008,0.063,0.011,0.088,0.011c0.108,0,0.153-0.062,0.196-0.125
		s0.087-0.126,0.195-0.126c0.026,0,0.056,0.004,0.091,0.012c0,0.226,0.307,0.146,0.227,0.456c0.101-0.146,0.265-0.216,0.426-0.216
		c0.239,0,0.472,0.155,0.485,0.444c0.206-0.017,0.067-0.388,0.229-0.456c0.14,0.087,0.307,0.116,0.487,0.116
		c0.213,0,0.444-0.041,0.674-0.082c0.23-0.041,0.459-0.083,0.666-0.083c0.395,0,0.708,0.15,0.795,0.732
		c-0.648,0.488-1.646,0.736-2.659,0.736c-1.641,0-3.321-0.651-3.61-1.99C83.182,84.375,83.303,84.338,83.418,84.338z M82.467,85.623
		c0.331,0.276,0.815,0.405,0.798,1.026c0.112-0.044,0.2-0.062,0.271-0.062c0.163,0,0.24,0.091,0.321,0.183
		c0.08,0.092,0.163,0.184,0.337,0.184c0.085,0,0.192-0.022,0.331-0.076c-0.051,0.273,0.204,0.246,0.113,0.568
		c0.175-0.178,0.533-0.285,0.898-0.285c0.432,0,0.875,0.15,1.039,0.516c0.98-0.619,2.076-0.27,3.076-0.798
		c0.008,0.107,0.139,0.088,0.23,0.11c0.158,0.149,0.113,0.497,0.113,0.799c-0.946,0.471-2.385,0.779-3.79,0.779
		c-2.11,0-4.142-0.699-4.309-2.602C82.034,85.796,82.324,85.788,82.467,85.623z M81.094,87.559h0.581
		c0.01,0.596,0.866,0.346,0.792,1.029c0.123-0.061,0.254-0.094,0.375-0.094c0.231,0,0.42,0.125,0.424,0.439
		c0.049,0.014,0.09,0.02,0.126,0.02c0.087,0,0.139-0.037,0.189-0.07c0.051-0.035,0.1-0.07,0.184-0.07
		c0.021,0,0.044,0.002,0.07,0.006c0.524,0.516,1.248,0.654,2.005,0.654c0.459,0,0.931-0.051,1.376-0.104
		c0.445-0.051,0.865-0.104,1.224-0.104c0.024,0,0.049,0.002,0.073,0.002c0.109-0.08,0.172-0.203,0.229-0.334
		c0.137,0.045,0.262,0.062,0.381,0.062c0.182,0,0.348-0.041,0.518-0.08s0.342-0.08,0.533-0.08c0.153,0,0.318,0.025,0.506,0.098
		c0.338-0.105-0.068-0.371,0-0.576c0.033-0.008,0.062-0.012,0.09-0.012c0.108,0,0.152,0.062,0.197,0.127
		c0.045,0.062,0.088,0.125,0.194,0.125c0.026,0,0.055-0.002,0.089-0.01c-0.82,1.479-2.652,1.943-4.562,1.943
		c-1.461,0-2.968-0.273-4.103-0.572C81.966,89.273,80.695,88.914,81.094,87.559z M80.428,89.627c0.571,0,0.927,0.514,1.247,1.018
		c0.098-0.066,0.182-0.092,0.257-0.092c0.144,0,0.254,0.096,0.358,0.189c0.104,0.096,0.204,0.189,0.328,0.189
		c0.087,0,0.185-0.047,0.305-0.172c0.098,0.273,0.25,0.33,0.418,0.33c0.059,0,0.12-0.008,0.182-0.014
		c0.062-0.008,0.123-0.014,0.184-0.014c0.184,0,0.357,0.062,0.476,0.379c0.35-0.078,0.69-0.172,1.015-0.172
		c0.284,0,0.554,0.072,0.809,0.287c0.499-0.289,1.099-0.305,1.74-0.305h0.236c0.509,0,1.038-0.01,1.558-0.15
		c0.238-0.029,0.033-0.51,0.227-0.578c0.554,0.211,0.834,0.184,1.369,0.236c-0.377,0.818-1.803,1.215-2.852,1.48
		c-0.718,0.188-1.48,0.26-2.258,0.26c-1.39,0-2.826-0.236-4.13-0.49c-0.599-0.807-1.693-1.121-1.938-2.277
		C80.132,89.66,80.287,89.627,80.428,89.627z M79.497,91.215c1.989,2.463,5.386,1.99,7.879,2.168c1.195-0.428,2.626-0.4,3.531-1.029
		c0.068,0.166,0.184,0.281,0.343,0.344c-0.945,1.291-3.408,1.764-5.472,1.824c-0.115,0.004-0.231,0.006-0.347,0.006
		c-1.708,0-3.399-0.383-4.674-0.797C80.199,93.119,78.763,92.373,79.497,91.215z M78.813,93.266
		c1.741,0.988,3.167,1.951,5.426,1.951c0.13,0,0.263-0.004,0.399-0.01c-0.474,0.529-1.083,0.725-1.736,0.725
		c-1.325,0-2.83-0.811-3.746-1.297C79.07,94.158,78.665,93.992,78.813,93.266z M82.945,96.66c0.263,0,0.524-0.025,0.781-0.08
		c-0.034,0.354-0.119,0.646-0.228,0.912c-0.093,0.004-0.184,0.004-0.273,0.004c-2.349,0-3.834-0.859-4.525-2.512
		C79.987,95.904,81.501,96.66,82.945,96.66z M77.674,93.73c-0.011,2.043,2.35,4.479,4.72,4.479c0.33,0,0.66-0.047,0.985-0.15
		c1.109-0.338,0.568-2.088,2.018-2.088c0.114,0,0.24,0.012,0.381,0.035c0.444,0.32,0.285,1.236,0.456,1.83
		c0.453,0.133,1,0.197,1.598,0.197c3.107,0,7.516-1.77,6.382-5.107c0.19-0.096,0.497-0.135,0.813-0.135
		c0.383,0,0.782,0.055,1.016,0.135c-0.608,2.023-1.195,4.049-1.829,6.053c-6.304-0.121-13.193-0.234-19.732-0.457
		C75.508,96.883,76.431,95.143,77.674,93.73z M90.361,99.811c-5.523,0-10.925-0.248-16.335-0.377
		c0.979-0.014,1.952-0.018,2.922-0.018c5.64,0,11.159,0.189,16.81,0.361C92.619,99.801,91.488,99.811,90.361,99.811z
		 M71.814,100.668c-0.083,0-0.146-0.047-0.182-0.209c0.387-1.854-0.302-4.189,1.823-4.682c0.439,0.25,0.264,1.113,0.913,1.141
		c0.108-1,0.462-2.334,0.227-3.303h-0.009c-0.869,0-1.098,0.639-1.699,0.906c0.318-1.18,0.187-1.951,0.796-2.736
		c0.668-0.857,2.138-0.971,2.736-1.713c0.491-0.598,0.29-1.539,0.115-2.172h-0.05c-0.27,0-0.461,0.045-0.631,0.092
		c-0.17,0.045-0.319,0.092-0.505,0.092c-0.117,0-0.248-0.018-0.409-0.064c-1.767,0.557-0.89,3.219-2.167,4.107
		c-0.08-0.348-0.182-0.662-0.57-0.686c-1.094,0.658-1.254,2.248-1.594,3.652c0.729-0.139,0.648-1.09,1.367-1.256
		c0.296,0.719-0.354,2.258-0.798,2.973c0.012-0.59-0.308-0.84-0.343-1.369c-0.294-0.016-0.545-0.068-0.689-0.234
		c0.325-2.447,0.828-4.734,1.944-6.389h0.055c1.429,0,1.976-0.877,2.567-1.713c-0.445-0.638-0.16-0.907-0.115-1.826
		c-0.02-0.001-0.041-0.001-0.062-0.001c-0.278,0-0.508,0.043-0.714,0.084c-0.207,0.042-0.39,0.084-0.575,0.084
		c-0.185,0-0.372-0.042-0.586-0.167c-1.665,0.99-0.639,3.384-1.944,4.566c-0.248-0.057-0.249-0.365-0.55-0.365
		c-0.039,0-0.081,0.006-0.13,0.016c-0.466,1.027-1.258,2.324-0.917,3.77c0.257-0.359,0.348-0.865,0.805-1.025
		c-0.262,1.643,0.068,3.885-1.146,4.57c-0.04-2.916-0.587-6.273,0.114-8.91c1.506-0.357,1.612-2.107,2.17-3.418
		c-0.159-0.194-0.649-0.04-0.804-0.229c-0.084-0.596-0.262-1.106-0.792-1.254c-0.324,0.292-0.508,0.725-1.118,0.725
		c-0.108,0-0.23-0.014-0.369-0.044c-0.639,1.979,0.118,3.5,0.112,5.138c-0.207-0.049-0.284-0.232-0.537-0.232
		c-0.011,0-0.021,0-0.032,0.002c-1.174,0.879-1.431,2.672-1.943,4.223c0.901-0.512,1.026-1.789,2.057-2.166
		c0.416,0.787,0.348,2.578,0.343,3.422c-0.144-0.033-0.277-0.049-0.399-0.049c-1.295,0-1.497,1.787-1.886,2.9
		c0.707-0.285,0.933-1.045,1.709-1.256c1.008,0.332,1.037,3.207,0.576,3.885c-0.128-0.129-0.21-0.178-0.288-0.178
		c-0.106,0-0.204,0.09-0.398,0.178c-0.016-0.568-0.381-0.129-0.569-0.568c-0.12,0.414-0.161,0.904-0.461,1.145
		c-0.238-0.027-0.369-0.309-0.536-0.309c-0.046,0-0.095,0.021-0.149,0.076c0.125,0.508-0.188,0.562-0.229,0.912
		c-0.043-0.043-0.205-0.125-0.327-0.125c-0.101,0-0.175,0.057-0.13,0.238c0.019,0.318,0.087,0.389,0.225,0.389
		c0.055,0,0.12-0.012,0.198-0.021c0.077-0.012,0.167-0.021,0.271-0.021c0.035,0,0.07,0,0.107,0.002
		c0.386,0.281-0.081,0.75,0.342,0.908c0.119-0.148,0.112-0.422,0.34-0.457c0.38,0.035,0.613,0.57,0.918,0.57
		c0.103,0,0.214-0.061,0.342-0.223c-0.015,0.002-0.029,0.002-0.043,0.002c-0.65,0,0.015-0.771,0.043-1.027
		c0.416,0.307,0.679,0.764,0.913,1.254c0.138,0.012,0.267,0.018,0.391,0.018c0.595,0,1.072-0.123,1.667-0.137
		c0.01-0.326-0.068-0.748,0.107-0.908c0.438,0.359,1.122,0.475,1.373,1.027c0.65-0.131,0.114-1.193,0.114-1.719
		C72.051,100.596,71.917,100.668,71.814,100.668z M73.914,94.416c0.226,0.117,0.129,0.877,0,1.025
		c-0.295-0.021-0.167-0.467-0.485-0.467c-0.025,0-0.054,0.002-0.086,0.01C73.434,94.693,73.741,94.619,73.914,94.416z
		 M75.165,88.818c-0.073,0.418-0.386,0.6-0.796,0.678C74.346,88.982,74.71,88.85,75.165,88.818z M75.595,89.539
		c0.167,0,0.331,0.023,0.483,0.072c-0.457,0.496-1.015,0.896-1.825,1.033C74.246,89.92,74.931,89.539,75.595,89.539z M71.633,93.152
		c0.115-0.414,0.278-0.779,0.456-1.143c0.043-0.012,0.078-0.018,0.107-0.018c0.316,0-0.078,0.705,0.235,0.705
		C72.287,92.971,71.963,93.068,71.633,93.152z M73.57,87.445c-0.092,0.41-0.529,0.459-0.911,0.574
		C72.785,87.652,73.113,87.479,73.57,87.445z M72.887,85.964c0.331,0.781-0.223,1.581-0.912,1.711
		C71.748,86.893,72.271,86.25,72.887,85.964z M67.696,90.055c-0.329,0-0.423,0.234-0.749,0.246
		c-0.021-0.408,0.366-0.861,0.645-0.861c0.171,0,0.303,0.172,0.273,0.633C67.802,90.061,67.747,90.055,67.696,90.055z M67.31,95.002
		c-0.112,0-0.231,0.027-0.362,0.092c0.056-0.219,0.25-0.287,0.233-0.572c0.342,0.113,0.794,0.125,0.685,0.686
		C67.686,95.088,67.51,95.002,67.31,95.002z M69.347,83.797c0.564,0,0.576,0.924,0.12,1.026
		C69.102,84.801,69.193,83.98,69.347,83.797z M70.036,85.052c0.28,0.456-0.238,2.026-1.144,2.053
		C68.794,85.942,69.5,85.59,70.036,85.052z M68.206,86.649c-0.006-0.767,0.069-1.454,0.343-1.938
		C69.181,85.149,68.605,86.245,68.206,86.649z M70.493,96.24v0.457c-0.297-0.02-0.444-0.166-0.457-0.457H70.493z M69.856,91.098
		c-0.017,0-0.034,0-0.053,0.004c0.03-0.355,0.246-0.516,0.342-0.801h0.016c0.461,0,0.116,0.717,0.098,0.914h-0.021
		c-0.082,0-0.137-0.029-0.191-0.059S69.938,91.098,69.856,91.098z M69.316,100.586c-0.026,0-0.054-0.006-0.084-0.014
		c0.019-0.322-0.062-0.746,0.115-0.912C69.673,99.672,69.713,100.586,69.316,100.586z M70.258,101.037
		c-0.513-0.389,0.108-1.346,0.12-1.941c-0.33-0.562-0.695-1.135-0.455-1.943h0.335C70.994,98.207,70.982,99.822,70.258,101.037z"/>
		</g>
	</svg>
}