import React from 'react';

import ElectionFAQ from "../ElectionFAQ";

import RequireAuth from '../../hocs/RequireAuth';
import { imgQuestion } from '../../misc/icons/icons';

import {NavLink} from 'react-router-dom';
import {PATHS} from '../../Routes';

import PageTitle from '../PageTitle';

class ElectionFAQx extends React.Component {
constructor(props) {
	super(props);
}

render() {
	return (this.faqs());
}

faqs() {
	let faq = [
		{
			question : 'Which political parties have primary election contests on the ballot?',
			answer : (
				<div>
					<p>
						There are various Democratic, Republican, and non-partisan primary election contests throughout
						the state.  <NavLink to={PATHS.CandidatesIssues}>Click here to view the primary election contests in your area.</NavLink>
					</p>
					<p>
						The Presidential Primary Election was held on March 5, 2024, so you will not vote for
						presidential candidates in this election.
					</p>
				</div>)
		},
		{
			question : 'Which political party’s primary election can I vote in?',
			answer : (
				<div className='dtsvote-c-home__inner'>
					<p>Political parties set rules on who can and who cannot vote in its primary election.  Here are the rules:</p>
					<ul>
						<li>
							<strong>Democratic Primary Election</strong>
							- only voters registered with the Democratic Party and unaffiliated voters may vote in the Democratic
							Primary Election.
						</li>
						<li>
							<strong>Republican Primary Election</strong>
							- only voters registered with the Republican Party may vote in the Republican Primary Election.
						</li>
						<li>
							<strong>Non-Partisan Primary Election (such as an election for local school board seat)</strong> - all voters, regardless of party affiliation, may vote in non-partisan primary elections.
						</li>
					</ul>
					<p>
						The voter affiliation deadline for the 2024 June Primary election is January 9, 2024. Party affiliation changes that occur after January 9, 2024 will take effect after the 2024 primary election canvas.
					</p>
				</div>
			)
		},
		{
			question : 'How do I check my party affiliation?',
			answer   : (
				<div>
					<p>
						<NavLink to={PATHS.VoterInfo}>Click this link to check the party affiliation that is on your voter registration record.</NavLink>
					</p>
				</div>
			)
		},
		{
			question : 'How can I change my party affiliation? Is there a deadline to change it before the election?',
			answer   : (
				<div>
					<p>
						You can change your party affiliation by submitting a voter registration to your county clerk or by visiting voter.utah.gov and updating your voter registration.
					</p>
					<p>
						The voter affiliation deadline for the 2024 June Primary election is January 9, 2024. Party affiliation changes that occur after January 9, 2024 will take effect after the 2024 primary election canvas.
					</p>
				</div>
			)
		},
		{
			question : 'Can I vote in both the Republican and Democratic Primary Elections?',
			answer   : (
				<div>
					<p>
						No, you cannot vote in both the Republican and Democratic Primary Election
					</p>
				</div>
			)
		}
	];

	return (
		<div id='ElectionFAQ'>
			<PageTitle image={imgQuestion(50, 50)} title="PRIMARY ELECTION RULES"/>
			<div className="dtsvote-c-home__inner">
				Click on a frequently asked question below for more information.
			</div>
			<div className="dtsvote-c-home__general-content">
				<ElectionFAQ electionFAQs={faq} />
			</div>
		</div>
	);
}

}

export default RequireAuth(ElectionFAQx);

