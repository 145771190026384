import React from 'react';

import {imgLocation} from '../misc/icons/icons';

/**
 * This is a generic address component for address formatting.
 */
class AddressMap extends React.Component {
	message = "View location on Google Maps";

	constructor(props) {
		super(props);
	}

	render() {
		return (this.AddressMap(this.props));
	}

	/**
	 * Returns a map in a new tab
	 * @param props = {
	 *     address,
	 *     city
	 * }
	 * @Returns Google map in new tab
	 * @constructor
	 */
	AddressMap(props) {
		let address;
		let city;
		let returnAddressMap = null;

		if (props.address && props.city) {
			address = props.address;
			city = props.city;

			returnAddressMap = (
				<div className='dtsvote-c-accordion-locations'>
					<span className="dtsvote-u-pr-0d5">{ imgLocation(12, 22) }</span>
					<a href={`http://maps.google.com/maps?q=${address}, ${city}, UT`} target={'_blank'}>{this.message}</a>
				</div>
			);
		}

		return returnAddressMap;
	}
}

export default AddressMap;