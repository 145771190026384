import {
	GLOBAL_ERRORS_SET,
	GLOBAL_ERRORS_CLEAR,
	GLOBAL_GET_APP_VERSION
} from "../actions/_index_action-types";

export const globalReducer = (state = {
	errors: [],
	appVersion: ''
}, action) => {
	switch (action.type) {
		case GLOBAL_ERRORS_SET:
			return {
				...state,
				errors: [...state.errors, action.payload]
			};
		case GLOBAL_ERRORS_CLEAR:
			return {
				...state,
				errors: []
			};
		case GLOBAL_GET_APP_VERSION:
			return {
				...state,
				appVersion: action.payload
			};
		default:
			return state;
	}
};
