import React from 'react';

import {PATHS} from '../../Routes';
import {NavLink} from 'react-router-dom';

import DropBoxLocation from "./DropBoxLocation";
import {replaceBody, replaceBodyBold} from "../../AppVars";
import {isVoteInPersonCounty} from "../../AppVars";

class VoteByMail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabSelected: 0,
			tabs: [
				'Vote By Mail',          // Tab 0
				'Vote Early In Person',  // Tab 1
				'Vote On Election Day'   // Tab 2
			],
			accordionToggleAll: true,
		};
	}

	render() {
		return (this.VoteByMail(this.props.data));
	}

	// Text for primary elections
	primary() {
		const {mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate} = this.props.data.generic;
		const electionMessage = this.props.data.electionMessage;
		const {countyName} = this.props.data.residence || {};
		const {phone, clerkEmail} = this.props.data.residence.countyClerk || {};
		const locations = this.props.data.dropBoxLocations;
		const dropBoxExist = locations != null && locations.length > 0;

		let makeSureBallotIsCounted = (
			<li>
				Make sure your ballot is counted by either:
				<ul>
					<li>Postmarking your ballot on or before Election Day, {postMarkBallotDate}; or</li>
					<li>Dropping your ballot off at a mail ballot drop box by 8:00 pm on Election Day.</li>
				</ul>
			</li>
		);

		if (!dropBoxExist) {
			makeSureBallotIsCounted = (
				<li>
					Make sure your ballot is counted by postmarking your ballot on or before Election
					Day, {postMarkBallotDate}.
				</li>
			);
		}

		if (!dropBoxExist && isVoteInPersonCounty(this.props.data.residence.countyId)) {
			makeSureBallotIsCounted = (
				<li>
					Make sure your ballot is counted by either:
					<ul>
						<li>Postmarking your ballot on or before Election Day, {postMarkBallotDate}; or</li>
						<li>Dropping your ballot off at your county clerk's office or a polling location by 8:00 pm on
							Election Day.
						</li>
					</ul>
				</li>
			);
		}

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p className='dtsvote-u-mt-0'>
					<strong>{countyName} County</strong> is conducting its election by mail. If you are an active
					registered voter,
					you will receive a ballot in the mail if:
				</p>
				<div className='dtsvote-c-home__inner'>
					<ul>
						<li>
							There is a primary election for a non-partisan contest, such as a local school board seat,
							in your area;
						</li>
						<li>
							You are affiliated with the Republican Party on your voter registration;
						</li>
						<li>
							You are affiliated with the Democratic Party on your voter registration and there is a
							Democratic primary election in your area; or
						</li>
						<li>
							You are an unaffiliated voter and you requested a Democratic or Republican primary ballot.
							If you would like to request a ballot, please contact your county clerk’s office. (Please
							note that the Republican Party requires unaffiliated voters to affiliate with the party to
							participate in its primary election.)
						</li>
					</ul>
				</div>
				<p><NavLink to={PATHS.VoterInfo}>You may check your party affiliation on your voter registration at this
					link.</NavLink> If you meet any of the requirements above, follow the steps below to vote:</p>
				<div className='dtsvote-c-home__inner'>
					<ol>
						<li>
							Your ballot will be mailed to you between {mailBoxBallotDate}.
							Didn’t receive your ballot? Immediately contact the {countyName} County Clerk
							at {phone} or {clerkEmail}.
						</li>
						<li>Follow the instructions provided with the ballot.</li>
						{makeSureBallotIsCounted}
					</ol>
				</div>
			</div>
			<DropBoxLocation dropBoxLocations={locations}/>
		</div>;
	}

	// Text for vote by mail tab if the county is by mail and the election is municipal.
	mailMunicipal() {
		const {mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate} = this.props.data.generic;
		const electionMessage = this.props.data.electionMessage;
		const locations = this.props.data.dropBoxLocations;
		const dropBoxExist = locations != null && locations.length > 0;

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p>{electionMessage} Your municipality is conducting a mail election. Follow the steps below to
					vote:</p>
				<ol>
					<li>If you are an active registered voter, your ballot will be mailed to you
						between {mailBoxBallotDate}. Didn&rsquo;t receive your ballot? Immediately contact your
						municipal clerk or recorder.
					</li>
					<li>Follow the instructions provided with the ballot.</li>
					<li>
						Make sure your ballot is counted by either:
						<ul>
							<li>Postmarking your ballot on or before {postMarkBallotDate} (the day before the
								election).
							</li>
							{dropBoxExist
								?
								<li>Dropping your ballot off at your local election official’s office, a polling
									location, or a drop box by 8:00 pm on Election Day. Below is a list of drop box
									locations in your area:</li>
								:
								<li>Dropping your ballot off at your local election official’s office or a polling
									location by 8:00 pm on Election Day.</li>
							}
						</ul>
					</li>
				</ol>
			</div>
			<DropBoxLocation dropBoxLocations={locations}/>
		</div>;
	}

	// Text for vote by mail tab if the county is by mail and the election is general.
	mailGeneral() {
		const {mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate} = this.props.data.generic;
		const electionMessage = this.props.data.electionMessage;
		const {countyName} = this.props.data.residence || {};
		const {phone, clerkEmail} = this.props.data.residence.countyClerk || {};
		const locations = this.props.data.dropBoxLocations;
		const dropBoxExist = locations != null && locations.length > 0;

		return <div><div className='dtsvote-c-home__inner'>
			<p className='dtsvote-u-mt-0'><strong>{electionMessage} {countyName} County</strong> is conducting a mail election. Follow the steps below to vote:</p>
			<ol>
				<li>If you are an active registered voter, your ballot will be mailed to you between {mailBoxBallotDate}. Didn&rsquo;t receive your ballot? Immediately contact the {countyName} County Clerk at {phone} or {clerkEmail}.</li>
				<li>Follow the instructions provided with the ballot.</li>
				<li>
					Make sure your ballot is counted by either:
					<ul>
						<li>Postmarking your ballot on or before {postMarkBallotDate} (the day before the election).</li>
						{ dropBoxExist
							?
							<li>Dropping your ballot off at your county clerk's office, a polling location, or a drop box by 8:00 pm on Election Day. Below is a list of drop box locations for {countyName} County.</li>
							:
							<li>Dropping your ballot off at your county clerk's office or a polling location by 8:00 PM on Election Day.</li>
						}
					</ul>
				</li>
			</ol>
		</div>
			<DropBoxLocation dropBoxLocations={locations} />
		</div>;
	}

	// Text for vote by mail tab if the county is NOT by mail and the election is general.
	nonMailGeneral() {
		const {mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate} = this.props.data.generic;
		const {countyName} = this.props.data.residence || {};
		const {phone, clerkEmail} = this.props.data.residence.countyClerk || {};
		const locations = this.props.data.dropBoxLocations;
		const dropBoxExist = locations != null && locations.length > 0;

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p className='dtsvote-u-mt-0'><strong>{countyName} County</strong> is <span
					className='dtsvote-u-text-und'><strong>not</strong></span> conducting a mail election. If you would
					like to vote by mail, follow these steps below:</p>
				<ol>
					<li>Apply for a mail ballot. If you have a current Utah driver license, you can apply <a
						href='https://secure.utah.gov/voterreg/index.html'>online</a>. You can also apply by submitting
						a <a
							href='https://elections.utah.gov/Media/Default/Documents/Elections%20Resources/Absentee%20Ballot%20Application.pdf'>paper
							form</a>. The {countyName} County Clerk must receive your application on or
						before {nonMailReceivedDate}.
					</li>
					<li>Check your mailbox for your ballot around {mailBoxBallotDate}. If you requested your ballot
						after this date, you can expect the ballot to arrive within several days. Didn't receive your
						ballot yet? Immediately contact the {countyName} County Clerk at {phone} or {clerkEmail}</li>
					<li>After you receive the ballot, follow the instructions provided with the ballot.</li>
					<li>Make sure your ballot is counted by either:</li>
					<ul>
						<li>Postmarking your ballot on or before {postMarkBallotDate} (the day before the election).
						</li>
						<span className='dtsvote-u-d-block dtsvote-u-mt-1'><strong>OR</strong></span>
						{dropBoxExist ?
							<li>Dropping your ballot off at your county clerk's office, a polling location, or a drop
								box by 8:00 pm on Election Day. Below is a list of drop box locations
								for {countyName} County.</li>
							:
							<li>Dropping your ballot off at your county clerk's office or a polling location by 8:00 PM
								on Election Day.</li>
						}
					</ul>
				</ol>
			</div>
			<DropBoxLocation dropBoxLocations={locations}/>
		</div>;
	}

	// Text for vote by mail tab if the county is NOT by mail and the election is municipal.
	nonMailMunicipal() {
		const {mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate, electionMessage} = this.props.data.generic;
		const {countyName} = this.props.data.residence || {};
		const locations = this.props.data.dropBoxLocations;
		const dropBoxExist = locations != null && locations.length > 0;

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p className='dtsvote-u-mt-0'>Your municipality {electionMessage} is <span
					className='dtsvote-u-text-und'><strong>not</strong></span> conducting a mail election. If you would
					like to vote by mail, follow these steps below:</p>
				<ol>
					<li>Apply for a mail ballot. If you have a current Utah driver license, you can apply <a
						href='https://secure.utah.gov/voterreg/index.html'>online</a>. You can also apply by submitting
						a <a
							href='https://elections.utah.gov/Media/Default/Documents/Elections%20Resources/Absentee%20Ballot%20Application.pdf'>paper
							form</a>. The {countyName} County Clerk must receive your application on or
						before {nonMailReceivedDate}.
					</li>
					<li>Check your mailbox for your ballot between {mailBoxBallotDate}. If you requested your ballot
						after this date, you can expect the ballot to arrive within several days. Didn't receive your
						ballot yet? Immediately contact your municipal clerk or recorder.
					</li>
					<li>After you receive the ballot, follow the instructions provided with the ballot.</li>
					<li>Make sure your ballot is counted by either:</li>
					<ul>
						<li>Postmarking your ballot on or before {postMarkBallotDate} (the day before the election).
						</li>
						<span className='dtsvote-u-d-block dtsvote-u-mt-1'><strong>OR</strong></span>
						{dropBoxExist ?
							<li>Dropping your ballot off at your local election official’s office, a polling location,
								or a drop box by 8:00 pm on Election Day. Below is a list of drop box locations in your
								area.</li>
							:
							<li>Dropping your ballot off at your local election official’s office or a polling location
								by 8:00 pm on Election Day.</li>
						}
					</ul>
				</ol>
			</div>
			<DropBoxLocation dropBoxLocations={locations}/>
		</div>;
	}

	nonOverseeElection() {
		const {mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate} = this.props.data.generic;
		const electionMessage = this.props.data.electionMessage;
		const {countyName} = this.props.data.residence || {};
		const {phone, clerkEmail} = this.props.data.residence.countyClerk || {};
		const locations = this.props.data.dropBoxLocations;
		const dropBoxExist = locations != null && locations.length > 0;

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p className='dtsvote-u-mt-0'>The availability of early voting times and locations will vary across the
					state. For more information about early and election day voting please contact your county clerk's
					office for details. Follow the steps below to vote in the primary election.</p>
				<ol>
					<li>If you are an active registered voter, your ballot will be mailed to you
						between {mailBoxBallotDate}. For assistance, contact the {countyName} County Clerk
						at {phone} or {clerkEmail}.
					</li>
					<li>Follow the instructions provided with the ballot.</li>
					<li>
						Make sure your ballot is counted by either:
						<ul>
							<li>Postmarking your ballot on or before {postMarkBallotDate} (the day before the
								election).
							</li>
							{dropBoxExist
								?
								<li>Dropping your ballot off at your county clerk's office, a polling location, or a
									drop box by 8:00 pm on Election Day. Below is a list of drop box locations
									for {countyName} County.</li>
								:
								<li>Dropping your ballot off at your county clerk's office or a polling location by 8:00
									PM on Election Day.</li>
							}
						</ul>
					</li>
				</ol>
			</div>
			<DropBoxLocation dropBoxLocations={locations}/>
		</div>;
	}

	VoteByMail(data) {
		const {countyId, electionId, electionMessage} = data.residence;
		const precinctId = data.residence.precinct.precinctId;
		const electionPhase = data.generic.electionPhase;

		if (electionMessage) {
			return replaceBody(canceledElectionMessage)
		}

		if (countyId && precinctId && electionId) {
			if (!data.dropBoxLocations) {
				data.voteWhereGetDropBoxLocations(data.residence.countyId);
			}

			if (data.residence.precinct && data.residence.precinct.byMail === 0) {
				switch (electionPhase) {
					case 'Municipal':
						return this.nonMailMunicipal();
						break;
					default :
						return this.nonMailGeneral();
				}
			} else {
				switch (electionPhase) {
					case 'Municipal':
						return this.mailMunicipal();
						break;
					default :
						return this.mailGeneral();
				}
			}
		} else if (data.generic && data.generic.electionPhase === 'Municipal') {
			return replaceBody(noMunicipalElectionMessage);
		} else {
			return replaceBodyBold(noElectionMessage);
		}
	}
}

export default VoteByMail;
