import {createUserManager} from 'redux-oidc';

const userManagerConfig = {
	client_id: 'Auca-Crabbe-893289',
	// tell where to go once user is logged in: must be an entry in apadmin
	redirect_uri: `${window.location.origin}/voter-search/search/login/callback`,
	// not sure what silent renew does nor how it works
	redirect_uri_silent_renew: 	`${window.location.origin}/voter-search/search/login/silent_renew`,

	response_type: 'id_token token',
	scope: 'openid profile email directory',
	authority: 'https://login.dts.utah.gov:443/sso/oauth2',
	automaticSilentRenew: true,
	filterProtocolClaims: false,
	loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;