import React from 'react';
import PropTypes from 'prop-types';

import { HomeRoutes } from '../../Routes';
import SidebarNav from './SidebarNav';

class Home extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return(
			<div id='dtsvote-home' className='dtsvote-c-home'>
				<SidebarNav />

				<main id='main-content' className='dtsvote-c-home__content'>
					<section id='section-content'>
						<HomeRoutes />
					</section>
				</main>
			</div>
		);
	}
}

Home.propTypes = {

};

Home.defaultProps = {

};

export default Home;
