import React from 'react';

import Accordion from './Accordion';
import AccordionHeaderLabel from "./AccordionHeaderLabel";

/**
 * This is a component to render election FAQs.
 */
class ElectionFAQ extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			accordionToggleAll: true
		};
	}

	render() {
		return (this.renderElectionFAQ(this.props));
	}

	renderElectionFAQ = () => {
		const electionFAQs = this.props.electionFAQs;
		if (!electionFAQs || electionFAQs.length === 0) {
			return (<div id="noElectionFAQs"></div>);
		}

		const list = electionFAQs.map(electionFAQ => {
			const {question, answer} = electionFAQ;

			const accordionHeader = {
				header : question
			};
			const headerLabel = <AccordionHeaderLabel accordionHeader={accordionHeader} />;

			return {
				headerLabel: headerLabel,
				panelContents: <div className='dtsvote-u-pb-1'>
					<div className='dtsvote-u-ml-0d5'>
						<div>
							{answer}
						</div>
					</div>
				</div>
			}
		});

		return <div>
			<h3 className='dtsvote-fs-h5 dtsvote-u-pl-1'>Election FAQs</h3>
			<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
					onClick={() => {
						this.state.accordionToggleAll
							? Accordion.expandAll('dtsvote')
							: Accordion.collapseAll('dtsvote');
						this.setState({
							accordionToggleAll: !this.state.accordionToggleAll});
					}}>
				{this.state.accordionToggleAll ? 'Expand All' : 'Collapse All'}
			</button>
			<Accordion
				list={list}
				id='acc3'
				headingLevel={2}
				namespace='dtsvote'
			/>
		</div>
	};
}

export default ElectionFAQ;