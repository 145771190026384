import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

import '../../css/index.scss';
import rootReducer from './reducers/_index_reducers';
import { AppRoutes } from './Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import { GlobalPopup } from "./misc/util";
import { loadUtahHeader} from "dts-utah-gov-header";
import userManager from './misc/UserManager';
import {OidcProvider, loadUser} from "redux-oidc";
//import { Log } from 'oidc-client';

export const URL_BASENAME = '/voter-search';
export const SESSION_STORAGE_KEY = 'DTS_VOTER-SEARCH';
export const SEARCH_TYPES = { ADDRESS: 'address', VOTER: 'voter' };

//Log.logger = console;
//Log.level = Log.DEBUG;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer,  {}, composeEnhancers(applyMiddleware(thunk)));
loadUser(store, userManager);

import DebugButtons from './components/__DEBUG-BUTTONS__'; // todo remove

class App extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		loadUtahHeader({
			backgroundColor: '#f1f1f1',
			foregroundColor: '#393939',
			darkColorScheme: false,
			height: '32px',
			agencyName: 'Vote.Utah.gov',
			agencyShortName: 'Vote.Utah.gov',
			agencyLink: 'https://vote.utah.gov',
			fontWeights: [300,400,700],
			defaultFontWeight: 400,
			defaultFontSize: 16,
			defaultRootEl: 'html',
			skipNav: true,
			mainContentId: 'main-content'
		});
	}

	render() {
		const show = (location.hostname === "localhost" || location.hostname.includes("dev"));

        return (
            <div id='dtsvote-app'>
				<Header />
				<div id='dtsvote-main-content'>
					<AppRoutes />
				</div>
				<Footer />
				{show ? <DebugButtons /> : null }
				<GlobalPopup />
            </div>
        );
    }
}

const AppWrapped =
	<BrowserRouter basename={URL_BASENAME}>
		<Provider store={store}>
			<OidcProvider store={store} userManager={userManager}>
				<App/>
			</OidcProvider>
		</Provider>
	</BrowserRouter>;

render(AppWrapped, document.getElementById('root'));
