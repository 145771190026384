import axios from 'axios';

import {URL_BASENAME} from '../App';
import {
	SAMPLEBALLOT_GET_DATA,
	SAMPLEBALLOT_CLEAR_DATA,
	SAMPLEBALLOT_GET_PROFILE_REQ,
	SAMPLEBALLOT_CLEAR_PROFILE,
	GLOBAL_ERRORS_SET
} from './_index_action-types';

export const sampleBallotGetData = (precinctId, electionId) => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/sampleBallot.json/${precinctId}/${electionId}`
	})
		.then(res => {
			dispatch({type: SAMPLEBALLOT_GET_DATA, payload: res.data});
		})
		.catch(() => {
			dispatch({
				type: GLOBAL_ERRORS_SET,
				payload: 'Error fetching sample ballot data. Refresh the page and try again.'
			});
		});
};

export const sampleBallotResetData = () => dispatch => {
	dispatch({type: SAMPLEBALLOT_CLEAR_DATA, payload: null});
};

export const sampleBallotGetProfile = candidateId => dispatch => {
	dispatch({type: SAMPLEBALLOT_GET_PROFILE_REQ, payload: null});
	const pdfWindow = window.open();

	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/getCandidateProfilePublic.json/${candidateId}`,
		responseType: 'blob'
	})
		.then(response => {
			if (response.status == 200) {
				//Create a Blob from the PDF Stream
				const file = new Blob([response.data], {type: "application/pdf"});
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				//Open the URL on new Window
				pdfWindow.location.href = fileURL;

				dispatch({type: SAMPLEBALLOT_CLEAR_PROFILE, payload: null});
			} else {
				dispatch({
					type: GLOBAL_ERRORS_SET,
					payload: "No profile has been uploaded for this candidate."
				});
				dispatch({type: SAMPLEBALLOT_CLEAR_PROFILE, payload: null});
			}
		})
		.catch(error => {
			dispatch({
				type: GLOBAL_ERRORS_SET,
				payload: "Error retrieving Profile."
			});
			dispatch({type: SAMPLEBALLOT_CLEAR_PROFILE, payload: null});
		});
};

export const sampleBallotClearProfile = () => dispatch => {
	dispatch({type: SAMPLEBALLOT_CLEAR_PROFILE, payload: null});
};
