import React from 'react';
import PropTypes from 'prop-types';

import { circleDown } from '../misc/icons/icons';

export default class Accordion extends React.Component {
	constructor(props) {
		super(props);
	}

	handleClick = (e, ns) => {
		const el = e.currentTarget;
		const panelEl = document.getElementById(
			el.getAttribute('aria-controls'));

		if (el.getAttribute('aria-expanded') === 'true') {
			el.setAttribute('aria-expanded', false);
			panelEl.classList.remove(`${ns}-c-accordion__panel--expanded`);
			panelEl.classList.add(`${ns}-c-accordion__panel--collapsed`);
		} else {
			el.setAttribute('aria-expanded', true);
			panelEl.classList.remove(`${ns}-c-accordion__panel--collapsed`);
			panelEl.classList.add(`${ns}-c-accordion__panel--expanded`);
		}
	};

	renderAccordionItems = () => {
		const {namespace: ns, id} = this.props;
		return this.props.list.map((el, i) => {
			const panelState = el.isOpen
				? `${ns}-c-accordion__panel--expanded`
				: `${ns}-c-accordion__panel--collapsed`;

			return ([
				<dt className={`${ns}-c-accordion__heading`}
					aria-level={this.props.headingLevel}
					role='heading'
					key={`${id}` + (0 + i)}
				>
					<button
						className={`${ns}-c-accordion__trigger`}
						id={`${id}-head${i}`}
						aria-expanded={el.isOpen}
						aria-controls={`${id}-sect${i}`}
						type='button'
						onClick={e => this.handleClick(e, ns)}
					>
						<span className={`${ns}-c-accordion__label`}>
							{ el.headerLabel }
						</span>
						<span className={`${ns}-c-accordion__icon`}>
							{ circleDown() }
						</span>
					</button>
				</dt>,
				<dd className={`${ns}-c-accordion__panel ${panelState}`}
					id={`${id}-sect${i}`}
					aria-labelledby={`${id}-head${i}`}
					role='region'
					key={`${id}` + (1 + i)}
				>
					{ el.panelContents }
				</dd>
			]);
		});
	};

	render() {
		if (this.props.list.length < 1) { return (null); }

		return (
			<dl className={`${this.props.namespace}-c-accordion `
							+ this.props.classNames }
				id={this.props.id}
				role='presentation'
			>
				{ this.renderAccordionItems() }
			</dl>
		);
	}
}

Accordion.propTypes = {
// REQUIRED PROPS

	// The heading level used on the accordion item headers.
	headingLevel: PropTypes.number.isRequired,

	// Unique reference to the accordion.
	id: PropTypes.string.isRequired,

	// [ { headerLabel: any, panelContents: any, isOpen: bool }, ... ]
	list: PropTypes.array.isRequired,

// OPTIONAL PROPS

	// Additional class string to add to root level container.
	classNames: PropTypes.string,

	// Prefix the root level container with a specific namespace.
	namespace: PropTypes.string,
};

Accordion.defaultProps = {
	classNames: '',
	namespace: 'dts',
	showToggleAllButtons: true
};

/**
 * Collapse all <Accordion> panels for the given namespace.
 * @param namespace {String} the used namespace for <Accordion>
 */
Accordion.collapseAll = (namespace = 'dts') => {
	const triggers = document.querySelectorAll(
		`.${namespace}-c-accordion__trigger`
	);

	triggers.forEach(el => {
		el.setAttribute('aria-expanded', false);
		const panelEl = document.getElementById(
			el.getAttribute('aria-controls'));
		panelEl.classList.remove(`${namespace}-c-accordion__panel--expanded`);
		panelEl.classList.add(`${namespace}-c-accordion__panel--collapsed`);
	});
};

/**
 * Expand all <Accordion> panels for the given namespace.
 * @param namespace {String} the used namespace for <Accordion>
 */
Accordion.expandAll = (namespace = 'dts') => {
	const triggers = document.querySelectorAll(
		`.${namespace}-c-accordion__trigger`
	);

	triggers.forEach(el => {
		el.setAttribute('aria-expanded', true);
		const panelEl = document.getElementById(
			el.getAttribute('aria-controls'));
		panelEl.classList.remove(`${namespace}-c-accordion__panel--collapsed`);
		panelEl.classList.add(`${namespace}-c-accordion__panel--expanded`);
	});
};

/**
 * ARIA Guidelines for Accordion:
 * https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/#accordion
 *
 * todo: optional keyboard navigation controls.
 */
