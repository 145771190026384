import {
	CANDIDATE_VERIFY_PASSCODE_REQ,
	CANDIDATE_VERIFY_PASSCODE_RES,
	CANDIDATE_GET_LIST_REQ,
	CANDIDATE_GET_LIST_RES,
	CANDIDATE_UPDATE_REQ,
	CANDIDATE_UPDATE_RES,
	CANDIDATE_REQUEST_PASSCODE,
	CANDIDATE_RESET_FETCHING,
	CANDIDATE_GET_LIST_ERROR
} from '../actions/_index_action-types';

export const candidateReducer = (state = {
	isFetching: false,
	authData: null,
	passCodeData: null,
	list: null,
	listError: null,
	saveResult: null
}, action) => {
	switch (action.type) {
		case CANDIDATE_VERIFY_PASSCODE_REQ:
			return {
				...state,
				isFetching: true
			};
		case CANDIDATE_VERIFY_PASSCODE_RES:
			return {
				...state,
				isFetching: false,
				passCodeData: action.payload
			};
		case CANDIDATE_GET_LIST_REQ:
			return {
				...state,
				isFetching: true
			};
		case CANDIDATE_GET_LIST_RES:
			return {
				...state,
				isFetching: false,
				list: action.payload
			};
		case CANDIDATE_GET_LIST_ERROR:
			return {
				...state,
				listError: action.payload
			};
		case CANDIDATE_UPDATE_REQ:
			return {
				...state,
				isFetching: true
			};
		case CANDIDATE_UPDATE_RES:
			return {
				...state,
				isFetching: false,
				saveResult: action.payload
			};
		case CANDIDATE_RESET_FETCHING:
			return {
				...state,
				isFetching: false,
			};
		case CANDIDATE_REQUEST_PASSCODE:
		default:
			return state;
	}
};
