import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	InputList,
	InputInformation,
	MaskType,
	Components,
	Button,
	ValidationStyle,
	ValidationType,
	validationEngineFromInputInformationList
} from 'dts-react-common';

import ClearInactive from  '../hocs/ClearInactive';
import { SEARCH_TYPES } from '../App';
import {
	searchByAddress,
	searchByVoter,
	searchGetGeneric
} from '../actions/_index_actions';
import { getLocalAuthDetails } from '../misc/util';
import { links } from './Home/SidebarNav';

class Search extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.getExistingInputs();
		this.state.validationStyle = ValidationStyle.BLURRED;
		this.links = links();
		this.referringContext = this.getReferringContext();
		this.validationEngine = null;
		this.instructions = null;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.searchGetGeneric();

		switch (this.props.searchBy) {
			case SEARCH_TYPES.VOTER:
				if (this.state.street && this.state.street.length > 0) {
					this.instructions = 'We need a little more information from you to check your voter registration or track your mail ballot! Please enter your name and birth date below:'
				} else {
					this.instructions = 'Enter your personal information and address below:'
				}
				this.instructions2 = 'This tool displays tracking information for by mail ballots or provisional ballots. It does not display information  if you voted in-person on a voting machine at a polling location.';
				break;
			default:
				this.instructions = 'Enter your information below:';
		}
	}

	getExistingInputs = () => {
		if (this.props.inputs) {
			return this.props.inputs;
		}

		const authDetails = getLocalAuthDetails();
		if (authDetails !== false && authDetails.inputs) {
			return authDetails.inputs;
		}

		return {
			firstName: '',
			lastName: '',
			dob: '',
			street: '',
			city: '',
			zip: ''
		};
	};

	getReferringContext = () => {
		let next = this.props.next; // From url param
		if (!next) {
			// From router location state
			next = this.props.location.state ? this.props.location.state.next : undefined;
			if (next) {
				next = next.split('/');
				next = next[next.length - 1];
			}
		}

		let context = undefined;
		if (next) {
			for (let i = 0; i < this.links.length; i++) {
				let path = this.links[i].path.split('/');
				path = path[path.length - 1];

				if (next === path) {
					context = this.links[i].text;
					break;
				}
			}
		}

		// Default to How Can I Vote? if nothing found.
		if (context === undefined) {
			context = this.links[0].text;
		}

		return context;
	};

	handleInput = (field, value) => {
		this.setState({ [field]: value });
	};

	doSearch() {
		this.setState({ validationStyle: ValidationStyle.ALWAYS });
		const formIsValid = this.validationEngine.isValid(this.state);

		if (formIsValid) {
			let formData = {...this.state};

			let nextFromRedirect = null;
			if (this.props.location.state && this.props.location.state.next) {
				nextFromRedirect = this.props.location.state.next;
			}

			this.props.searchBy === SEARCH_TYPES.ADDRESS
			? this.props.searchByAddress(
				formData, this.props.history, this.props.next, nextFromRedirect
			)
			: this.props.searchByVoter(
				formData, this.props.history, this.props.next, nextFromRedirect
			);
		}
	}

	getFieldError = field => {
		return this.props.errors
			? this.props.errors[field]
				? <label className='error' id={'field-error-${field}'} htmlFor={field}>
					{ this.props.errors[field] }
				</label>
				: undefined
			: undefined;
	};

	render() {
		const inputs = [
			new InputInformation({
				type: Components.TEXT_INPUT,
				label: 'Street Address',
				field: 'street',
				value: this.state.street,
				onChange: this.handleInput.bind(this),
				showLabel: true,
				required: true,
				children: this.getFieldError('street'),
				validationTypes: [ValidationType.REQUIRED]
			}),
			new InputInformation({
				type: Components.TEXT_INPUT,
				label: 'City',
				field: 'city',
				value: this.state.city,
				onChange: this.handleInput.bind(this),
				showLabel: true,
				required: true,
				children: this.getFieldError('city'),
				validationTypes: [ValidationType.REQUIRED]
			}),
			new InputInformation({
				type: Components.TEXT_INPUT,
				label: 'Zip',
				field: 'zip',
				value: this.state.zip,
				onChange: this.handleInput.bind(this),
				showLabel: true,
				required: true,
				maskType: MaskType.ZIPCODE,
				children: this.getFieldError('zip'),
				className: 'dtsvote-u-text-left',
				validationTypes: [ValidationType.REQUIRED]
			}),
		];

		// Additional fields if searching by voter.
		this.props.searchBy === 'voter' && inputs.unshift(
			new InputInformation({
				type: Components.TEXT_INPUT,
				label: 'First Name',
				field: 'firstName',
				value: this.state.firstName,
				onChange: this.handleInput.bind(this),
				showLabel: true,
				required: true,
				children: this.getFieldError('firstName'),
				validationTypes: [ValidationType.REQUIRED]
			}),
			new InputInformation({
				type: Components.TEXT_INPUT,
				label: 'Last Name',
				field: 'lastName',
				value: this.state.lastName,
				onChange: this.handleInput.bind(this),
				showLabel: true,
				required: true,
				children: this.getFieldError('lastName'),
				validationTypes: [ValidationType.REQUIRED]
			}),
			new InputInformation({
				type: Components.TEXT_INPUT,
				label: 'Date of Birth',
				field: 'dob',
				value: this.state.dob,
				onChange: this.handleInput.bind(this),
				showLabel: true,
				required: true,
				maskType: MaskType.DATE,
				children: this.getFieldError('dob'),
				validationTypes: [ValidationType.REQUIRED]
			})
		);

		this.validationEngine = validationEngineFromInputInformationList(inputs);
		this.validationEngine.setValidationStyle(this.state.validationStyle);
		this.validationEngine.isValid(this.state);

		// Change title of address form if election phase is caucus
		const isCaucus = this.props.generic && this.props.generic.electionPhase === 'Caucus';
		let formTitle = 'How can I vote?';
		if (isCaucus && formTitle === this.referringContext) {
			formTitle = 'Find My Caucus Information';
		} else {
			formTitle = this.referringContext;
		}

		// Set specific error message for voter info
		let errorMessage = this.getFieldError('errorMessage');
		if (formTitle === 'Find my voter registration info' && typeof errorMessage !== 'undefined') {
			errorMessage = (
				<label class="error" id="field-error-${field}" for="errorMessage">
					There was an error with the search! Check your information again.
					<br /><br />
					If your information is correct, you may not be registered to vote or registered to vote or registered at this address. &nbsp;
					<a href='https://vote.utah.gov/additionalInfo.html'>Click here to register to vote or update your voter registration</a>.
				</label>
			);
		}

		return(
			<div id='dtsvote-search'
				 className='dtsvote-c-search dtsvote-u-mt-2'>
			<main id='main-content'>
			<section>
				<h1 className='dtsvote-u-text-center dtsvote-u-mt-0 dtsvote-u-text-white'>{formTitle}</h1>
				<p> { this.instructions2 } </p>
				<p> { this.instructions } </p>
				<form id='dtsvote-search-form' className='dtsvote-u-mt-1d5' autoComplete='off'>
					<InputList
						inputs={inputs}
						validationEngine={this.validationEngine}
					/>
					<Button
						label='SUBMIT'
						className='dtsvote-btn dtsvote-btn--primary
							dtsvote-u-d-block dtsvote-u-mx-auto dtsvote-u-px-3
							dtsvote-u-mt-1 '
						onClick={this.doSearch.bind(this)}
						busy={this.props.isFetching}
					/>
					{ this.getFieldError('requestError') }
					{ errorMessage }
				</form>
			</section>
			</main>
			</div>
		);
	}
}

Search.propTypes = {
	// Redux action creator to dispatch fetch, status, and errors.
	searchByAddress: PropTypes.func.isRequired,

	// Redux action creator to dispatch fetch, status, and errors.
	searchByVoter: PropTypes.func.isRequired,

	// Redux action creator to fetch misc. app data.
	searchGetGeneric: PropTypes.func.isRequired,

	// Currently making an async request?
	isFetching: PropTypes.bool.isRequired,

	// Form errors.
	errors: PropTypes.object,

	// Dispatched form input values that didn't generate errors.
	inputs: PropTypes.object,

	// Misc. app data (eg election phase).
	generic: PropTypes.object,

	// searchByAddress OR searchByVoter form view.
	searchBy: PropTypes.string.isRequired,

	// Optional URL param of which page to pass to after auth.
	next: PropTypes.string,

	// From react-router.
	location: PropTypes.object.isRequired
};

function mapStateToProps({ search }) {
	return {
		errors: search.errors,
		isFetching: search.isFetching,
		inputs: search.inputs,
		generic: search.generic
	};
}

export default connect(mapStateToProps, {
	searchByAddress, searchByVoter, searchGetGeneric })(ClearInactive(Search));
