import axios from 'axios';

import { URL_BASENAME } from '../App';
import {
	VOTEWHERE_GET_EARLYLOCS,
	VOTEWHERE_GET_ELECTIONLOCS,
	VOTEWHERE_RESET,
	VOTEWHERE_GET_DROPBOXLOCS,
	GLOBAL_ERRORS_SET
} from './_index_action-types';

export const voteWhereGetEarlyLocs = countyId => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/earlyPollLocations.json/${countyId}`
	})
	.then(res => {
		dispatch({ type: VOTEWHERE_GET_EARLYLOCS, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching voting locations. Refresh the page and try again.'
		});
	});
};

export const voteWhereGetElectionDayLocs = (countyId, precinctId, electionId) => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/otherElectionPollingLocations.json/${countyId}/${precinctId}/${electionId}`
	})
	.then(res => {
		dispatch({ type: VOTEWHERE_GET_ELECTIONLOCS, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching voting locations. Refresh the page and try again.'
		});
	});
};

export const voteWhereReset = () => dispatch => {
	dispatch({ type: VOTEWHERE_RESET, payload: null });
};

export const voteWhereGetDropBoxLocations = countyId => dispatch => {
	axios({
		method: 'GET',
		url: `${URL_BASENAME}/api/dropBoxLocations.json/${countyId}`
	})
	.then(res => {
		dispatch({ type: VOTEWHERE_GET_DROPBOXLOCS, payload: res.data });
	})
	.catch(() => {
		dispatch({ type: GLOBAL_ERRORS_SET,
			payload: 'Error fetching drop box locations. Refresh the page and try again.'
		});
	});
};
