import React from 'react';

class PageTitle extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (this.PageTitle(this.props));
	}

	/**
	 *
	 * @param props {image (from misc/icons/icons), title (page title)}
	 * @returns {*}
	 * @constructor
	 */
	PageTitle(props) {
		let image;
		let title;

		if (props && props.image) {
			image = props.image;
		}

		if (props && props.title) {
			title = props.title;
		}

		let pageTitle = (
			<div className='dtsvote-c-home__title'>
				{image}
				<h1 className='dtsvote-c-home__title__text'>{title}</h1>
			</div>
		);

		return pageTitle;
	}
}

export default PageTitle;