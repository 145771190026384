import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'dts-react-common';

import { SEARCH_TYPES } from '../../App';
import {isVoteInPersonCounty} from "../../AppVars";
import { PATHS } from '../../Routes';
import { searchLogout, searchGetGeneric } from '../../actions/search_actions';
import {
	imgLocation,
	imgCandidates,
	imgCheck,
	imgSearch,
	imgPencil,
	imgMailClosed,
	imgPhone,
	imgId,
	imgTwitter,
	imgFacebook,
	imgQuestion
} from '../../misc/icons/icons';

import Address from '../Address';

const links = () => [
	{
		text: 'How can I vote?',
		path: PATHS.HomeAsVoter,
		img: imgLocation
	},
	{
		text: 'Learn about the candidates and issues',
		path: PATHS.CandidatesIssues,
		img: imgCandidates
	},
	{
		text: 'Track my mail or provisional ballot',
		path: PATHS.TrackMailBallot,
		img: imgCheck
	},
	{
		text: 'Find my voter registration info',
		path: PATHS.VoterInfo,
		img: imgSearch
	},
	{
		text: 'Register to vote or update my voter registration',
		path: PATHS.RegisterToVote,
		img: imgPencil,
		external: true
	},
	{
		text: 'Update Ballot Mailing Address',
		path: PATHS.RequestAMailBallot,
		img: imgMailClosed,
		external: true
	},
	{
		text: 'Contact my elected officials',
		path: PATHS.ContactOfficial,
		img: imgPhone
	},
	{
		text: 'Voter ID Requirements',
		path: PATHS.VoterIdRequirements,
		img: imgId
	},
	// {
	// 	text: 'Election FAQs',
	// 	path: PATHS.ElectionFAQ,
	// 	img: imgQuestion
	// },
	{
		text: 'Facebook',
		path: PATHS.Facebook,
		img: imgFacebook,
		external: true,
		mobileMenuOnly: true
	},
	{
		text: 'Twitter',
		path: PATHS.Twitter,
		img: imgTwitter,
		external: true,
		mobileMenuOnly: true
	}
];

class SidebarNav extends React.Component {
	constructor(props) {
		super(props);

		this.ElectionInfo = this.ElectionInfo.bind(this);
		this.PrecinctInfo = this.PrecinctInfo.bind(this);
		this.links = links();
	}

	handleLinkClick = () => {
		window.scrollTo(0, 0);

		const MEDIA_SM = 768;
		if(window.innerWidth <= MEDIA_SM) {
			this.handleMenuButton();
		}
	};

	renderLink = i => {
		const isCaucus = this.props.generic && this.props.generic.electionPhase === 'Caucus';
		const isPrimary = this.props.generic && this.props.generic.electionPhase === 'Primary';
		const electionName = this.props.data && this.props.data.election && this.props.data.election.name;
		const countyId = this.props.data.residence.countyId;
		let menuTitle = this.links[i].text;

		let isInPersonCounty = isVoteInPersonCounty(countyId);

		// Override menu items
		if (i === 0) {
			if (isCaucus) {
				menuTitle = 'My Caucus Location';
			} else if (isInPersonCounty) {
				this.links[0].path = PATHS.HowWhereToVote;
			}
		}

		if (i === 2 && isCaucus && electionName) {
			menuTitle = 'Track My ' + electionName + ' Ballot';
		}

		if (i === 8 && isPrimary && electionName) {
			menuTitle = electionName + ' Election FAQs';
		}
		// End override menu items

		const linkInner = <div className='dtsvote-c-home__nav__item'>
			{ this.links[i].img(22,22) }
			<span className='dtsvote-c-home__nav__text'>{menuTitle}</span>
		</div>;

		if (this.links[i].mobileMenuOnly) {
			return <li key={i} className='dtsvote-c-home__nav__item--media-sm'>
				<a href={this.links[i].path}>
					{ linkInner }
				</a>
			</li>;
		} else {
			if (this.links[i].external) {
				return <li key={i}>
					<a href={this.links[i].path}>
						{ linkInner }
					</a>
				</li>;
			}

			return <li key={i}>
				<NavLink to={this.links[i].path} onClick={this.handleLinkClick}
					isActive={(match, location) => {
						if (!match) {
							if (i === 0 && (location.pathname === '/resident/home'
								|| location.pathname === '/voter/home')) {
								return true;
							}
							return false;
						}

						return true;
					}}
				>
					{ linkInner }
				</NavLink>
			</li>;
		}
	};

	handleMenuButton = () => {
		const button = document.getElementsByClassName('hamburger')[0];
		button.classList.toggle('is-active');

		const el = document.getElementsByClassName(
			'dtsvote-c-home__nav__content')[0];
		el.classList.toggle('dtsvote-u-d-block');
		el.classList.toggle('animated');
		el.classList.toggle('fadeIn');
	};

	// Get election info
	ElectionInfo() {
		let electionInfo = null;
		let date;
		let electionName;

		if (this.props.data) {
			date = this.props.data.election ? this.props.data.election.electionDate : null;
			electionName = this.props.data.election ? this.props.data.election.name : null;
		}

		if (date) {
			date = new Date(date);
			date = date.toDateString().split(' ');
			date.shift();
			date = date.join(' ');
		}

		if (this.props.generic && this.props.generic.electionPhase !== 'Caucus' && date) {
			electionInfo = (
					<span>{`${electionName} Election Date:`}<br />{date}<br /><br /></span>
			);
		} else {
			electionInfo = (
				<div></div>
			);
		}

		return electionInfo;
	}

	// Get precinct info
	PrecinctInfo() {
		let precinctInfo = null;
		let precinct;

		if (this.props.data) {
			precinct = this.props.data.precinct ? this.props.data.precinct : null;
		}

		if (precinct) {
			precinctInfo = (
				<div className="dtsvote-u-mt-0d5">Precinct: { precinct.precinctName }</div>
			);
		} else {
			precinctInfo = (
				<div></div>
			);
		}

		return precinctInfo;
	}

	render() {
		if (!this.props.generic) {
			this.props.searchGetGeneric();
		}

		// Setup address for Address component
		let address, addressProps, physicalAddress;
		if (this.props.data) {
			address = this.props.data.residence ? this.props.data.residence : null;
		}

		if (address) {
			physicalAddress = {
				address: address.physicalAddress,
				unitType: address.physicalUnitType,
				unitNumber: address.physicalUnitNumber,
				city: address.physicalCity,
				state: 'UT',
				zip: address.physicalZip
			};
		}

		addressProps = {
			address: physicalAddress,
			header: "Your address information",
			headerStyle: "dtsvote-fs-h7 dtsvote-u-d-iblock",
			addressStyle: "dtsvote-u-mt-0d5"
		};
		// End address setup

		return (
			<div id='dtsvote-sidebarnav' className='dtsvote-c-home__nav'>
				<div className='dtsvote-c-home__nav__controls'>
					<button className='hamburger hamburger--slider' type='button'
							aria-label='Menu' aria-controls='dtsvote-c-home__nav__content' onClick={this.handleMenuButton}>
						<span className='hamburger-box'>
							<span className='hamburger-inner'></span>
						</span>
						<span className='hamburger-label'>Menu</span>
					</button>
				</div>

				<div className='dtsvote-c-home__nav__content'>
						<div className='dtsvote-c-home__nav__address'>
							<this.ElectionInfo />
							<Address addressProps={addressProps} />
							<this.PrecinctInfo />
							<NavLink to={this.props.authType === SEARCH_TYPES.VOTER
								? PATHS.SearchByVoter_plain :
								PATHS.SearchByAddress_plain}
									 className="dtsvote-u-mt-0d5 dtsvote-u-d-block"
							>(Use another address)</NavLink><br />
							<Button label='Logout'
									className='dtsvote-btn dtsvote-btn--primary dtsvote-c-home__nav__logout'
									onClick={() =>
										this.props.searchLogout(this.props.history, true)} />
						</div>
					<nav id='dtsvote-navigation'>
						<ul>
							{ this.links.map((link, i) => this.renderLink(i)) }
						</ul>
					</nav>
				</div>
			</div>
		);
	}
}

SidebarNav.propTypes = {
	// API response data for `search` redux slice.
	data: PropTypes.object,

	// Voter or Resident (address) auth type.
	authType: PropTypes.any, // {(string|null)}

	// Clears redux/session and pushes to root.
	searchLogout: PropTypes.func.isRequired,

	// Misc. app data (eg election phase).
	generic: PropTypes.object,
	searchGetGeneric: PropTypes.func.isRequired,

	// From react-router-dom via withRouter
	history: PropTypes.object.isRequired
};

function mapStateToProps({ search }) {
	return {
		data: search.data,
		authType: search.authType,
		generic: search.generic
	};
}

export default withRouter(connect(mapStateToProps, {searchLogout, searchGetGeneric })(SidebarNav));
export { links };
