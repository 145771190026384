import React from 'react';

import Accordion from '../Accordion';
import AccordionHeaderLabel from "../AccordionHeaderLabel";
import {NavLink} from 'react-router-dom';
import {PATHS} from '../../Routes';
import Address from "../Address";
import AddressMap from "../AddressMap";
import {replaceBody, replaceBodyBold, primaryNoticeInPerson, primaryNoticeDriveUpPolling} from "../../AppVars";
import {isVoteInPersonCounty} from "../../AppVars";

class VoteOnElectionDay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabSelected: 2,
			tabs: [
				'Vote By Mail',          // Tab 0
				'Vote Early In Person',  // Tab 1
				'Vote On Election Day'   // Tab 2
			],
			accordionToggleAll: true,
		};
	}

	render() {
		return (this.VoteOnElectionDay(this.props.data));
	}

	nearestElectionDayVotingLocation() {
		const { address1: election_address1,
				address2: election_address2,
				city: election_city,
				zip: election_zip,
				pollLocation: election_pollLocation,
				pollName: election_pollName } = this.props.data.residence.electionDayPollAddress || {};
		const { mailBoxBallotDate, postMarkBallotDate, nonMailReceivedDate } = this.props.data.generic;
		const electionPhase = this.props.data.generic.electionPhase;
		const { electionDate : election_date } = this.props.data.residence.election || {};
		const electionDate = new Date(election_date);
		let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		let electionDateFormatted = days[electionDate.getDay()] + ', ' + months[electionDate.getMonth()] + ', ' + electionDate.getDate() + ' ' + electionDate.getFullYear();

		let pollAddressHeader = 'Polling Address:';
		let pollAddressPageHeader = 'Your nearest Election Day voting location:';
		let inLineDisclaimer = '*If you are in line by 8:00 pm, you will be permitted to vote.';
		let primaryNotice = primaryNoticeInPerson();

		const otherVotingLocations = this.props.data.voteElectionDay.pollAddressList.map(poll => {
			const {city, zip, pollName, pollLocation, address1, address2, electionDate, electionTime} = poll;

			let description = pollName;
			if (pollLocation) {
				description = pollName + ' - ' + pollLocation;
			}
			const address = {
				description : description,
				address : address1,
				addressLine2 : address2,
				city : city,
				state : 'UT',
				zip : zip
			};
			const addressProps = {
				address : address
			};

			const accordionHeader = {
				header : city,
				items : [pollName, address1, address2]
			};
			const headerLabel = <AccordionHeaderLabel accordionHeader={accordionHeader} />;

			let locationHeader = 'Drive-up voting location:';
			if (isVoteInPersonCounty(this.props.data.residence.countyId)) {
				locationHeader = 'Location :';
			}
			return {
				headerLabel: headerLabel,
				panelContents: <div className='dtsvote-u-pb-1'>
					<div className='dtsvote-u-ml-0d5'>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>Date & Time:</strong>
						<div>
							{electionDate}<br />
							{electionTime}
						</div>
						<strong className='dtsvote-u-d-block dtsvote-u-mt-0d5'>{locationHeader}</strong>
						<Address addressProps={addressProps}/>
						<AddressMap address={address1} city={city} />
					</div>
				</div>
			};
		});

		return <div>
			<div className='dtsvote-c-home__inner'>
				<p className='dtsvote-u-mt-0'>{pollAddressPageHeader}</p>
				<h2 className='dtsvote-fs-h5'>{pollAddressHeader}</h2>
				<address className='dtsvote-u-mt-0d5'>
					{election_pollName}{election_pollLocation ? ` - ${election_pollLocation}` : undefined} <br />
					{election_address1} <br />
					{election_address2 ? <div>{election_address2} <br /></div>  : undefined}
					{election_city}, UT, {election_zip}
				</address>
				<AddressMap address={election_address1} city={election_city} />
				<h3 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>Date & Hours:</h3> <br />
				<span  className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>{electionDateFormatted}</span> <br />
				<span  className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>7:00 am - 8:00 pm*</span><br />
				<small>{inLineDisclaimer}</small> <br />
				<h3 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>What to Bring:</h3> <br />
				<span className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>Remember to bring valid ID. (<NavLink to={PATHS.VoterIdRequirements}>what is valid ID?</NavLink>)</span>
				{electionPhase === 'Primary' ? <div>{primaryNotice}</div> : <div></div>}
				<h3 className='dtsvote-fs-h5 dtsvote-u-d-iblock'>What to Expect:</h3> <br />
				<span className='dtsvote-u-d-iblock dtsvote-u-mt-0d5'>Election Day voting locations are designed to assist voters who need to replace their ballots, voters who did not receive a mail ballot, or voters who need to use a voting machine that offers accessible features such as an audio ballot. If you received a mail ballot, you are encouraged to skip the lines on Election Day by postmarking your ballot by {postMarkBallotDate} or depositing it in a county ballot drop box before 8:00 pm on Election Day.</span>
			</div>
			{otherVotingLocations ?
				<div>
					<h3 className='dtsvote-fs-h5 dtsvote-u-pl-1'>Below are other voting locations in {this.props.data.residence.countyName} County that you may use, regardless of where you reside within the county:</h3>
					<button className='dtsvote-btn dtsvote-btn--primary dtsvote-c-accordion__toggle-all dtsvote-u-mt-1'
							onClick={() => {
								this.state.accordionToggleAll
									? Accordion.expandAll('dtsvote')
									: Accordion.collapseAll('dtsvote');
								this.setState({
									accordionToggleAll: !this.state.accordionToggleAll});
							}}>
						{this.state.accordionToggleAll
							? 'Expand All' : 'Collapse All'}
					</button>
					<Accordion
						list={otherVotingLocations}
						id='acc2'
						headingLevel={2}
						namespace='dtsvote'
					/>
				</div>
				:
				<div>
					<h3 className='dtsvote-fs-h5 dtsvote-u-pl-1'>
						We’re sorry!  Your county is not offering drive-up voting on Election Day or regular
						polling locations this election.  Contact your county clerk for more information.
					</h3>
				</div>
			}

		</div>;
	}

	noNearestElectionDayVotingLocation() {
		return replaceBody(noPollInfoMessage);
	}

	VoteOnElectionDay(data) {
		const electionMessage  = data.residence.electionMessage;

		if (electionMessage) {
			return replaceBody(canceledElectionMessage);
		}

		if (! data.voteElectionDay) {
			const { countyId, electionId } = data.residence;
			const precinctId = data.residence.precinct.precinctId ;
			if (countyId && precinctId && electionId) {
				data.voteWhereGetElectionDayLocs(countyId, precinctId, electionId);
				return (
					<div className='dtsvote-u-ml-0d5 dtsvote-u-text-center'>
						<h3>Loading...</h3>
					</div>
				)
			} else if (data.generic && data.generic.electionPhase === 'Municipal') {
				return replaceBody(noMunicipalElectionMessage);
			} else {
				return replaceBodyBold(noElectionMessage);
			}
		} else if (data.voteElectionDay.errorMessage) {
			return <p className='dtsvote-u-alert'>{data.voteElectionDay.errorMessage}</p>
		}

		if (! data.residence.electionDayPollAddress ) {
			return this.noNearestElectionDayVotingLocation();
		}

		return this.nearestElectionDayVotingLocation();
	}
}

export default VoteOnElectionDay;