import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { globalGetAppVersion } from '../actions/global_actions';
import { stateSeal } from '../misc/icons/icons';

class Footer extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.globalGetAppVersion();
	}

	render() {
		return (
			<div id='dtsvote-footer' className='dtsvote-u-mt-2'>
				<div className="dtsvote-c-wrapper dtsvote-c-wrapper--small">
					<div className='dtsvote-c-footer'>
						<div className='dtsvote-c-footer__col1'>
							<ul>
								<li><a href='#root'>Back to Top</a></li>
							</ul>
						</div>
						<div className='dtsvote-c-footer__col2'>
							<ul>
								<li><a href='https://www.utah.gov/'>Utah.gov Home</a></li>
								<li><a href='https://www.utah.gov/disclaimer.html'>Utah.gov Terms of Use</a></li>
								<li><a href='https://www.utah.gov/privacypolicy.html'>Utah.gov Privacy Policy</a></li>
								<li><a href='https://www.utah.gov/accessibility.html'>Utah.gov Accessibility Policy</a></li>
								<li><a href='https://www.utah.gov/translate.html'>Translate Utah.gov</a></li>
							</ul>
						</div>
						<div className='dtsvote-c-footer__col3'>
							{stateSeal()}
						</div>
						<div className='dtsvote-c-footer__col4'>
							<small>Copyright &copy; {new Date(Date.now()).getFullYear()} State of Utah - All rights reserved.</small>
							<br/>
							<small>Version: {this.props.appVersion}</small>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Footer.propTypes = {
	// The app version.
	appVersion: PropTypes.string,

	// Get the app version.
	globalGetAppVersion: PropTypes.func.isRequired,
};

function mapStateToProps({ global }) {
	return { appVersion: global.appVersion };
}

export default connect(mapStateToProps, { globalGetAppVersion })(Footer);
