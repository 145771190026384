import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import RequireAuth from '../../hocs/RequireAuth';
import {searchGetGeneric} from '../../actions/search_actions';
import {
	voteWhereGetDropBoxLocations,
	voteWhereGetEarlyLocs,
	voteWhereGetElectionDayLocs,
	voteWhereReset
} from '../../actions/voteWhere_actions';
import {imgLocation} from '../../misc/icons/icons';

import PageTitle from '../PageTitle';
import CaucusInfo from "./CaucusInfo";
import VoteByMail from "./VoteByMail";
import VoteEarlyInPerson from "./VoteEarlyInPerson";
import VoteOnElectionDay from "./VoteOnElectionDay";

class HowWhereToVote extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tabSelected: 0,
			tabs: [
				'Vote By Mail',        // Tab 0
				'Vote Early In Person',  // Tab 1
				'Vote on Election Day'   // Tab 2
			],
			accordionToggleAll: true,
			// caucusInfoClicked: false
		};
	}

	componentDidMount() {
		if (!this.props.generic) {
			// Restore `generic` in case app is refreshed.
			this.props.searchGetGeneric();
		}
	}

	componentWillUnmount() {
		this.props.voteWhereReset();
	}

	renderTabs = () => {
		return this.state.tabs.map((tab, i) => {
			return <button
				key={i}
				className={'dtsvote-c-home__tabs-button ' +
				(this.state.tabSelected === i ?
					'dtsvote-c-home__tabs-button--active' : '') +
				(i === 1 ? ' dtsvote-c-home__tabs-button--mid' : '')}
				onClick={() => {
					this.setState({
						tabSelected: i,
						accordionToggleAll: true
					});
				}}
			><span>{ this.state.tabs[i] }</span><div className='dtsvote-c-home__tabs-button--active__arrow-down'></div></button>;
		});
	};
	handleCaucusInfoClick = () => {
		this.setState(prevState => ({
			caucusInfoClicked: !prevState.caucusInfoClicked
		}));
	};

	render() {
		let returnValue;

		if (!this.props) {
			return <p>Loading...</p>;
		}

		if (!this.props.residence) {
			return <p>Loading...</p>;
		}

		if (this.props.residence.electionPhase === 'Caucus') {
		// if (this.props.residence.electionPhase === 'Caucus' || this.state.caucusInfoClicked) {
			// Caucus election phase display
			returnValue = (
				<CaucusInfo residence={this.props.residence} />
				// <CaucusInfo residence={this.props.residence} handleCaucusInfoClick={this.handleCaucusInfoClick} />
			);
		} else {
			const tabContent = this.state.tabSelected === 0
				? <VoteByMail data={this.props} />  // tab 0
				: this.state.tabSelected === 1
					? <VoteEarlyInPerson data={this.props} />  // tab 1
					: <VoteOnElectionDay data={this.props} /> ;// tab 2

			let pageTitle = "HOW CAN I VOTE?";

			returnValue = (
				<div id='HowWhereToVote'>
					<PageTitle image={imgLocation(50, 50)} title={pageTitle}/>
					{/*<div className='dtsvote-c-home__title'>*/}
					{/*	{ imgLocation(50, 50) }*/}
					{/*	<h1 className='dtsvote-c-home__title__text'>HOW CAN I VOTE?</h1>*/}
					{/*	<a href="#" onClick={this.handleCaucusInfoClick} style={{ marginLeft: 'auto' }}>My Caucus Locations</a>*/}
					{/*</div>*/}
					<div className='dtsvote-c-home__tabs'>
						{ this.renderTabs() }
					</div>
					<div className='dtsvote-c-home__tabs-content'>
						{ tabContent }
					</div>
				</div>
			);
		}

		return returnValue;
	}
}

HowWhereToVote.propTypes = {
	// Residence holds data needed for API calls.
	residence: PropTypes.object,

	// Misc. app data (eg election phase).
	generic: PropTypes.object,

	// Subslice of voteWhere for 'Vote Early In Person'
	voteEarly: PropTypes.object,

	// Subslice of voteWhere for 'Vote On Election Day'
	voteElectionDay: PropTypes.object,

	// Fetch early polling location data.
	voteWhereGetEarlyLocs: PropTypes.func.isRequired,

	// Fetch nearby election day polling locations.
	voteWhereGetElectionDayLocs: PropTypes.func.isRequired,

	// Used to reset fetched data on unmount in case the address is changed.
	voteWhereReset: PropTypes.func.isRequired,

	// Get/read the drop box locations for Vote By Mail tab.
	voteWhereGetDropBoxLocations: PropTypes.func.isRequired,
	dropBoxLocations: PropTypes.array,

	// Redux action creator to fetch misc. app data.
	searchGetGeneric: PropTypes.func.isRequired
};

function mapStateToProps({search, voteWhere}) {
	return {
		residence: search.data,
		generic: search.generic,
		voteEarly: voteWhere.voteEarly,
		dropBoxLocations: voteWhere.dropBoxLocations,
		voteElectionDay: voteWhere.voteElectionDay
	};
}

export default connect(mapStateToProps, {
	voteWhereGetEarlyLocs,
	voteWhereGetElectionDayLocs,
	voteWhereReset,
	voteWhereGetDropBoxLocations,
	searchGetGeneric
})(RequireAuth(HowWhereToVote));
