import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { PATHS } from '../Routes';
import ClearInactive from  '../hocs/ClearInactive';

class Landing extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		if (true) {
			window.location = PATHS.UI;
		}

		return (
			<div id='dtsvote-landing'
				 className='dtsvote-c-landing dtsvote-c-wrapper'>
				<div id='main-content' className='dtsvote-c-landing__inner'>
					<div>
						<p className='dtsvote-u-mt-0'><strong>Search by address</strong> to view the elected officials, view the sample ballot, candidate profiles and issue information or locate a polling place for that address.</p>
						<Link to={PATHS.SearchByAddress_plain}
						   className='dtsvote-btn dtsvote-btn--primary
						   		dtsvote-u-mt-1'>
							Search By Address</Link>
					</div>
					<div className='dtsvote-u-mt-2'>
						<p><strong>Search by voter information</strong> to track your ballot, view your voter registration information, your elected officials, your sample ballot, candidate profiles and issue information or locate your polling place.</p>
						<Link to={PATHS.SearchByVoter_plain}
						   className='dtsvote-btn dtsvote-btn--primary dtsvote-u-mt-1'>
							Voter Info / Track Ballot</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default ClearInactive(Landing);
