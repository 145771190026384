import { combineReducers } from 'redux';

import { searchReducer } from './search_reducer';
import { voteWhereReducer } from './voteWhere_reducer';
import { globalReducer } from './global_reducer';
import { sampleBallotReducer } from './sampleBallot_reducer';
import { candidateReducer } from './candidate_reducer';
import { trackMyBallotReducer } from './trackMyBallot_reducer';
import { reducer } from 'redux-oidc';

export default combineReducers({
	oidc: reducer,
	search: searchReducer,
	voteWhere: voteWhereReducer,
	global: globalReducer,
	sampleBallot: sampleBallotReducer,
	candidate: candidateReducer,
	ballot: trackMyBallotReducer
});
