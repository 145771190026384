import React from 'react';

/**
 * This is a generic address component for address formatting.
 */
class Address extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (this.Address(this.props));
	}

	formatHeader(addressProps) {
		let formattedHeader;

		if (addressProps.header && addressProps.headerStyle) {
			formattedHeader = (<h2 className={addressProps.headerStyle}>{addressProps.header}: </h2>);
		} else {
			formattedHeader = (<div></div>);
		}

		return formattedHeader;
	}

	formatAddress(addressProps) {
		let formattedAddress;

		if (addressProps.address.address) {
			let addressLine1 = addressProps.address.address;
			let cityStateZip = '';

			if (addressProps.address.unitType) {
				addressLine1 += ' ' + addressProps.address.unitType;
			}

			if (addressProps.address.unitNumber) {
				addressLine1 += ' ' + addressProps.address.unitNumber;
			}

			if (addressProps.address.city) {
				cityStateZip = addressProps.address.city + ', ';
			}

			if (addressProps.address.state) {
				cityStateZip += addressProps.address.state + ' ';
			}

			if (addressProps.address.zip) {
				cityStateZip += addressProps.address.zip;
			}

			if (addressProps.address.description && addressProps.address.addressLine2) {
				formattedAddress = (
					<address className={addressProps.addressStyle}>
						{addressProps.address.description} <br />
						{addressLine1}<br />
						{addressProps.address.addressLine2}<br />
						{cityStateZip}<br />
					</address>
				);
			} else if (addressProps.address.description && ! addressProps.address.addressLine2) {
				formattedAddress = (
					<address className={addressProps.addressStyle}>
						{addressProps.address.description} <br />
						{addressLine1}<br />
						{cityStateZip}<br />
					</address>
				);
			} else if (! addressProps.address.description && addressProps.address.addressLine2) {
				formattedAddress = (
					<address className={addressProps.addressStyle}>
						{addressLine1}<br />
						{addressProps.address.addressLine2}<br />
						{cityStateZip}<br />
					</address>
				);
			} else {
				formattedAddress = (
					<address className={addressProps.addressStyle}>
						{addressLine1}<br />
						{cityStateZip}<br />
					</address>
				);
			}
		} else {
			formattedAddress = undefined;
		}

		return formattedAddress;
	}

	formatEmailFax(value) {
		let formattedValue = (
			<span className='dtsvote-u-d-block'> {value} </span>
		);

		return formattedValue;
	}

	formatEmail(address) {
		let formattedEmail = undefined;

		if (address.email) {
			formattedEmail = (
				this.formatEmailFax(address.email)
			);
		}

		return formattedEmail;
	}

	formatFax(address) {
		let formattedFax = undefined;

		if (address.fax) {
			formattedFax = (
				this.formatEmailFax(address.fax)
			);
		}

		return formattedFax;
	}

	/**
	 * Return a formatted address based on addressProps.
	 * @param addressProps = {
				address: {address, addressLine2, unitType, unitNumber, city, state, zip, fax, email, errorMessage},
				header: Custom Header,
				headerStyle: header style such as "dtsvote-fs-h5 dtsvote-u-d-iblock",
				addressStyle: address style such as "dtsvote-u-mt-0d5"
			}
	 * @returns formatted address
	 * @constructor
	 */
	Address(props) {
		let address;
		let addressProps;
		let returnAddress = null;

		if (props && props.addressProps && props.addressProps.address) {
			address = props.addressProps.address;
			addressProps = props.addressProps;
		}

		if (address && ! address.errorMessage && (address.email || address.address || address.fax)) {
			returnAddress = (
				<div>
					{this.formatHeader(addressProps)}
					{this.formatAddress(addressProps)}
					{this.formatEmail(address)}
					{this.formatFax(address)}
				</div>
			);
		}

		return returnAddress;
	}
}

export default Address;