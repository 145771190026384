import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store } from '../App.js';
import * as actions from '../actions/_index_actions';

/**
 * Usage Example:
 *     <DebugButtons parentProps={this.props} parentState={this.state} />
 */
class DebugButtons extends React.Component {
	constructor(props) {
		super(props);

		this.state = { localDebugButtonsStateTest: 123 };

		this.pushPaths = [
			`/`,

			// Candidate Paths:
			'/candidate/landing',
			'/candidate/edit',

			// Search Paths:
			`/search/search-by-address/`,
			`/search/search-by-address/how-and-where-can-i-vote/`,
			`/search/search-by-address/candidates-and-issues/`,
			`/search/search-by-voter/`,
			`/search/search-by-voter/track-mail-ballot/`,
			`/search/search-by-voter/voter-info/`,

			// Home Path:
			`/resident/home`,
			`/voter/home`,

			// Home > Address Auth Paths:
			'/resident/home/how-and-where-can-i-vote',
			'/resident/home/candidates-and-issues',
			'/resident/home/register-to-vote',
			'/resident/home/request-mail-ballot',
			'/resident/home/contact-election-official',
			'/resident/home/voter-id-requirements',

			// Home > Voter Auth Paths:
			'/voter/home/voter-info',
			'/voter/home/track-mail-ballot',
		];
	}

	handleLogParent = () => {
		console.log('\n-----PARENT State & Props-----');
		console.log('STATE:', this.props.parentState);
		console.log('PROPS:', this.props.parentProps);
		console.log('------------------------------\n');
	};

	handleLogLocal = () => {
		console.log('\n-----DEBUG State & Props-----');
		console.log('STATE:', this.state);
		console.log('PROPS:', this.props);
		console.log('-----------------------------\n');
	};

	handleLogStore = () => {
		console.log('\n-----APP STORE-----');
		console.log('STORE:', store.getState());
		console.log('-------------------\n');
	};

	handleSearchLogout = (e, pushToRoot = false) => {
		this.props.searchLogout(this.props.history, pushToRoot);
	};

	handlePush = path => {
		this.props.history.push(path);
	};

	render() {
		return(
			<div id='DebugButtons' className='dtsvote-u-p-1d5'>
				<h4 className='dtsvote-u-text-white'>Debug Options:</h4>
				<button className='dtsvote-u-mt-0d5'
						onClick={this.handleLogParent}>
					LOG PARENT State & Props
				</button>
				<br />
				<button className='dtsvote-u-mt-0d5'
						onClick={this.handleLogStore}>
					LOG APP STORE
				</button>
				<br />
				<button className='dtsvote-u-mt-0d5'
						onClick={this.handleLogLocal}>
					LOG DEBUG State & Props
				</button>
				<br />
				<button className='dtsvote-u-mt-0d5'
						onClick={this.handleSearchLogout}>
					searchLogout (sessionStorage/redux clear)</button>
				<br />
				<button className='dtsvote-u-mt-0d5'
						onClick={e => this.handleSearchLogout(e, true)}>
					searchLogout (clear and push to /)</button>
				<h4 className='dtsvote-u-text-white'>Push Paths:</h4>
				{
					this.pushPaths.map((path, i) => {
						return <div key={i}>
								<button className='dtsvote-u-mt-0d5'
										 onClick={() => this.handlePush(path)}>
								PUSH to: {path}
							</button>
							</div>;
					})
				}
			</div>
		)
	}
}

DebugButtons.propTypes = {
	parentState: PropTypes.object,
	parentProps: PropTypes.object
};

DebugButtons.defaultProps = {
	parentState: {},
	parentProps: {}
};

export default withRouter(
	connect(state => state, actions)(DebugButtons)
);
