import {
	SAMPLEBALLOT_GET_DATA,
	SAMPLEBALLOT_CLEAR_DATA,
	SAMPLEBALLOT_GET_PROFILE_REQ,
	SAMPLEBALLOT_GET_PROFILE_RES,
	SAMPLEBALLOT_CLEAR_PROFILE,
} from "../actions/_index_action-types";

export const sampleBallotReducer = (state = {
	data: null,
	fetchingProfile: false,
	profile: null
}, action) => {
	switch (action.type) {
		case SAMPLEBALLOT_GET_DATA:
			return {
				...state,
				data: action.payload
			};
		case SAMPLEBALLOT_CLEAR_DATA:
			return {
				...state,
				data: null,
				fetchingProfile: false,
				profile: null
			};
		case SAMPLEBALLOT_GET_PROFILE_REQ:
			return {
				...state,
				fetchingProfile: true
			};
		case SAMPLEBALLOT_GET_PROFILE_RES:
			return {
				...state,
				fetchingProfile: false,
				profile: action.payload
			};
		case SAMPLEBALLOT_CLEAR_PROFILE:
			return {
				...state,
				profile: null,
				fetchingProfile: false
			};
		default:
			return state;
	}
};
