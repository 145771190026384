import React from "react";
import {CallbackComponent} from "redux-oidc";
import userManager from "../misc/UserManager";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { PATHS } from "../Routes";

const propTypes = {
	// the react router history object for pushing/redirecting
	history: PropTypes.object.isRequired,

};

const defaultProps = {};

class LoginCallback extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false
		}
	}

	render() {

		const successCallback = (user) => {
			//console.log('user', user);
			this.props.history.push(PATHS.CandidateLanding);
		};

		const errorCallback = (error) => {
			console.error('error', error);
		};

		return (
			<CallbackComponent
				userManager={userManager} successCallback={successCallback} errorCallback={errorCallback}>
				<div>Redirecting...</div>
			</CallbackComponent>
		);
	}
}

LoginCallback.propTypes = propTypes;
LoginCallback.defaultProps = defaultProps;

export default connect()(LoginCallback);
